import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { LessonLayout, LessonHeader, LessonTitle, CodeBlock } from '../components'


const RunningLoadingComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={3}
         title=' Javascriptの実行環境 ' />
        <p>
          <p>
            webサイトはHTMLによって構造を、CSSによってデザインを、JavaScriptによってwebサイトに動きを付けるための役割を担っています。今回は、JavaScriptがwebサイト上や自分のPC上でどのように実行されているのか？という基本的な部分を実際に見ていきましょう。
          </p>
          <br />

          <ol>
            <li>・webサイトのconsole</li>
            <li>・Node.jsとコマンドプロンプト</li>
            <li>・script tag</li>
          </ol>
          <br />
          <p>
            JavaScriptを実行する環境はいくつかありますが、上記の他にも、JavaScriptを実行するにはいくつかの方法があります。
          </p>
        </p>
        <h2 className='text-2xl mt-4'>前提知識</h2>
        <p>
          前提知識として、各種ブラウザにはconsole機能が付いています。
        </p>
        <br />
        <p>
          console機能を使用する事で、webサイト上でどのようにJavaScriptが動いているのか確認したり、自分で書いたJavaScriptをwebサイト上で模擬的にテストすることができるという利点があります。
        </p>
        <br />
        <p>
          また、コンピュータに視覚的にではなく、文字で直接命令（コマンド）を送る方法として、シェルやターミナル、コマンドプロンプトというものがあります。
        </p>
        <br />
        <p>
          そして、Node.jsとはサーバーサイド（アプリケーションの裏側）で動くJavaScriptです。Node.jsを使うとローカル上（自分のPC）でJavaScriptが実行可能となります。その際に必要となるのがターミナルやコマンドプロンプトなのです。
        </p>
        <br />
        <p>
          今回は、3つのJavaScriptの実行方法をご紹介します。
        </p>
        <h2 className='text-2xl mt-4'>値の評価と表示</h2>
        <h2 className='text-2xl mt-4'>JavaScriptの基本</h2>
        <div>
          <p>値の評価とは、入力した値を評価してその結果を返すことを示しています。例を見てみましょう。</p>
          <ol>
            <li><code>1 + 1</code> という色を評価したら2という結果を返す</li>
            <li><code>animal</code>という変数を評価したら、変数に代入されている値を返す</li>
            <li><code>const x = 1;</code>という文を評価することで変数を定義するが、この文には返り値はありません</li>
          </ol>
          <p>この値の評価方法を確認するためにWebブラウザを使ってJSを実行する方法を見ていきます。</p>
        </div>
        <div>
          <p>実際にJavaScriptを書く前に、特徴を少し理解しておきまししょう。</p>
          <br />
          <b>大文字と小文字を区別する</b>
          <p>JavaScriptは大文字小文字を区別します。例えば、DOGとdogでは別の文字列として認識されます。</p>
          <br />
          <b>予約語を持つ</b>
          <p>JavaScriptには特別な意味を持つキーワードがあり、これらは予約語とも呼ばれます。このキーワードと同じ名前の変数や関数は宣言できません。</p>
          <br />
          <b>文はセミコロンで区切られる</b>
          <p>JavaScriptは、文（Statement)ごとに処理していき、文はセミコロン（；）によって区切られます。特殊なルールに基づき、セミコロンがない場合もありますが、暗黙的なものへ頼ると意図しない挙動が発生するため、セミコロンは常に書く様にしましょう。</p>
          <p>また、スペース、タブなどは空白文字（ホワイトスペース）と呼ばれます。これは空白文字を分に幾つおいても挙動に違いはありません。空白文字の置き方は人によって好みが異なるため、複数人で開発する場合は、これらの空白文字の置き方を決めたコーディングスタイルを決めると良いでしょう。</p>
          <br />
          <b>strict mode</b>
          <p>JavaScriptにはstrict modeという実行モードが存在しています。古く安全でない構文や機能が一部禁止されています。これにより開発者が間違いに気づきやすくなり、安全にコードが書けるようにJavaScriptの落とし穴を塞いでくれます。</p>
          <br />
          <b>実行コンテキスト:scriptとModule</b>
          <p>JavaScriptの実行コンテキストとしてscriptとModuleがあります。コードを書く場合には、この２つの実行コンテキストの違いを意識することは多くありませんが、どういったものかを理解しておきましょう。
            <p>Scriptの実行コンテキストは、多くの実行環境ではデフォルトの実行コンテキストです。scriptの実行コンテキストでは、デフォルトはstrict modeではありません。</p>
            <p>Moduleの実行コンテキストは、Javascriptをモジュールとして実行するために、ECMAScript2015で導入されたものです。Moduleの実行コンテキストでは、デフォルトがstrict modeとなり、古くから安全でない構文は機能は一部禁止されています。またモジュールの機能はmoduleの実行コンテキストでしか利用できません。</p>
          </p>
        </div>
        <h2 className='text-2xl mt-4'>webサイトのconsoleでJavaScriptを実行してみよう！</h2>
        <div>
          <p>今回は、JavaScriptをChromeで実行してみます。</p>
          <p>また、Chromeにはデベロッパーツールという機能搭載されています。</p>
          <br />

          <p>ディベロッパーツールを使うことで、webサービスの開発、テスト、デザインのチェックなどを行うことができるので、webサイトを開発するには必須のツールなのです。
          </p>
          <p>デベロッパーツールの主な機能は以下の通りです。
          </p>
          <br />
          <ol>
            <li>・Elements: HTMLのソースを確認、それに関連づいたCSSの内容を確認できる。</li>
            <li>・Console: JavaScriptの動きを確認し、どこでエラーが出ているかも知ることができる。</li>
            <li>・Sources: プログラムのソースを確認できる。エラーが出ている箇所は強調される。</li>
          </ol>
          <br />
          <p>
            JavaScriptを学習する際に、わざわざエディタを開いてプログラムを書く必要がなく、簡単にプログラムに動作を確認できるのがメリットです。
          </p>
          <br />
          <p>【手順】</p>
          <br />
          <p>任意のWEBページを開き、右クリック＞検証＞Consoleから開くことができます。</p>
          <br />
          <p>また、[Ctrl + Shift + I] や[F12]のショートカットからも同様に開くことができます。</p>
          <p>
            こちらの `{'>'}` に 1 + 1 を入力しEnterを押すことで、2が返ってきますので、JavaScriptの実行を確認することができました。
          </p>
        </div>
        <h2 className='text-2xl mt-4'>エディタでJavaScriptを実行してみよう！</h2>
        <div>
          <p>
            今回は、VSCodeを使用します。
            VSCodeですと、Emmetという機能が初めから搭載されているので便利です。
          </p>
          <br />
          <p>
            Emmetはhtmlファイルで、!と打つだけでmetaタグ等の、htmlを書く際に必要な情報を一瞬で書いてくれるのです。
          </p>
          <br />
          <p>
            次に、VSCodeのhtmlファイル内に&lt;body&gt;タグがありますが、その中に以下のように書くことで、JavaScriptを実行することができます。
          </p>
          <CodeBlock>
            {`
<body>
<script>
console.log('hi')
</script>
</body>
        `}
          </CodeBlock>

          <p>右クリックから"ブラウザで開く"を選択し、文字が表示されるか確認しましょう。</p>
          <br />
          <p>注意点として、&lt;script&gt;タグは &lt;/body&gt;タグの終わりに書くと良いです。例えば、変数などを使用した場合、&lt;/script&gt;以降に変数の要素などを書くと、要素が読み込まれずnullとなってしまいます。</p>
          <br />
          <p>
            というのも、プログラムは基本的に順次進行する構造を持ち、上から下にプログラムが処理されるのです。ですので、JavaScriptを書く際にはコードを記述する順番にも気をつけるようにしましょう。
          </p>
        </div>
        <h2 className='text-2xl mt-4'>Node.jsでJavaScriptを実行してみよう！</h2>
        <p>
          引き続き、VSCodeを使って行きますが、新しくjsファイルを作ってみましょう。
          簡単にコードを記述します。
        </p>
        <CodeBlock>
          {`
console.log(\`I'm from node\`);
        `}
        </CodeBlock>
        <p>
          それでは、コマンドプロンプトを開きましょう。

          コマンドプロンプトを開いたら、コマンド dir を入力する事で、ディレクトリの一覧を表示することができます。
        </p>
        <br />
        <p>
          その中から、先ほど作ったファイルを以下のように入力すると、JavaScriptの実行結果(I’m from node)が返ってきます。
        </p>
        <CodeBlock>
          {`
node node-example.js
        `}
        </CodeBlock>
        <p>以上、3つが今回紹介するJavaScriptの実行方法となります。</p>
        <h2 className='text-2xl mt-4'>コードの評価とエラー</h2>
        <p>JSのコードを実行したときにエラーメッセージが表示されて意図した様に動かない場合もあるはずです。プログラミングを書くときに一度もエラーを出さずに書き終えることはほとんどありません。特に新しいプログラミング言語を学ぶ際は試行錯誤を繰り返すことはとても重要です。</p>
        <p>エラーメッセージが出た時は慌てずにそのエラーメッセージを読んでみましょう。そうすることで多くの問題を解決することができます。</p>
        <p>エラーには大きく分けて「構文エラー」と「実行エラー」の２種類があります。</p>
        <p>そのエラーを修正する足がかりを見てみましょう。</p>
        <br />
        <b>・構文エラー</b>
        <p>構文エラーはコードの文法が間違っている場合に発生するエラーです。</p>
        <p>JavaScriptエンジンは、コードを解釈してから、プログラムとして実行できる形に変換して実行します。</p>
        <p>例を一つ見てみましょう。</p>
        <CodeBlock>
          {`
SyantaxError: missing ) after argument list[詳細] index.js:1:13
          `}
        </CodeBlock>
        <p>基本的には同じ形式でエラーメッセージが表示されます。パーツごとにみていきましょう。</p>
        <p>「SyntaxError:] - エラーの種類</p>
        <p>「missing ) after argument list」 - エラーの内容</p>
        <p>「index.js:1:13」 - ファイル名：行番号：列番号</p>
        <p>このエラーメッセージから、関数呼び出しの ) が足りていないことが原因の構文エラーとなっていることがわかります。</p>
        <p>構文エラーの原因はコードの書き間違いであることがほとんどです。そのため、エラーが発生した位置やその周辺を注意深くみることで、エラーの原因を特定できます。</p>
        <br />
        <b>・実行エラー</b>
        <p>実行エラーはプログラミングを実行している最中に発生するエラーです。実行時（ランタイム）に起きるエラーであるため、ランタイムエラーと呼ばれることもあります。</p>
        <p>実行時エラーが発生した場合は、そのコードは構文としては正しい（構文エラーではない）のですが、別のことが原因でエラーが発生しています。</p>
        <p>例</p>
        <CodeBlock>
          {`
ReferenceError: x is not defiened[詳細] index.js:2.1
          `}
        </CodeBlock>
        <p>パーツは構文エラーと一緒ですね。</p>
        <p>実行時エラーは該当する箇所を実行するまで、エラーになるかが分からない場合も多いです。</p>
        <p>そのため、どこまではちゃんと実行できたか順番に追っていくような、エラーの原因を特定する作業が必要になる場合があります。</p>
        <p>この様なエラーの原因を特定し、修正する作業のことを
          <b>デバッグ</b>と呼びます。
        </p>
        <p>エラーがWebコンソールに表示されているならば、そのエラーは修正できます。 エラーを過度に怖がる必要はありません。エラーメッセージなどのヒントを使ってエラーを修正していけるようにしましょう。</p>
        <p>さて次回も、一緒にJavaScriptについて学んでいきましょう。</p>
      </LessonLayout>
    </div>
  )
}

export default withRouter(RunningLoadingComponent)
