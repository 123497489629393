import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomeComponent from './pages/Home'
import IntroductionComponent from './pages/Introduction'
import BrowserTerminalSetupComponent from './pages/BrowserTerminalSetup'
import RunningLoadingComponent from './pages/RunnningLoadingJS'
import VariablesStatementsComponent from './pages/VariablesStatements'
import PrettierEslintComponent from './pages/PrettierEslint'
import TypesComponent from './pages/Types'
import FunctionsComponent from './pages/Functions'
import DevToolsComponent from './pages/DevTools'
import ScopeComponent from './pages/Scope'
import HoistingComponent from './pages/Hoisting'
import ClosuresComponent from './pages/Closures'
import DOM_1Component from './pages/DOM_1'
import DOM_2Component from './pages/DOM_2'
import DOM_3Component from './pages/DOM_3'
import DOM_4Component from './pages/DOM_4'
import DOM_5Component from './pages/DOM_5'
import DOM_6Component from './pages/DOM_6'
import DOM_7Component from './pages/DOM_7'
import DOM_8Component from './pages/DOM_8'
import Event_1Component from './pages/Event_1'


export const paths = {
  home                : '/',
  introduction        : '/01_introduction_to_javascript',
  browserTerminalSetup: '/02_browser_editor_terminal_setup',
  runningLoadingJs    : '/03_running_loading_js',
  variablesStatements : '/04_variables_statements',
  prettierEslint      : '/05_prettier_eslint',
  types               : '/06_types',
  functions           : '/07_functions',
  devTools            : '/08_devTools',
  scope               : '/09_scope',
  hoisting            : '/10_hoisting',
  closures            : '/11_closures',
  DOM_1               : '/12_DOM_1',
  DOM_2               : '/13_DOM_2',
  DOM_3               : '/14_DOM_3',
  DOM_4               : '/15_DOM_4',
  DOM_5               : '/16_DOM_5',
  DOM_6               : '/17_DOM_6',
  DOM_7               : '/18_DOM_7',
  DOM_8               : '/19_DOM_8',
  Event_1             : '/20_Event_1',
}

export default () => (
  <Router basename='/'>
    <Switch>
      <Route exact path={paths.home} component={HomeComponent} />
      <Route path={paths.introduction} component={IntroductionComponent} />
      <Route path={paths.browserTerminalSetup} component={BrowserTerminalSetupComponent} />
      <Route path={paths.runningLoadingJs} component={RunningLoadingComponent} />
      <Route path={paths.variablesStatements} component={VariablesStatementsComponent} />
      <Route path={paths.prettierEslint} component={PrettierEslintComponent} />
      <Route path={paths.types} component={TypesComponent} />
      <Route path={paths.functions} component={FunctionsComponent} />
      <Route path={paths.devTools} component={DevToolsComponent} />
      <Route path={paths.scope} component={ScopeComponent} />
      <Route path={paths.hoisting} component={HoistingComponent} />
      <Route path={paths.closures} component={ClosuresComponent} />
      <Route path={paths.DOM_1} component={DOM_1Component} />
      <Route path={paths.DOM_2} component={DOM_2Component} />
      <Route path={paths.DOM_3} component={DOM_3Component} />
      <Route path={paths.DOM_4} component={DOM_4Component} />
      <Route path={paths.DOM_5} component={DOM_5Component} />
      <Route path={paths.DOM_6} component={DOM_6Component} />
      <Route path={paths.DOM_7} component={DOM_7Component} />
      <Route path={paths.DOM_8} component={DOM_8Component} />
      <Route path={paths.Event_1} component={Event_1Component} />
    </Switch>
  </Router>
)
