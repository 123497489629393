import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const PrettierEslintComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={5}
          title=' Prettier と ESLint ' />
        <p>今回は、JavaScriptのフォーマットとコーディングをサポートする2つのツールESLintとPrettierについて紹介します。</p>
        <br />
        <p>これらのつーつを使えるようになると、バグの発生を防いだり、フォーマットの手間を省くことができるのです。
        </p>
        <p>また、チーム開発の際にはJavaScriptの書き方（ルール）として規定していることも多々ありますので、コーディングする方にとっては必須のツールとなります。</p>
        <h2>ESLintについて</h2>
        <article>
          <p>ESLintはコードの構文をチェックし、バグの発生を防いでくれます。また、コードに一貫性を持たせることができるので、プロジェクトの品質を強化することが可能です。
          </p>
          <br />
          <p>結果的に、コードのバグを自分で調査し修正する必要がないので、時間短縮につながり、生産性が高くなります。</p>
          <p>ちなみに、ESLintはプラグインを導入することで、vueやreact,angularなどのフレームワークにも対応することができるのです。</p>
        </article>
        <h3>ESLint Demoをさわってみよう！</h3>
        <article>
          <a class='text-blue-700' href='https://eslint.org/demo'>https://eslint.org/demo</a>
          <p>それでは、簡単にESLint Demoをさわってみましょう。</p>
          <p>まずは、Rules Configulationにて、ECMA Versionが最新のバージョンを選びます（2021 / 2020年12月現在）。</p>
          <CodeBlock>
            {`
age = 100
console.log(age);
        `}
          </CodeBlock>
          <p>例として、上記のコードを画面左側画面に入力してみましょう。</p>
          <br />
          <p>すると、画面右に赤い文字でフィードバックを送ってくれます。また、リンクをクリックするとエラーの詳細（ルール）についてページのが表示されますので、適宜確認・修正していきましょう。</p>
          <br />
          <p>実際には、数百種類のルールが存在するので、自分が必要なものを選択して使用する事になります。</p>
        </article>
        <h2>Prettierについて</h2>
        <article>
          <p>PrettierはNode.js上で動作するコード整形ツールです。インデントやスペース、ダブルクォーテーション（”）、セミコロン（;）忘れなどを自動でフォーマットしてくれます。</p>
          <br />
          <p>結果的に、開発者はコードのクセなどを気にしながらコーディングする必要がないので、可読性が高くなるのです。</p>
          <br />
          <p>こちらがPrettierのDemoサイトになります。既にサンプルコードが入力されておりますので、Prettierがどのような動作をするのかが理解できると思います。</p>
          <p><a class='text-blue-700' href='https://prettier.io/playground/'>https://prettier.io/playground/</a></p>
        </article>
        <h3>ESLintとPrettierを併用する理由は？</h3>
        <article>
          <p>ESLintにも、eslint --fixというコマンドがあり、コード整形を行うことができます。しかし、eslint --fixは設定する項目が多く、項目外のコード整形は行いません。</p>
          <p>そこで、ESLintが整形できないコードもPrettierには整形できるので、ESLintとPrettierを連携して使うことが実際には多いのです。</p>
        </article>
        <h2>VSCodeにESLint, Prettierをインストールしてみよう！</h2>
        <article>
          <p>それでは、VSCodeにESLintをインストールし、その中にPrettierのプラグインを組み込んでいきましょう。</p>
          <p>1: ターミナルから新規フォルダを作成し、ローカル（プロジェクトごと）にインストールしていきます。</p>
          <br />
          <p>2: 新規に作ったフォルダにpackage.jsonファイルをコマンド（npm init）でインストールしましょう。</p>
          <br />
          <p>3: 
            <CodeBlock>
              {`
 npx install-peerdeps --dev eslint-config-wesbos
        `}
            </CodeBlock>
            をターミナルに入力する事で、node_modulesフォルダが作成されます。これにより、JavaScriptがESLintを実行することが可能となります。</p>
          <br />
          <p>それでは、VSCodeを開いて.eslintrcファイルをrootに作成しましょう。</p>
          <br />
          <p>4: 作成した.eslintrcファイル内に、</p>
          <CodeBlock>
            {`
{
  "extends"：[
    "wesbos"
  ]
}
        `}
          </CodeBlock>
          <p>を挿入します。</p>
          <br />
          <p>5: </p>
          <p>それでは、VSCodeにESLintの拡張機能を追加していきましょう。</p>
          <p>VSCodeの設定を変更します。</p>
          <p>設定の検索から、settings.jsonを表示します。</p>
          <p>以下のコードをsettings.jsonの最後に挿入しましょう。</p>
          <CodeBlock>
            {`
// These are all my auto-save configs
"editor.formatOnSave": true,
// turn it off for JS and JSX, we will do this via eslint
"[javascript]": {
  "editor.formatOnSave": false
},
"[javascriptreact]": {
  "editor.formatOnSave": false
},
// show eslint icon at bottom toolbar
"eslint.alwaysShowStatus": true,
// tell the ESLint plugin to run on save
"editor.codeActionsOnSave": {
  "source.fixAll": true
},
// Optional BUT IMPORTANT: If you have the prettier extension enabled for other languages like CSS and HTML, turn it off for JS since we are doing it through Eslint already
"prettier.disableLanguages": ["javascript", "javascriptreact"],

        `}
          </CodeBlock>
          <p>[ javascript, javascriptreact] のeditor.formatOnSaveはfalseにしておかないと、Prtitterのフォーマットとうまく連携されません。</p>
          <br />
          <p>これで設定が完了しました。</p>
          <p>実際にVSCodeでJavaScriptのファイルを作成し、ESLint、Prettierが以下のように機能する事を確認しましょう。</p>
          <p>
            <ul>
              <li>・上書き保存したときにフォーマットされること。</li>
              <li>・定数宣言などを書き忘れた時は、赤線にカーソルを当てるとヒントが出てくる。</li>
            </ul>
          </p>
          <br />
          <p>それでは、今回は以上となります。</p>
          <p>最後までお読みいただきありがとうございました。</p>
        </article>
      </LessonLayout>
    </div>
  )
}

export default withRouter(PrettierEslintComponent)