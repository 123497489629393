import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const TypesComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={6}
        title=' JavaScriptの型' />
        <p>今回は、JavaScriptのデータ型・オブジェクト型・リテラルについて学んでいきましょう。</p>
        <h2>データの型（Type）とは何か？</h2>
        <article>
          <p>まず初めに、データの型についての説明を致します。</p>
          <p>データ型とは、変数に入れるデータ（値）の種類の事です。</p>
          <p>データ型を大きく分けると、基本型とオブジェクト型（参照型）の2つに分類されます。</p>
          <br></br>
          <p>・基本型： 文字列や数値などの基本的な値の型のこと。</p>
          <p>・オブジェクト型： 変数に値そのものではなく、オブジェクトへの参照が代入されている。</p>
          <p>違いとして、変数はデータを一つだけ入れることができるのに対し、オブジェクトは複数のデータを格納することができます。</p>
        </article>
        <h2>データの型（Type）を知っておく必要性は何か？</h2>
        <article>
          <p>では、なぜ変数やオブジェクトの値の型を知っておく必要があるのでしょうか？</p>
          <br></br>
          <p>JavaやC言語など、静的型付け言語と呼ばれている言語では、変数を定義する際にデータ型を指定する必要があります。</p>
          <p>しかし、JavaScriptでは実行時にJavaScriptが自動的にデータ型を判断してくれる（動的型付け言語）ので、コーディングする時に指定する必要がないのであまり意識をしませんが、しっかりと意識しておく必要があるのです。</p>
          <br />
          <b>※ 動的型付け言語と静的型付け言語の違い</b>
          <br />
          <p>- 動的型付けとは</p>
          <p>その名の通り、動的にデータ型が設定される言語のことを言います。コードを書く時に変数や関数にどんなデータを入れるのか、型を指定しなくてもプログラムが実行されるときに変数に自動的にデータ型が設定されます。</p>
          <p>なので、プログラマーは変数や関数にどんな値が入るかをある程度予測するだけで、型の管理はインタプリタやコンパイラと呼ばれる「人間が理解できる言葉をコンピュータが理解できる言葉に翻訳するもの」が勝手にやってくれるので型の宣言をしなくても良くなります。</p>
          <p>型を宣言する必要がないため、小さいプログラムや型の変動が激しい環境であれば簡単に実装できますが、翻訳する分時間がかかるので、プログラムの実行速度は遅くなってしまいます。</p>
          <br />
          <p>- 静的型付けとは</p>
          <p>変数や関数に〜なデータの型が入りますよと事前に定義しておくことによって、その型以外のデータを変数では使えないように管理する方法です</p>
          <br />
          <p>ここで変数や関数になぜ型が必要なのか？という疑問が浮かびます。自動で型を翻訳してくれるならそれで問題ないのでは？と思いますよね。</p>
          <p>静的型付けが必要な理由は、「事前にネイティブ（機械語）にコンパイル（翻訳）するには必須である」という事です。</p>
          <p>つまり、事前に変数や関数の型は〜ですよと翻訳者（コンパイラやインタプリタ）に伝えることによって翻訳の手間が省けるわけです。</p>
          <p>特にiosのネイティブアプリを開発するには、動的型付け言語が利用できません。</p>
          <p>動的な型は、データの型を宣言しないため。実行時にデータのサイズがわかりません。そのため、メモリ容量を多めにとったり、実行時に余分な作業が求められる為、静的言語に比べると処理は遅くなります。</p>
          <p>変数にデータの型を事前に宣言することで、その変数は文字列なのか、数値なのか、配列なのかがコードを後から見たときにわかり、他人と共同で開発をする時に便利です。</p>
          <p>しかし、プログラムの構造が複雑になっていくと、その構造を把握している必要が出てくるので結構大変です。どちらが優れている。というよりは適材適所に合わせて使うのが良いでしょう。</p>
          <br />
          <p>JavaScriptは動的型付け言語で、自動的に型の変換を行ってしまうので、データ型を知っておかないと思わぬエラーが発生します。例えば、数字の計算がしたいのに、間違ってString型とNumber型を足した場合、欲しいデータ型であるNumber型ではなく、String型になってしまうのです。</p>
          <p>ですので、コードを書く時は変数がどんなデータ型か常に意識する必要があります。</p>
          <p>それでは、実際に基本型、オブジェクト型のデータ型についてそれぞれ見ていきましょう。</p>
        </article>
        <h2>データ型とは？</h2>
        <article>
          <p>データ型を大きく分けると、プリミティブ型とオブジェクトの２つに分類されます。</p>
          <p>プリミティブ型（基本型）は、真偽値や数値などの基本的な値の型のことです。</p>
          <p>プリミティブ型の値は、一度作成したらその値自体を変更できないというイミュータブル(immutable)の特性を持ちます。JavaScriptは文字列も一度作成したら変更できないイミュータブルの特性を持ち、プリミティブ型の一種として扱われます。</p>
          <br />
          <p>プリミティブ型でないものをオブジェクト（複合型）と呼び、オブジェクトは複数のプリミティブ型の値またはオブジェクトからなる集合です。</p>
          <p>オブジェクトは値そのものではなく値への参照を経由して操作されるため、参照型のデータとも言います。</p>
          <p>データ型を細かくみていくと、7つのプリミティブ型とオブジェクトからなります。</p>
          <br></br>
          <b>プリミティブ型（基本型）</b>
          <p>String：文字列（"text"）</p>
          <p>Number：数値（1, 20）</p>
          <p>Boolean：真偽値（true or false）</p>
          <p>Null：空の値</p>
          <p>Undefined：未定義</p>
          <p>Symbol：他の値と重複しないユニークなシンボル値</p>
          <p>巨大な整数(Biglnt): ES2020から追加された9007199254740992nなどの任意精度の整数のデータ型</p>
          <b>オブジェクト（複合型）</b>
          <p>プリミティブ以外のデータ</p>
          <p>オブジェクト、配列、関数、正規表現、Dateなど</p>
          <br></br>
          <p>※プロパティ：値に名前（プロパティ名）をつけたもの</p>
          <p><code>typeof</code>演算子を使用することで、データ型を調べることができます。</p>
          <p>オブジェクト型は最後に説明します。</p>
          <p>ちなみに、Symbol型は高度であり、あまり使われていないのでここでは取り上げません。</p>
        </article>
        <h2>リテラル</h2>
        <article>
          <p>リテラルとは、プログラムに直接記述するデータ値のことで、プログラム上で数値や文字列など、データ型の値を直接記述できるように構文として定義されたものです。 </p>
          <p>プリミティブ型の値や一部のオブジェクトはリテラルを使うことで簡単に定義できる様になっています。</p>
          <p>例えば、"と"で囲んだ範囲が文字列リテラルとなります。</p>
          <CodeBlock>
            {`
//"と"で囲んだ範囲が文字列リテラル
const str = "Hello";
            `}
          </CodeBlock>
          <p>テンプレートリテラルは、htmlなどを書きたいときに便利です。</p>
          <p>リテラル表現がない場合は、その値を作る関数に引数を渡して作成する形になります。そのような冗長な表現を避ける方法として、よく利用される主要な値にはリテラルが用意されています。</p>
          <p>これらのリテラルについてstring型(文字列）からみていきましょう。
          </p>
        </article>
        <h2>String型（文字列）</h2>
        <p>まずは、テキストの値を表す連続した文字で、文字列を表すString型です。</p>
        <h3>String型の書き方</h3>
        <article></article>
        <p>文字列はダブルクォーテーション（""）かシングルクオーテーション（''）、バッククオート（``）で囲みます。</p>
        <CodeBlock>
          {`
const name = 'first'; (シングルクオーテーション）
const middle = "second"; （ダブルクォーテーション）
const last = \`bos\`; （バッククォート）
`}
        </CodeBlock>
        <p>ちなみに、JavaScript は大文字と小文字を区別するので、nameという変数は Name と同じではありません。</p>
        <br></br>
        <p>では、she's so coolを表示したい時はどうでしょうか？</p>
        <CodeBlock>
          {`
const sentence = 'she's so cool';
`}
        </CodeBlock>
        <p>また、"cool"と表示したい場合はどうでしょうか？</p>
        <CodeBlock>
          {`
const sentence = \`she's so "cool"\`;
`}
        </CodeBlock>
        <p>これは、バッククオートを用いた表記法です。</p>
        <p>文字列内部に出現しない別のクォート記号を使うことで、エスケープをせずに書くこともできますが、エスケープシーケンスと呼ばれるバックスラッシュ（\）を用いた表記法もあり、以下のように書くことができます。</p>
        <CodeBlock>
          {`
const sentence = "she's so \\"cool\\"";
`}
        </CodeBlock>
        <h3>エスケープシーケンスの種類</h3>
        <article>
          <p>エスケープシーケンスはバックスラッシュ（\）と文字を組み合わせることで、特殊な文字を表すことができ、結果は同じです。</p>
          <br></br>
          <p>・\n －改行</p>
          <p>・\b －バックスペース</p>
          <p>・\t －水平タブ</p>
          <p>・\\ －バックスラッシュ</p>
          <p>・\" －ダブルクォーテーション</p>
          <p>・\' －シングルクォーテーション</p>
        </article>
        <h3>複数行の文字列を表記したい</h3>
        <article>
          <p>また、複数行の文字列を一行に集約することなく、そのまま表示したい場合もあります。ダブルクォートとシングルクォートどちらも、改行をそのまま入力できません。改行を含んだ文字列は定義できないため、構文エラーになりますが、その際は、ES2015から導入されたテンプレートリテラルと呼ばれるバッククォート（`）を用いることができます。</p>
          <p>テンプレートリテラルを用いることで、改行記号のエスケープシーケンス（\n）を使わずに、以下のように書くことができるのです。</p>
          <CodeBlock>
            {`
const song = \`Ohhh
ya
I like
you\`;

(実行結果)
Ohhh
ya
I like
you
`}
          </CodeBlock>
          <CodeBlock>
            {`
const html = \`
<div>
  <h2></h2>
</div>
\`;
`}
          </CodeBlock>
        </article>
        <h3>String型で文字を連結したい</h3>
        <article>
          <p>文字列の連結したいときは、プラス（+） を使うことで文字を繋げることができます。</p>
          <CodeBlock>
            {`
const name ='teru';
const hello = 'Hello my name is ' + name + '.
Nice to meet you.';
(実行結果)
Hello my name is teru. Nice to meet you.
`}
          </CodeBlock>
          <p>また、letは値の再代入が可能なのでこのように書くこともでき、結果は同じとなります。</p>
          <CodeBlock>
            {`
let hello2 = 'Hello my name is ';
hello2 = hello2 + name;
hello2 = hello2 + ' Nice to meet you ';

(実行結果)
Hello my name is teru. Nice to meet you.
`}
          </CodeBlock>
          <p>ただし、これでは分かりにくいコードとなっているので、バッククォートを用い、以下のように記述する方が分かりやすいです。バッククォートは "拡張機能" の引用符です。</p>
          <p>また、名前の通りテンプレートの様な機能も持っています。テンプレートリテラル内で、変数を {'${}'} の中にラップすることで、変数を埋め込み文字列の中で表現することができます。</p>
          <CodeBlock>
            {`
const hello = \`Hello my name is \${name}. Nice to meet you. I am \${1 + 100} years old.\` ;
            `}
          </CodeBlock>
          <p>ポイントとしては、変数を用いたり数字の計算をするために{'${}'} を使用することです。</p>
          <p>テンプレートリテラルも他の文字列リテラルも他の文字列リテラルと同様に同じリテラル記号を内包したい場合は、\を使ってエスケープする必要があります。</p>
          <p>使用例）</p>
          <CodeBlock>
            {`
const name ='teru';
const hello = 'Hello';

const html = \`
<div>
  <h2>\${name}</h2>
  <p>\${hello}</p>
</div>
\`;

console.log(html);
document.body.innerHTML = html;

(実行結果)
<div>
  <h2>teru</h2>
  <p>Hello</p>
</div>
            `}
          </CodeBlock>
          <br />
          <p>それでは、こちらで実際にConsoleを開き、動作を確認してみましょう！</p>
          <br />
          <iframe src='https://stackblitz.com/edit/js-sgmpxj?embed=1&file=index.js' title='巻き上げExample' style={{ width: '100%', height: '400px' }}></iframe>

        </article>
        <h2>Number型（数値）</h2>
        <article>
          <p>次に、Number型（数値）について見ていきましょう。</p>
          <p>他のプログラミング言語では、Number型といってもInteger型、long型など、Number型の中にも様々な種類があります。</p>
          <p>しかし、JavaScriptではNumber型の一種類のみで、その中でも、整数か小数点付きかの2種類のリテラルがあるだけです。これは、JavaScriptが動的型付け言語だからです。</p>
          <br></br>
          <h3>整数（integer）</h3>
          <p>整数リテラルには次の４種類があります</p>
          <br />
          <ul>
            <li>
              <p>10進数:数字の組み合わせ。</p>
              <p>ただし、複数の数字を組み合わせた際に、先頭を0から開始すると8進数として扱われる場合があります。</p>
              <p>（例）0、2、10</p>
            </li>
            <li>
              <p>2進数:0b(または、0B)の後に、0または1の数字の組み合わせ</p>
              <p>（例）0b0, 0b10, 0b1010</p>
            </li>
            <li>
              <p>8進数:0oまたは（0O)の後に、0から7の数字の組み合わせ。</p>
              <p>（例）0o644, 0o777</p>
            </li>
            <li>
              <p>16進数:0xまたは（0X）の後ろに、0から9までの数字とaからfまたはAからFのアルファベットの組み合わせ。</p>
              <p>（例）0x30A2, 0xEEFF</p>
            </li>
          </ul>
          <CodeBlock>
            {`
const age = 100;
`}
          </CodeBlock>
          <h3>浮動少数点数（float）</h3>
          <p>浮動小数点数をリテラルとして書く場合には次の２種類の表記が利用できます。</p>
          <p>.(ドット）を含んだ数字。3.14</p>
          <p>eまたはEを含んだ数字。2e8</p>
          <p>※ 0から始まる浮動小数点数は、0を省略して書くことができますが、0から始まる場合でも省略せずに書いた方が意図しない挙動を減らせるでしょう。</p>
          <CodeBlock>
            {`
const age = 100.5;
`}
          </CodeBlock>
          <h3>Biglnt</h3>
          <p>ES2020以前では正確に扱える数値の最大値は2^53-1(9007199254740991)で、これよりも大きな値を表現したり計算すると、間違った結果になる場合がありました。</p>
          <p>この問題を解決するためにES2020よりBiglntという新しい整数型のデータ型とリテラルが追加され、Biglntでは2^53-1よりも大きな整数を正しく表現できます。</p>
          <p>Biglntリテラルは、数値の後ろにnをつけます。</p>
          <CodeBlock>
            {`
console.log(1n); // => 1n
            `}
          </CodeBlock>
          <br />
          <b>※ Numeric Separators</b>
          <p>数値が大きくなるほど、桁数の見間違いなどが発生しやすくなりますよね。例えば1兆(1000000000000)など、桁数を読み取りにくいです。一十百千万...、と数えても良いですが、時間もかかりますし、何より見間違いが発生しやすいです。</p>
          <p>ES2021から、数値リテラル内の区切り文字としてアンダーバー（ _ ）を追加できるNumeric Separatorsがサポートされています。</p>
          <CodeBlock>
            {`
1000000000000;
1_000_000_000_000;
//数値リテラルを評価する際にアンダーバー(_)は無視されるため、同じ意味になります。
            `}
          </CodeBlock>
          <p>※ Numeric Separatorsは数値リテラルである整数、浮動小数点、Biglntのリテラル内のみで利用できます。</p>
        </article>
        <h2>演算子</h2>
        <article>
          <p>演算子はよく利用する演算処理を記号などで表したものです。</p>
          <p>演算子は演算する対象を持ちます。この演算子の対象のことを「被演算子（オペランド）」と呼びます</p>
          <br />
          <b>2項演算子</b>
          <p>『左オペランド 演算子 右オペランド』のように演算子に対して前後に合計2つのオペランドを取る演算子を2項演算子と呼びます。</p>
          <p>例）1 + 2;</p>
          <p>この場合だと、1と2がオペランドになります。</p>
          <br></br>
          <p>2項演算子の例として、以下のようなものがあります。</p>
          <p>+（たす）</p>
          <p>-（ひく）</p>
          <p>* (掛ける)</p>
          <p>**（べき乗）</p>
          <p>/ (割る)</p>
          <p>% (割った余り)</p>
          <br></br>
          <p>例）</p>
          <CodeBlock>
            {`
10 % 3 // -> 1
10 / 2 // -> 5
10 ** 2 // -> 100
            `}
          </CodeBlock>
          <br />
          <b>単項演算子</b>
          <p>1つのオペランドを取る演算子を単項演算子と呼びます。</p>
          <p>+ (単項プラス演算子)</p>
          <p>- (単項マイナス演算子)</p>
          <p>++ (インクリメント演算子)</p>
          <p>-- (デクリメント演算子)</p>
          <br />
          <p>例）</p>
          <CodeBlock>
            {`
+1 // -> 1
-1 // -> -1

let num = 1;

num++; // -> 2 (num = num + 1; と同じ)
num--; // -> 0 (num = num - 1; と同じ)
            `}
          </CodeBlock>
          <p>また、インクリメント/デクリメント演算子はオペランドの後ろに置くか前に置くかで、評価の順番が異なります。</p>
          <p>例えば、後ろにインクリメント演算子を置いた場合、numの評価結果を返し、numに対して+1をするため、先ほどの例で挙げた結果では+1になります。</p>
          <p>前に演算子を置いた場合と後ろに置いた場合の結果の違いを見てみましょう。</p>
          <CodeBlock>
            {`
let num = 1;

------------------------------------
//前に演算子を置いた場合
console.log(++num); // -> 2
console.log(num); // -> 2
------------------------------------
//後ろに演算子を置いた場合
console.log(num++); // -> 1
console.log(num); // -> 2
------------------------------------
            `}
          </CodeBlock>
          <p>これは、前にインクリメント演算子を置いた場合、numに対して+1をして、numの評価結果を返す。という評価の違いが生む現象です。</p>
          <p>もちろん、デクリメント演算子でも同様の評価順番で処理をします。</p>
          <p>ただ、この2つの使い分けが必要となるケースは多くはありません。評価の順番が異なることだけ頭の片隅に入れておきましょう。</p>
          <br />
          <b>比較演算子</b>
          <p>比較演算子とは、オペランド同士の値を比較し、真偽値を返す演算子です。</p>
          <p>比較演算子の例として以下のものがあります。</p>
          <p>=== (厳密等価演算子)</p>
          <p>・・・左右の2つのオペランドを比較して、同じ型で同じ値である場合に、trueを返します</p>
          <p>!== (厳密不等価演算子)</p>
          <p>・・・左右の2つのオペランドを比較して、異なる型または異なる値である場合にtrueを返します。</p>
          <p>== (等価演算子)</p>
          <p>・・・2つのオペランドを比較して、同じデータ型のオペランドを比較する場合は、厳密等価演算子(===)と同じ結果になります。</p>
          <p>!= (不等価演算子)</p>
          <p>・・・2つのオペランドを比較して、等しくない場合にtrueを返します。</p>
          <p>{'>'} (大なり演算子/より大きい)</p>
          <p>・・・左のオペランドが右のオペランドより大きい場合にtrueを返します。</p>
          <p>{'>='} (大なりイコール演算子/以上)</p>
          <p>・・・左のオペランドが右オペランドより大きい場合、または等しい場合にtrueを返します。</p>
          <p>{'<'} (小なり演算子/より小さい)</p>
          <p>・・・左のオペランドが右のオペランドより小さい場合にtrueを返します。</p>
          <p>{'<='} (小なりイコール演算子/以下)</p>
          <p>・・・左のオペランドが右のオペランドより小さい場合、または等しい場合にtrueを返します。</p>
          <br />
          <b>== と===の違いは何か？</b>
          <p>===(厳密等価演算子)は左の値と右の値が同じかチェックし、かつ左右のデータ型の種類も同じかどうかもチェックする演算子です。</p>
          <p>一方、==(等価演算子)は左右の値が同じかどうかだけチェックし、データ型はチェックしないという違いがあります。以下の例を見て頂くと分かりやすいのではないでしょうか？</p>
          <CodeBlock>
            {`
10 === 10
< true

"10" == 10
< true

"10" === 10
< false
`}
          </CodeBlock>
          <br />
          <b>暗黙的な型変換</b>
          <p>さて、上の例のように等価演算子(==)と厳密等価演算子(===)の結果の違いが生まれる理由は『暗黙的な型変換』が理由です。</p>
          <p>暗黙的な型変換とは、ある処理において、処理過程で行われる明示的でない型変換のことを言います。</p>
          <p>例えば、等価演算子(==)で異なるデータ型を比較した場合、同じ型となるように暗黙的に型変換をしてから比較するように動いています。</p>
          <p>今回の例だと文字列の10と数字の10を比較していますが、文字列の10を暗黙的に数字に変換しているわけですね。</p>
          <p>JavaScriptでは、エラーが発生するのではなく暗黙的な型変換が行われてしまうケースが多くあります。このような場合、プログラムは例外を投げずに処理が進むため、バグの発見が難しくなります。</p>
          <p>しかし、この暗黙的な型変換を避ける方法があります。それは、常に厳密等価演算子(===)を使用することです。</p>
          <p>これによって、意図しない比較結果を避けることが出来ます。そのため、比較には等価演算子ではなく厳密等価演算子を使うことを推奨しています。</p>
          <br />
          <b>ビット演算子</b>
          <p>ここでは詳しくは触れませんが、ビット演算しについて軽く触れておきます。</p>
          <p>ビット演算とは、このビットを使う演算のことです。名前のままですね。</p>
          <p>まず、ビット(bit)とはコンピュータの判別できる処理の最小の単位のことを言います。コンピュータは0と1しか判別できません。なので、コンピュータにとっては文字、画像、動画、音楽などのデータはこの0と1の寄せ集めに過ぎません。</p>
          <p>ビット演算は、このビットの移動やビットの単位の論理演算を行うことを言います。</p>
          <p>そして、ビット演算子はその名の通り、ビット演算に用いる演算子のことを指し、JavaScriptのビット演算子には『{' & '},{' | '},{' ^ '},{' ~ '},{' << '},{' >> '},{' >>> '}』があります。</p>
          <p>詳しく知りたい方は調べてみてください。プログラムにおいて知らなくてもなんとかなる場面は多いですが、知っておいて損はありません。プログラムの選択肢を広げることもでき、一度覚えれば多くのコンピュータ言語で使用することができるのでさまざまな環境で使用することが出来ます。</p>
          <br />
          <b>論理演算子</b>
          <p>論理演算子は論理演算を表す記号や符号のことを指し、真(true)と偽(false)の真偽値に対して演算を行うことが出来ます。</p>
          <p>JavaScriptの論理演算子には『{' && '} AND・かつ(AND演算子)』、『{' || '} OR・または(OR演算子)』、『{' ! '} NOT・否定(NOT演算子)』があります。</p>
          <p>使用方法は、</p>
          <p>AかつBの場合~ (AND演算)</p>
          <p>AまたはBの場合~ (OR演算)</p>
          <p>A以外の場合~ (NOT演算)</p>
          <p>といった様に、if/else文や条件分岐に使用されることが多いです。</p>
          <br />
          <b>条件演算子( ? と : )</b>
          <p>三項をとる演算子であるため、三項演算子とも呼ばれます。</p>
          <p>条件分岐による値を返せるため、条件によって変数の初期値が違う場合などに使われます。</p>
          <p>条件演算子は以下のように書きます。</p>
          <CodeBlock>
            {`
// 条件式 ? trueの場合の処理 : Falseの場合の処理;

const T = true ? "A" : "B";
console.log(T); // -> "A"
const F = false ? "A" : "B";
console.log(F); // -> "B"
            `}
          </CodeBlock>
          <br />
          <b>グループ化演算子( ( と ) )</b>
          <p>グループ化演算子は複数の2項演算子が組み合わさった場合に、演算子の優先順位を決めるができる演算子です。</p>
          <p>例えば、以下のようにグループ演算子（）で囲んだ部分が最初に処理されるため、結果も変化します。</p>
          <CodeBlock>
            {`
console.log(1 + 2 * 3) // -> 7
console.log((1 + 2) * 3) // -> 9
            `}
          </CodeBlock>
          <br />
          <b>カンマ演算子( , )</b>
          <p>カンマ演算子は、カンマ(,)で区切った式を左から順に評価し、最後の式の評価結果を返します。</p>
          <CodeBlock>
            {`
式1, 式2, 式3; // -> 「式1 -> 式2 -> 式3」の順番に評価される
            `}
          </CodeBlock>
        </article>
        <h3>Mathオブジェクトを使ってみよう。</h3>
        <article>
          <p>JavaScriptでは、算術演算子以外にも、Mathオブジェクトを用いることによって様々な計算することが可能です。</p>
          <p>使い方としては、Math.演算子() として、ドット（.）で繋げなければなりません。以下に、Mathオブジェクトの演算子をまとめています。</p>
          <br></br>
          <p>・abs（絶対値をとる）</p>
          <p>・round（四捨五入）</p>
          <p>・ceil（切り上げ）</p>
          <p>・floor（切り捨て）</p>
          <p>・max（一番大きな値をとる）</p>
          <p>・min（一番小さな値をとる）</p>
          <p>・random（ランダムを発生させる）</p>
          <p>・sqrt(平方根)</p>
          <p>・pow（累乗）</p>
          <p>・exp（eの累乗）</p>
          <br></br>
          <p>例）</p>
          <p>Math.round(20.5)</p>
          <p>{'< 21'}</p>
          <br></br>
          <p>Math.floor(20.2) </p>
          <p>{'< 20'}</p>
          <br></br>
          <p>Math.ceil(20.99999)</p>
          <p>{'< 21'}</p>
          <br></br>
          <p>・0～1の数字をランダムに返す。</p>
          <p>Math.random()</p>
          <p>{'< 0.89876'}</p>
          <br></br>
          <p>それでは、算術演算子とMathオブジェクトを組み合わせて計算すると、以下のようになります。</p>
          <CodeBlock>
            {`
const candy = 20;
const kids = 3;
const eachKidGets = Math.floor(candy / kids);
const dadGets = candy % kids;

console.log(\`Each kids gets \${eachKidGets}\`);
< 6

console.log(\`Dad gets \${dadGets}\`);
< 2
`}
          </CodeBlock>
        </article>
        <h3>JavaScriptで計算する際の注意点①</h3>
        <article>
          <p>1 + "1" </p>
          <p>⇒ "11"</p>
          <br></br>
          <p>上記のように、Number型とString型を混ぜて計算してしまうと、Number型の計算結果（2）が返ってくるように見えますが、実際はString型（"11"）になってしまいます。</p>
          <p>これは、エラーのもととなってしまうため、String型とNumber型を混ぜないように注意しましょう。</p>
        </article>
        <h3>JavaScriptで計算する際の注意点②</h3>
        <article>
          <p>小数を含む計算をする時は注意しよう！</p>
          <p>0.1 + 0.2;</p>
          <p>⇒ 0.300000000000004</p>
          <br></br>
          <p>例えば、上記のように少数を伴う計算をした場合、誤差が生じます。これは、JavaScriptがIEEE 754という規格に従って実装されているためです。</p>
          <p>つまり、0.1と書いた場合には計算上、0.10000000000555111とされ計算結果に誤差が発生します。</p>
          <p>JavaScriptでは、Number型1種類しかありませんので、「整数か小数か」「ビット数」などによる区別を持ちません。</p>
          <p>結論から言うと、JavaScriptの数値は64ビットの浮動小数点数です。つまり、JavaScriptの数値は全てが小数となるのです。</p>
          <p>実際には、1 + 1 が1.0 + 1.0として計算されているということになります。</p>
          <br></br>
          <p>少数を含む計算の誤差を修正する方法はいくつかありますが、基本的には整数値にしてから計算することで解決します。</p>
          <p>例えば、10ドル34セントの場合は、10.34ではなく、計算上1034を用いるようにします。</p>
          <p>これでも誤差が生じる場合もありますので、bignumber.jsというJavaScriptライブラリを使用する事をオススメします。</p>
          <a className='text-blue-900' href='https://github.com/MikeMcl/bignumber.js/'>https://github.com/MikeMcl/bignumber.js/</a>
        </article>
        <h3>Number型のInfinityとは？</h3>
        <article>
          <p>JavaScriptの特徴として、大きすぎる数字はInfinity または小さ過ぎる数字は -Infinityとなります。実は、これもNumber型です。Infinityは0で割ったり、大きすぎる数値を計算した場合に、表示されます。</p>
          <p>1000 ** 200 === Infinity</p>
          <p>⇒ Infinity: true</p>
          <p>1000 ** 200をはconsole上ではnullとなりますが、===（厳密等価演算子）を用いることで、Infinityかどうか判定することができます。</p>
        </article>
        <h3>Number型のNaNとは？</h3>
        <article>
          <p>計算できない場合は、NaN (Not a Number)が返ってきます。ただし、NaNもNumber型です。</p>
          <p>NaNは技術的には数値型であるにもかかわらず「数値でない」ことを表します。例えば、別のデータ型を使って無効な計算をしようとすると、結果がNaNになります。</p>
          <p>isNaN(10 / 'dog')</p>
          <p>⇒ NaN: true</p>
          <p>NaNであるかどうかは、isNaN関数を使うことを判定することができます。</p>
        </article>
        <h3>データ型を特定する方法とは？</h3>
        <article>
          <p>JavaScriptのデータ型は、typeof演算子で確認することができます。</p>
          <p>特に、データを管理する際に何型の値であるか明らかにする必要があり、Chromeでtypeofを入力して確認する方法や、以下のようにconsole.log()で確認する方法があります。</p>
          <CodeBlock>
            {`
console.log(typeof "age");
< string

console.log(typeof 5);
< number
`}
          </CodeBlock>
          <p>ただし、オブジェクト型を確認するためには、typeofではなく、instanceofキーワードを使用しましょう。</p>
          <p>それでは、実際にコードを実行してconsole上で動作の確認をしてみましょう。</p>
          <iframe src='https://stackblitz.com/edit/js-zwc9gw?file=index.js' title='number' style={{ width: '100%', height: '400px' }}></iframe>
        </article>
        <h2>null（何もない）and Undefined（未定義）とは？</h2>
        <article>
          <p>undefinedはリテラルではありません。プロパティや値がまだ定義されていない状態、まだ存在しない、あるいはもう存在しないものを表現するグローバル変数のundefinedという値です。</p>
          <p>これに対して、true,false,nullなどは、グローバル変数ではなくリテラルであるため、同じ名前の変数を定義することはできません。</p>
          <CodeBlock>
            {`
let dog;
console.log(dog);
< undefined
`}
          </CodeBlock>
          <br />
          <p>null は、存在するが中身が空であることを表現したものです。</p>
          <p>基本的に、未定義の変数を参照したいときにはReferenceErrorの例外が発生します。しかし、値がない事を表現したいときはnullを代入することで、参照できるようになるのです。</p>
          <p>例えば、初期値はなるべく代入するようにしていても、目ぼしい初期値がない場合も状況によってはありえます。その場合、nullを代入する事で、初期値として「カラッポ」であることを表すことが可能となります。 </p>
          <CodeBlock>
            {`
const foo = null;
console.log(foo);
< null
`}
          </CodeBlock>
        </article>
        <h2>Booleans（論理値） and Equality（等価性）</h2>
        <article>
          <p>Boolean型はtrue（正しい）とfalse（謝り）の2つの種類のリテラルがあり、Yes or No, On or Offのような二者択一のような型となります。</p>
          <p>例）以下では、18は19よりも小さな数値であるので、等式としては間違いなので、falseとなります。</p>
          <CodeBlock>
            {`
const age = 18;
const ofAge = age > 19;
console.log(ofAge);
> false
`}
          </CodeBlock>
          <br></br>
          <p>また、Boolean型で等価であるか確認したい時は、=ではなく===を使用します。</p>
          <CodeBlock>
            {`
const age = 100;
const age2 = 100;

age === 100
< true

age === 10
< false

age === age2
< true
`}
          </CodeBlock>
        </article>
        <h2>Object型（オブジェクト型）</h2>
        <article>
          <p>JavaScriptにおいて、オブジェクトはあらゆるものの基礎となります。</p>
          <p>オブジェクト型は、プロパティ（キーとバリュー）の集合体であり、データや関数のコレクションです。オブジェクト型を用いることで、データなどをひとまとめにして管理やすくなるというメリットがあります。</p>
          <p>例えば、人というオブジェクトを作ると、以下のようにデータをまとめることができます。</p>
          <br></br>
          <p>・名前</p>
          <p>・性別</p>
          <p>・生年月日</p>
          <p>・住所</p>
        </article>
        <h3>オブジェクトの作り方と呼び出し方</h3>
        <article>
          <p>配列は[]で囲んで宣言しますが、オブジェクトはオブジェクトのキーと値を:で区切ったものを{'{}'} の中に書くことで作成できます。</p>
          <p>これは、personのデータの集合体のようなものです。</p>
          <CodeBlock>
            {`
const person = {
  first: 'teru',
  last: 'mina',
  age: 100
};
`}
          </CodeBlock>
          <p>実際に、これらの値を使用したいときは、オブジェクト名.キーの値 または、オブジェクト名[キーの値]とします。ドット演算子（.）でつないで参照する方法と[]（ブラケットを使用する方法があります。</p>
          <CodeBlock>
            {`
person.first or person["first"]
⇒ "teru"

person.last or person["last"]
⇒ "mina"

person.age or person["age"]
⇒ 100
`}
          </CodeBlock>
          <p>ドット記法では、プロフパティ名が変数名と同じく識別子である必要があります。そのため、次のように識別子でない場合は、プロパティ名はドット記法を使用して書くことはできません。</p>
          <CodeBlock>
            {`
// プロパティ名が文字列の555
const person = {
  "555" : "Tokyo"
};

person["555"]
// -> ブラケット記法では、文字列として書くことができる。
person.555
// -> ドット記法では、数値から始まる識別子は利用できない。
            `}
          </CodeBlock>
          <p>オブジェクトはとても重要で、配列や、正規表現もこのオブジェクトが元になっています。</p>
          <h3>配置リテラル</h3>
          <p>オブジェクトリテラルと並んで、よく使われるのが配列リテラルです。</p>
          <p>これは、ブラケット[]で値とカンマ区切りで囲み、その値を持つArrayオブジェクトを作成します。</p>
          <p>※ Arrayオブジェクトとは、組み込み関数（JavaScript側で予め用意してくれているオブジェクト）の一つで、配列を作成して操作するオブジェクトです。</p>
          <CodeBlock>
            {`
const array = ["first:1", "second:2", "third:3"];

console.log(array[0]); //-> "first:1"
// ※ indexは0から始まります。
console.log(array[1]); //-> "second:2"
            `}
          </CodeBlock>
          <h3>正規表現リテラル</h3>
          <p>JavaScriptでは咳表現をリテラルで書くことができます。</p>
          <p>正規表現リテラルはスラッシュ（/)によって囲みます。正規表現のパターン内では、+や\から始まる特殊文字が特別な意味を持ちます。</p>
          <br />
          <p>※ 正規表現とは、「いくつかの文字列（文字を並べたもの）を一つの形式で表現する表記方法の事」です。</p>
          <p>この表現方法を使用することにより、たくさんの文章の中から容易に見つけたい文字列を検索することが出来るため非常に便利です。</p>
          <p>通常の文字(a ~ zなど)と、”メタキャラクタ”という特殊文字『 . , ^ $ [ ] * + ? | ( )』から構成される文字パターンで構成されています。</p>
        </article>
        <h2>プリミティブ型とオブジェクト</h2>
        <article>
          <p>プリミティブ型は基本的にリテラルで表現しますが、真偽値（Boolean）、数値（Number）、文字列（String）はそれぞれオブジェクトとして表現する方法もあります。これらはプリミティブ型の値をラップしたようなオブジェクトであるため、ラッパーオブジェクトと呼ばれます。</p>
          <p>new演算子と対応するコンストラクタ関数を利用して作成します。</p>
          <p>しかし、ラッパーオブジェクトを使うべき理由は特にありません。</p>
          <p>JavaScriptではプリミティブ型のデータに対してもオブジェクトのように参照できる仕組みがあるからです。</p>
          <CodeBlock>
            {`
// 文字列をラップしたStringラッパーオブジェクト
const person = new String("太郎");
console.log(typeof person); // -> "object
console.log(person.length); // -> 2

// プリミティブ型の文字列データ
const person = "花子";
console.log(typeof person); // -> "string"
console.log(person.length); // -> 2
            `}
          </CodeBlock>
          <p>これは、プリミティブ型のデータのプロパティにアクセスする際に、対応するラッパーオブジェクトへ暗黙的に変換してからプロパティにアクセスするためです。</p>
          <p>このように、ラッパーオブジェクトを明示的に作成する必要はないため、常にリテラルでプリミティブ型のデータを表現することを推奨されています。</p>
        </article>
        <h2>動的型付け言語の特徴について</h2>
        <article>
          <p>最後に、動的型付け言語のおもしろい特徴についてシェアします。</p>
          <p>動的型付け言語の場合には、途中で変数のデータ型が変わることも許容されるのです。つまり、数値型の変数に文字列を代入することが可能となります。</p>
          <CodeBlock>
            {`
let sample = 10;
sample = 'text'; //文字列
`}
          </CodeBlock>
          <p>宣言時にはNumber型（数値）であった変数ですが、文字列を代入してもエラーにはなりません。文字列が代入されたタイミングでデータ型がNumber型からString（文字列）に変換されるためです。</p>
          <br></br>
          <iframe src='https://stackblitz.com/edit/js-8yyx1d?file=index.js' title='null, undefined' style={{ width: '100%', height: '400px' }}></iframe>
          <br></br>
          <h2>エクササイズ</h2>
          <p>それでは、こちらにエクササイズを用意しましたので、StackBlitzでチャレンジしてみましょう。</p>
          <iframe src='https://stackblitz.com/edit/js-kzn2si?file=index.js' title='types excercise' style={{ width: '100%', height: '400px' }}></iframe>
          <br />
          <details>
            <summary>答え</summary>
            <p>1.</p>
            <CodeBlock>
              {`
const quote = "He read \\"The Cremation of Sam McGee\\" by R.W. Service.";
console.log(quote);
`}
            </CodeBlock>
            <p>2.</p>
            <CodeBlock>
              {`
const home = 'c:\\\\temp';
`}
            </CodeBlock>
            <p>3.</p>
            <CodeBlock>
              {`
const poem =
'Roses are red,\\n\\
Violets are blue.\\n\\
Sugar is sweet,\\n\\
and so is foo.'
`}
            </CodeBlock>

          </details>
          <br />
          <p>以上、今回はJavaScriptのデータ型について学んでいきましたが、いかがだったでしょうか？</p>
          <p>長くなってしまいましたが、一度に全て覚える必要などありません。わからなくなったらまたここに戻ってきてください。</p>
          <p>最後までお読みいただきありがとうございました。</p>
        </article>
      </LessonLayout>
    </div>
  )
}

export default withRouter(TypesComponent)
