import * as React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const CodeBlock = ({ children, language='javascript' }) => {
  return (
    <>
      { language==='bash' && <SyntaxHighlighter language='javascript' customStyle={{ fontSize: '.8em', color: 'hotpink' }} style={monokaiSublime}>
        output:
      </SyntaxHighlighter>}
      <SyntaxHighlighter language={language} customStyle={{ fontSize: '1em', padding: '0 18px', margin: '10px 0' }} style={monokaiSublime}>
        { children }
      </SyntaxHighlighter>
    </>
  )
}

export default CodeBlock
