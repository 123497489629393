import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DOM_1Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={12}
         title=' DOM_1 - DOMの紹介 - ' />
        <p>
          JavaScriptは、ウェブブラウザやサーバー、ロボットなど多くの環境で使用されています。
        </p>
        <p>
          ただ、最もよく見られるのはウェブブラウザ上ではないでしょうか？
        </p>
        
        <p>
          私たちがHTMLを書く時、ブラウザはHTMLをDOM（Document Object
          Model）に変換します。そして、ウェブブラウザーのデベロッパーConsoleのElementsに表示されているものが、DOMなのです。
        </p>
        <h2>

          ブラウザの仕組みについて
        </h2>
        
        <p>
          ブラウザは、 HTML を読み込むと内部的に Document Object Model もしくは DOM
          と呼ばれるこういったデータ構造を作ります。そして、その内容に応じてページが描画がされる、という仕組みになっています。
        </p>
        
        <p>
          そこで、DOMをJavaScriptで操作することで様々な機能を作ることができます。
        </p>
        
        <p>
          今回は、JavaScriptでアプリケーションを作るために必須であるDOM操作について見ていきましょう。
        </p>
        
        <p>
          ちなみに、HTMLはDOMにアクセスするためのインターフェースです。ただし、JavaScriptのようなスクリプティング言語で実際に書き換え、操作しているのはHTMLではなく「HTMLに連動されているDOMである」ことを注意しておいてくださいね。
        </p>
        
        <h3>
          windowオブジェクトを操作してみよう！
        </h3>
        
        <p>
          windowインターフェイスは、DOMを収めるウインドウを表します。また、windowオブジェクトはJavaScriptに予め用意されているオブジェクトで、何も書いてない状態でもアクセスすることができます。
        </p>
        
        <p>
          それでは、実際にChromeのConsoleで例を見ていきましょう。
        </p>
        
        <p>
          window.locationについて
        </p>
        
        <CodeBlock>
          {`
> window.location
< Location {ancestorOrigins: DOMStringList, href: "https://developer.mozilla.org/ja/",
  origin: "https://developer.mozilla.org", protocol: "https:", 
  host: "developer.mozilla.org", …}
        `}
        </CodeBlock>
        
        <p>
          window.locationは、document の現在位置についての情報を持つ
          <a href='https://developer.mozilla.org/ja/docs/Web/API/Location'>
            Location
          </a>
          オブジェクトを返します。
        </p>
        
        <p>
          また、windowオブジェクトはJavaScriptでは特別であり、window.locationのwindowを省略することが可能です。
        </p>
        
        <p>
          では、詳細（⇩）をクリックしてみると、hostやprotocolなど様々な情報を確認することができます。
        </p>
        
        <CodeBlock>
          {`
host: "developer.mozilla.org"
hostname: "developer.mozilla.org"
href: "https://developer.mozilla.org/ja/"
origin: "https://developer.mozilla.org"
pathname: "/ja/"
port: ""
protocol: "https:"
        `}
        </CodeBlock>
        
        <p>
          次に、innerWidthプロパティを見てみましょう。
        </p>
        
        <CodeBlock>
          {`
> innerWidth
< 1330
        `}
        </CodeBlock>
        
        <p>
          window.innerWidthは、ウィンドウの内部の幅をピクセル単位で返します。
        </p>
        
        <p>
          つまり、innnerWidthはブラウザの横幅を確認することができるのです。
        </p>
        
        <p>
          それでは、window.documentについてです。
        </p>
        
        <CodeBlock>
          {`
> document
< ⇒#document        
        `}
        </CodeBlock>
        
        <p>
          window.document は、ウィンドウに含まれている文書への参照を返します。
        </p>
        
        <p>
          #documentをクリックすると、DOMの全ての要素が選択されたのが確認できるので、documentはすべてのelements(DOM)にアクセスすることができるということがわかります。
        </p>
        
        <p>
          window.navigatior
        </p>
        
        <CodeBlock>
          {`
 > navigator
 < Navigator {vendorSub: "", productSub: "20030107", vendor: "Google Inc.",
  maxTouchPoints: 20, userActivation: UserActivation, …}   
        `}
        </CodeBlock>
        
        <p>
          window.navigatorは、navigatorオブジェクトへの参照を返します。つまり、デバイスにおけるスクリプトを実行しているアプリケーションについての情報を得ることができます。
        </p>
        
        <p>
          ⇒をクリックすると、詳細情報が確認可能です。
        </p>
        
        <CodeBlock>
          {`
appCodeName: "Mozilla"
appName: "Netscape"
credentials: CredentialsContainer {}
deviceMemory: 8
doNotTrack: null
        `}
        </CodeBlock>
        
        <p>
          例えば、console上で、以下のコードを入力してみます。
        </p>
        <CodeBlock>
          {`
alert("あなたは " + navigator.appName + " を使用しています。");
        `}
        </CodeBlock>
        
        <p>
          すると、chrome上に「あなたはNetscapeを使用しています。」という警告ダイアログが表示されたと思います。このようにして、windowオブジェクトを使用することができるのです。
        </p>
        <div>
          
        </div>
      </LessonLayout>
    </div>
  )
}

export default withRouter(DOM_1Component)