import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DOM_5Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={16}
         title=' DOM_5 - カスタムデータ属性 - ' />
        <p>
          今回は、DOM操作における属性値(attribute)についてご説明していきます。
        </p>
        <br />
        <p>
          属性値は、classやsrc、id、altなど要素に付属してついているものです。
        </p>
        <br />
        <p>
          以下の例のように、属性＝属性値となっています。
        </p>
        <CodeBlock>
          {`
<input type=”text” id=”first” value=”blue”>
        `}
        </CodeBlock>
        <p>
          ・input：要素
        </p>
        <p>
          ・type, id, value：属性
        </p>
        <p>
          ・text, first, blue：属性値
        </p>
        <br />
        <p>
          それでは、実際に画像を用いて例を見てみましょう。
        </p>
        <br />
        <p>
          ・index.html
        </p>
        <CodeBlock>
          {`
<body>
<img src="https://picsum.photos/600/600?random=1" class='nice cool' alt="">
        `}
        </CodeBlock>
        <p>
          ・the-dom.js
        </p>
        <CodeBlock>
          {`
const pic = document.querySelector('.nice');
pic.classList.add('open');
pic.classList.remove('cool');
pic.classList.toggle('round');
 
console.log(pic.classList);
 
pic.alt='Cute pup';
pic.width= 200;
 
console.log(pic.alt);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh5.googleusercontent.com/muUyF7JTjCYfKgdhXHXoF003VzYQ5awOcphOPhgHb0PXBUNJ-izpm89ziI2JH8pJC_7lMUDRkcT0yjU4K9G2IoPAXcaR097ziQU0c-Hs7W2C7u4W-sk4rPxec8VtywPynS3a3AOX'
        width='502'
        height='395'
        alt='pic.alt' />
        </p>
        <p>
          img要素にalt属性とwidth属性を追加することができました。
        </p>
        <br />
        <p>
          pic.alt=’Cute pup’をセッターといいます。
        </p>
        <br />
        <p>
          また、console.log(pic.alt);をゲッターといいます。
        </p>
        <br />
        <p>
          ちなみに、画像のalt属性を設定する場合は、すでに画像であることがわかっており、「〜の画像」などと命名する必要はありませんので、ご注意ください。
        </p>
        <br />
        <p>
          ここでは、ゲッターとしてのみ働くHTMLImageElement.naturalWidthを紹介します。
        </p>
        <br />
        <p>
          HTMLImageElement.naturalWidthは、img要素で実際に表示されている横幅ではなく、画像の本来の横幅を返します。このプロパティは読み取り専用です。
        </p>
        <CodeBlock>
          {`
console.log(pic.alt);
console.log(pic.naturalWidth);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh5.googleusercontent.com/SJ4droH5twC2soZe2YcCNgvKMyzsfeVFvfnK5ZC7wsCRniAQTktSv7MQE9mRJvH6edB2gFqeDuW8j7HN28skURAgUyTfW936A32qS_BJRWrM97DWjgKM1Lzjr_Uet2UjngEzVNyq'
        width='544'
        height='198' 
        alt='naturalWidth' />
        </p>
        <br />
        <p>
          実行結果から、console.log(pic.naturalWidth);は0が返ってきました。
        </p>
        <br />
        <p>
          しかし、ChromeのConsoleでpic.naturalWidthを入力したときは600が返ってきました。
        </p>
        <br />
        <p>
          同じプロパティを実行したのに違う値が返されたのはなぜでしょうか？
        </p>
        <br />
        <p>
          なぜなら、console.log(pic.naturalWidth);を実行したときは、画像がまだダウンロードされていないため0が返ってきたのです。
        </p>
        <br />
        <p>
          このせいで、スライドショーなどを実装するときに問題となることが多々あります。
        </p>
        <br />
        <p>
          これを防ぐためには、addEventListenerを使う必要があります。
        </p>
        <br />
        <CodeBlock>
          {`
console.log(pic.naturalWidth);
 
window.addEventListener('load', function() {
 console.log(pic.naturalWidth);
});
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/Q8eCwRm32RMdajC4cCjY3V5w_Od21ZXY3VRS94D507pA2xFV6eU2v2D7acP2HREkQzhYmbg4ZfwU-YbpsRxRn0SgTRxFymkhMYp6BuUAl9lNHa1rdlfSZ6gKDhw8KrLNPJnKhfvr'
        width='354'
        height='150'
        alt='window.addEventListener' />
        </p>
        <br />
        <p>
          このように、addEventListenerでwindowがloadされた後に、ゲッターを実行することによって画像の横幅を取得することができます。
        </p>
        <br />
        <p>
          また、windowではなくpicを指定しても同様の結果が得られます。
        </p>
        <CodeBlock>
          {`
console.log(pic.naturalWidth);
 
pic.addEventListener('load', function() {
 console.log(pic.naturalWidth);
});
        `}
        </CodeBlock>
        <p>
          それでは、HTMLImageElement.naturalWidthプロパティはゲッターですが、セッターとしてしてみるとどうなるでしょうか？
        </p>
        <CodeBlock>
          {`
pic.naturalWidth = 200;
        `}
        </CodeBlock>
        <p>
          <img src='https://lh4.googleusercontent.com/LDQakqA4BbElmHurOfRYODT2lmltUiTkujjsH4E3rI04fgTf--a7NDgTd96kIMTK0M0G3vaNUAMeHtbBteGp0JuOfGRkI_lEEiHqrAZT7-QNsKIdH56Z8IcXTzeL0NPmq-f1SBId'
        width='680'
        height='124'
        alt='ゲッター' />
        </p>
        <br />
        <p>
          ChromeのConsole上では、エラーも出ませんがwidth属性が追加されてもいませんね。
        </p>
        <br />
        <p>
          よって、width属性を正しく追加するには、element.widthで画像のWidthを変更することができます。
        </p>
        <CodeBlock>
          {`
pic.width = 200;
 
console.log(pic.naturalWidth);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/Fy7MowsQdfvdwOSfdg_L01jdBnOHVdPUKRz7kIO_KJarGVil143mWXQbGM13cybsNvxtMYbATox86F5a_90YZJtItUSQ-41d3PyFFW5ueahZxgH57eelDZ4ckJsYv8fITzKtxetH'
        width='680'
        height='85' 
        alt='element.width' />
        </p>
        <br />
        <h2 dir='ltr'>
          setAttribute, getAttribute
        </h2>
        <br />
        <p>
          また、以下のようにsetAttribute, getAttributeを用いることで、属性値の取得、変更を行うこともできます。
        </p>
        <CodeBlock>
          {`
console.log(pic.getAttribute('alt'));
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/6pgUPtMLbxCDGH4oyjXS5L4RhV36tIAb3SwRTmCf_wQDhSQk9SbumxZicgu_9gwLtfxL8NEmb1tc9PmyuBRlFnEWl1CvYMFtinclt81NKwkdAth5bHRtCmTaABAv8Q8oAOPNhd2E'
        width='208'
        height='62' 
        alt='getAttribute' />
        </p>
        <br />
        <p>
          alt属性を取得することができました。
        </p>
        <CodeBlock>
          {`
pic.setAttribute('alt', 'REALLY CUTE PUP');
console.log(pic.getAttribute('alt'));
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/n2ZwYqy1vPoTG1DCnl0YBvSA1pm-8TpfxuCbvWGAmvMk_gba2c6d80SGmH6RCez9felt34SaLG3MIs9awKZI_NWjR0FcZ3hYb9jirBB0Ko4i1733k9bHJ6Y5yE8df9oVoYsXQbti'
        width='288'
        height='52'
        alt='setAttribute' />
        </p>
        <br />
        <p>
          pic.setAttribute()によって、alt属性の属性値をREALLY CUTE
          PUPに設定することができ、getAttribute(‘alt’)でalt属性の属性値を取得することができました。
        </p>
        <br />
        <p>
          また、element.setAttributeとHTMLImageElement.altプロパティの違いについてですが、一般的な属性ならHTMLImageElement.altなどで属性を変更することができます。
        </p>
        <br />
        <p>
          ただし、一般的でない属性を変更する場合はelement.setAttributeを使うことになります。
        </p>
        <br />
        <p>
          ちなみに、setAttribute()は以下の例のように、もし存在しなければ新しく属性を作ることが可能ですので、自分がある属性値を変更したと思っていても、「実は別の属性が作成されただけだった」ということがあるので注意しましょう。
        </p>
        <CodeBlock>
          {`
pic.setAttribute('alttt', 'REALLY CUTE PUP');
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/fObNlFGN-HQ7vlHxmqHbeZCYb0VNEs-uAQtXP2v7yST_LyUvJ1iCR95Yfo3WtfV5Fpi1Ebn66eJpW5W6FZdELW_9bckEyIjWi_dx9LcKpULbFdr0lc3MQjnEiOoOY--y6G18gt9p'
        width='680'
        height='63'
        alt='setAttribute　新しい属性' />
        </p>
        <br />
        <p>
          また、getAttribute()は存在しない属性であった場合、nullを返します。そして、属性を削除する時はremoveAttribute()を使用するようにしましょう。
        </p>
        <br />
        <h2 dir='ltr'>
          カスタムデータ属性
        </h2>
        <br />
        <p>
          もし、属性を独自に追加したい場合は、カスタムデータ属性が便利です。カスタムデータ属性は、html要素に対して任意の属性を付与することができます。
        </p>
        <br />
        <p>
          カスタムデータ属性を使うことによって、以下のように同じimg要素の一覧があれば、そこにidや種類などの情報を付与して差別化することができるので、動的な操作が行いやすくなります。
        </p>
        <br />
        <p>
          ・index.html
        </p>
        <CodeBlock>
          {`
<img data-name=”first” class=”custom” src=”https://picsum.photos/200” />
<img data-name=”second” src=”https://picsum.photos/200” />
<img data-name=”third” src=”https://picsum.photos/200” />
        `}
        </CodeBlock>
        <p>
          ・the-dom.js
        </p>
        <CodeBlock>
          {`
const custom = document.querySelector('.custom');
console.log(custom.dataset);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh5.googleusercontent.com/R2_IdqW7N_u1KZ_i_hyxY2ZNGQa74UtCr9-fxhOSc9GpduiWmQ1QgoESKsMiGNs8vtU3WfVhbSX3BSAzM6KccuK__oIiFv7r0UJA7eOMM4CSi3XDj6vU0mMa5Fl4wG6mVdjuLnsl'
        width='680'
        height='149'
        alt='dataset' />
        </p>
        <br />
        <p>
          customのクラス属性をもつ要素を取得し、Element.datasetでデータ属性を取得できました。
        </p>
        <br />
        <p>
          このように、「data-任意の名前」という形で任意の属性を付与することができます。名前は大文字が使えない事にも注意してください。
        </p>
        <br />
        <p>
          また、以下のようにデータ属性をhtmlから追加することもできます。
        </p>
        <CodeBlock>
          {`
<img class=”custom” data-name=”first” data-last=”last” src=”https://picsum.photos/200” />
        `}
        </CodeBlock>
        <p>
          <img src='https://lh6.googleusercontent.com/mjdcEtGM2B_vn2Sfv2exnVxJBl2vw9OsfEUyr4TgNIyI-uw_tOH5pK7368VhWAlnlIbCUMg_NCoO1CIOlqd2csTbbL0LBkdFai6F1-Ee1KqCLA8M5aULAHd9I-_CgIysaL31YLMm'
        width='680'
        height='140'
        alt='データ属性' />
        </p>
        <br />
        <p>
          実際に、カスタムデータ属性の使用例を示します。
        </p>
        <CodeBlock>
          {`
custom.addEventListener('click', function() {
  alert(\`Welcome \${custom.dataset.name} \${custom.dataset.last}\`);
  }); 
        `}
        </CodeBlock>
        <p>
          <img src='https://lh4.googleusercontent.com/15MalP7NBxcPyLVd8OXg_CqH2iZeIbLt6TMJRDljBAH9QNYRSz1slgoCdywy4ClBXjAFAgOwDYKnlPbTEOjimn_x3tIJUT7OEywoISp-SPGPaKGxIHmyk1aW7YRGTSMDteOhwwyD'
        width='680'
        height='217' 
        alt='カスタムデータ属性の使用例' />
        </p>
        <p>
          このように、カスタムデータ属性を使用する事で、JavaScriptの操作をより動的に行えるようになります。
        </p>
        <br />
        <p>
          以上、今回も最後までお読み頂きありがとうございました。
        </p>
      </LessonLayout>
    </div>
  )
}


export default withRouter(DOM_5Component)