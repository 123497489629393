import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DOM_7Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={17}
          title=' DOM_7  
          「文字列からHTMLの生成」と「クロスサイトスクリプティング」 ' />
        <p>
          それでは、今回は文字列からHTMLを生成する方法を見ていきましょう。
        </p>
        <p>
          まずは、サンプルコードを準備します。
        </p>
        <br />
        <p>
          ・creating-with-string.js
        </p>
        <CodeBlock>
          {`
const item = document.querySelector('.item');
 
item.innerHTML = \`<h1>Hey How are ya?</h1>\`;
console.log(item.innerHTML);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh5.googleusercontent.com/e3WIsFY5nPSJ2GO2epYYz6UAAiewoac3uparq5s5NZhvaO8Pq2gZKDVb-mPc6fje2fxlxrSZFnmYgWnnOzE99mlW3zmt4HccsnT3gwElnhed12l5nP2VEEItURvH9vm2JpD-SJu6'
        width='350'
        height='247'
        alt='creating-with-string.js' />
        </p>
        <p>
          innerHTMLメソッドで、バッククオート（｀）を用いることで、createElementメソッドを用いる場合と比べて、簡単にString型の要素を追加することができました。
        </p>
        <br />
        <p>
          innerHTML プロパティは、要素内のHTMLを取得したり設定したりします。
        </p>
        <br />
        <p>
          ちなみに、バッククオートを須要した文字列リテラルをテンプレートリテラルといいます。
        </p>
        <br />
        <p>
          テンプレートリテラルを使用することで、エスケープシーケンスなしで改行できますし、${'{'}...{'}'}を使うことでJavascriptを組み込むことができる、などのメリットがあります。
        </p>
        <br />
        <p>
          では次に、テンプレートリテラルを用いてイメージ要素を生成してみましょう。
        </p>
        <CodeBlock>
          {`
const width = 300;
const src = \`https://picsum.photos/\${width}\`;
const desc = \`Cute Pup\`;
const myHTML = \`
<div class=”wrapper”>
 <h2>Cute \${desc}</h2>
 <img src=\${src} alt=\${desc}/>
</div>
\`;
 
console.log(typeof myHTML);
 
item.innerHTML = myHTML;
console.log(item.innerHTML);
        `}
        </CodeBlock>
        <br />
        <p>
          <img src='https://lh4.googleusercontent.com/-d8i77evARAf1ik8lIJ4wOp8uEJ2WqUiIvjLvy-Kx-_EDy9yzo8Qg6bDSY5lIc78Qa7kbTWqskTMIyCjc25qdI54t7XgbblMJ39-Sc2Cn1guQaEjxShOD-DoMAm_kJ46bUgX7Ufc'
        width='488'
        height='592'
        alt='cute cute pup' />
        </p>
        <br />
        <p>
          ここで、myHTMLにtypeofを使用してみると、String型の文字列であることが確認できます。
        </p>
        <br />
        <p>
          typeof 演算子は、未評価のオペランドの型を示す文字列を返します。
        </p>
        <br />
        <p>
          また、myHTMLは要素ではなく文字列であるので、以下のようにクラス属性を追加しようとするとエラーになります。
        </p>
        <CodeBlock>
          {`
myHTML.classList.add(‘special’);

→×エラー
        `}
        </CodeBlock>
        <p>
          最終的に、innerHTMLメソッドを用いた時に文字列から要素となってDOMに追加されるのです。
        </p>
        <br />
        <p>
          では、文字列から要素になりましたので、classListメソッドを用いてクラス属性を追加してみましょう。
        </p>
        <CodeBlock>
          {`
const itemImage = document.querySelector('.wrapper img');
 
itemImage.classList.add('round');
 
console.log(itemImage);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh6.googleusercontent.com/7AtE14QAYp9fGZjBj22ycRwEG1aumhJZsha8VnNRJ1YypyaFyGPGN8QXYari6TJmDMbI4ak7Ud0WyvlxedOLyRzWN52bvMF7POSTjzeKkArAUpvi_R1o4QFKr67ZmKh_5nCj7yfu'
        width='605'
        height='588'
        alt='class round' />
        </p>
        <br />
        <p>
          wrapperクラスのimgタグのクラス属性にroundを追加することができました。
        </p>
        <br />
        <p>
          では、文字列（String）からDOM要素にする別の方法を見ていきましょう。
        </p>
        <h2>
          createRange().createContextualFragment(HTML)
        </h2>
        <br />
        <p>
          今回は、createRange().createContextualFragment(HTML)メソッドを用います。
        </p>
        <br />
        <p>
          createRange().createContextualFragment(HTML)を使うと、ネストした断片ドキュメントが取得できます。断片ドキュメントとは、もともとあるDOMに影響を与えない新しい構造のDOMのことです。
        </p>
        <br />
        <p>
          例えば、createElement()ですと、不要な要素（div, p,
          spanなど）が作られてしまうのですが、createRange()を使用することで、innerHTMLよりも軽くすることが可能になります。
        </p>
        <br />
        <p>
          では、実際に例を示します。
        </p>
        <CodeBlock>
          {`
const myHTML = \`
<div class='wrapper'>
 <h2>Cute \${desc}</h2>
 <img src=\${src} alt=\${desc}/>
</div>
\`;
 
const myFragment = document.createRange().createContextualFragment(myHTML);
 
console.log(myFragment.querySelector('img'));
console.log(myFragment);
        `}
        </CodeBlock>
        
        <br />
        <p>
          <img src='https://lh6.googleusercontent.com/lpW6C7hb0oXO7kSAf1p4XPOim4ZzmcMR_zuLJBM2p3REqv90q-n2MUYp1bSxRfvnOblevclkxUekVu8sKCxX_4L5vHCpaSqRu-nKTo3TlQdRdPZoaFnEeDWQCXqZkJctqXxSQucf'
        width='488'
        height='96'
        alt='document-fragment' />
        </p>
        <br />
        <p>
          createRange()を実行すると、Rangeという要素やHTMLのコレクションのようなものが生成され、そのままcreateContextualFragmentに文字列を渡しました。その実行結果が#document-fragmentであり、myHTMLの要素をすべて取得することができたのです。
        </p>
        <br />
        <p>
          得られたDocumentFragmentをappendChild()で、DOMに追加してみましょう。
        </p>
        <CodeBlock>
          {`
document.body.appendChild(myFragment);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/9xe4nNoE9t0w418lb-U2Kai69vuq2sOk0WdenUGp16q2DgJ9lMVtewTjORPHJkqomYSI2Cl-2KEFDkOoqW8tJWwDRN6qFdrPVVgm_86NtQp86eo2G1HxorjhFwv40y43HBxh34eT'
        width='374'
        height='401'
        alt='div.wrapper' />
        </p>
        <br />
        <p>
          ブラウザで要素が反映されたことが確認できました。
        </p>
        <br />
        <p>
          最後に、テンプレートリテラルからHTMLを生成する際の、セキュリティの落とし穴について説明します。
        </p>
        <br />
        <h2>
          クロスサイトスクリプティング（XSS）
        </h2>
        <br />
        <p>
          クロスサイトスクリプティング (cross site scripting) とは、 Web アプリケーションの脆弱性のひとつで、HTML
          を動的に生成する際に&lt;script&gt;～&lt;/script&gt;
          などで悪意のあるスクリプトを書き込まれると、悪意のあるデータをそのまま HTML
          として表示してしまい、秘密情報が盗まれたり、不正な振り込みが実施されたりなどのセキュリティ問題が発生することです。
        </p>
        <br />
        <p>
          例を示します。
        </p>
        <CodeBlock>
          {`
const width = 300;
const src = \`https://picsum.photos/\${width}\`;
const desc = \`Cute Pup <h1>LOVE THIS GUY</h1><style>* {display: none; }</style>\`;
const myHTML = \`
<div class=”wrapper”>
 <h2>Cute \${desc}</h2>
 <img src=\${src} alt=\${desc}/>
</div>
\`;
 
console.log(typeof myHTML);
 
item.innerHTML = myHTML;
console.log(item.innerHTML);
        `}
        </CodeBlock>
        <br />
        <p>
          <img src='https://lh3.googleusercontent.com/TH2Q3d3bELZRbMnj211iFYrh77Vd7iN2UawdYjvrb8WufjH9DZNC8-J-exVK5QMtPUGhycSFDdnW-W-m45-HHV5VNS2E4zYZRm54upw5c6epxzdqN46QA4G0qmihu5xaqUVCsQBj'
        width='680'
        height='263'
        alt='xxs' />
        </p>
        <br />
        <p>
          MyspaceなどのSNSを使った場合、変数descにスクリプトを追加して反映させることができてしまいます。
        </p>
        <br />
        <p>
          今回の例の場合、display: noneですので、画面が真っ白になりました。
        </p>
        <p>
          これがXSSと呼ばれるものです。
        </p>
        <CodeBlock>
          {`
const desc = \`Cute Pup <img onload="alert('Hacked')" src='https://picsum.photos/50' alt='' />\`;
        `}
        </CodeBlock>
        <p>
          <img src='https://lh6.googleusercontent.com/UflprcqazLr57Sj_DY283dz5MuzF9U1sjXT8rIqhZsult94-JNc4dAZ5c_d8H7ST-6bk5h1o0QX7jI15xAxqezngwN700oo40mz2y6ynSCN9rL_CCcJ0sa9PLPvfjbxlYTz5L0d_'
        width='680'
        height='93'
        alt='hacked' />
        </p>
        <br />
        <p>
          また、上記のようにイメージを追加し、そこにonloadイベントを追加するとユーザからデータを取得し、onloadが実行されて思いのままのスクリプトを実行することができるのです。この、ブラウザの画面上部にHackedと表示されます。
        </p>
        <br />
        <p>
          onloadイベントは、ページや画像などのHTMLが読み込みが完了した時点でイベントを実行する機能です。利用ケースとして、スライダーや画像ギャラリーのような画像を読み込んでから処理を実行したいときなどがあります。
        </p>
        <br />
        <p>
          このようにDOMを生成したり、操作するときはセキュリティ対策を行う必要があるのです。
        </p>
        <br />
        <p>
          では、次回はXSSのセキュリティの落とし穴を解決する手法についてご紹介していきます。
        </p>
        <div>
          <br />
        </div>
      </LessonLayout>
    </div>
  )
}


export default withRouter(DOM_7Component)