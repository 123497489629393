import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const HoistingComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={10}
         title=' JavaScriptの巻き上げ（Hoisting） ' />
        <h2>
          JavaScriptの巻き上げ（Hoisting）について
        </h2>
        
        <p>
          JavaScriptでは、関数や変数を宣言する前に呼び出すことができます。つまり、スコープ内の変数や関数は、宣言された場所に関係なくスコープの先頭で宣言されたことになるのです。これをJavaScriptの巻き上げ（hoisting）と呼びます。
        </p>
        
        <p>
          JavaScriptの巻き上げは、重要な概念なので、しっかりと理解しておく必要があります。なぜなら、巻き上げが原因となってエラーが発生したとしても、この概念を知らないと解決することができないからです。
        </p>
        
        <p>
          では、さっそく例を使って説明しますので、以下のファイルをご準備ください。
        </p>
        
        <p>
          ・hoisting.html
        </p>
        
       
       
        
        
        <CodeBlock>
          {`
<!DOCTYPE html>
<html lang=”en”>

  <head>
    <meta charset=”UTF-8”>
    <meta name=”viewport” content=”width=device-width,initial-scale=1.0”>
    <title></title>
    <link rel=”stylesheet” href=”../../base.css”>
  </head>

  <body>
    <script src=”./hoisting.js></script> 
  </body>
</html>
        `}
        </CodeBlock>
        
        <h2>
          関数の巻き上げについて
        </h2>
        <p>
          htmlファイルが準備できましたら、まずは関数の巻き上げについて見ていきましょう。
        </p>
        
        <p>
          では、問題です。以下のように関数を宣言する前に関数を呼び出すということは可能なのでしょうか？
        </p>
        
        <p>
          ・function.js
        </p>
        
        <CodeBlock>
          {`
sayHi();

function sayHi() {
console.log(‘hey!);
}
        `}
        </CodeBlock>
        
        <p>
          このように、sayHi関数を定義する前に関数を呼び出していますが、問題なく実行されています。なぜなら、JavaScript実行時にJavaScriptのコンパイラが以下のように、関数宣言をスコープの先頭に移動させるからです。これが、関数の巻き上げです。
        </p>
        
         
        <CodeBlock>
          {`
function sayHi() {
  console.log(‘hey!);
  }
  
  sayHi();  
        `}
        </CodeBlock>
        
        <p>
          では、別の例を示します。
        </p>
        
        <CodeBlock>
          {`
sayHi();

function sayHi() {
console.log(‘hey!);
console.log(add(10, 5));
}

function add(a, b) {
return a + b;
}
        `}
        </CodeBlock>
        <p>
          実行結果（Console）
        </p>
        <p>
          hey!
        </p>
        <p>
          15
        </p>
        
        <p>
          このように、add関数が宣言される前に、sayHi関数内でadd関数が呼び出されていますが、問題なく実行されています。この場合も、add関数が実行時にスコープの先頭に巻き上げられているのです。
        </p>
        
        <p>
          巻き上げ機能の使い方として、まず関数を呼び出してから呼び出される関数を定義しようとする人もいますが、基本的には関数を宣言してからその関数を使うというのが一般的です。
        </p>
        
        <p>
          ちなみに、関数の巻き上げは関数式やアロー関数では機能しない、ということも知っておいてくださいね。
        </p>
        <h2>
          変数の巻き上げについて
        </h2>
        
        <p>
          では、変数の巻き上げについてみていきましょう。
        </p>
        <p>
          例を示します。
        </p>
        
        <CodeBlock>
          {`
console.log(age);
var age = 10;
        `}
        </CodeBlock>
        
        <p>
          ここで、もしageが定義されていなかった場合、ReferenceErrorが出るはずですが、実際にはundefinedとなっています。なぜなら、ここで変数の巻き上げが起こっており、以下のように変数宣言だけが巻き上げられ、値は巻き上げられないという状態となるからです。
        </p>
        
        <CodeBlock>
          {`
var age;
console.log(age);
age = 10;
        `}
        </CodeBlock>
        
        <p>
          このように、varを用いることで巻き上げが起こり、予想外のバグが発生してしまうことがあるので、基本的には変数宣言をスコープの先頭で行うようにしましょう。
        </p>
        
        <p>
          また、varをそもそも使わないようにした方が良いのですが、このようなvarの問題を解決するために、constとletが2019年にECMAScriptの新機能としてが追加されたのです。
        </p>
        
        <p>
          ちなみに、letやconstでも変数の巻き上げは起こりますが、undefinedではなくReferenceError（参照エラー）を投げかけられる事になります。どちらにせよ、変数宣言はスコープの先頭でおこなうようにしておくのが無難です。
        </p>
        
        <p>
          以上、JavaScriptにおける関数と変数の巻き上げについてみてきましたが、いかがだったでしょうか。
        </p>
        <p>
          では、今回も最後までお読みいただきありがとうございました。
        </p>
        <div>
          
        </div>
      </LessonLayout>
    </div>
  )
}

export default withRouter(HoistingComponent)