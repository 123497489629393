import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DOM_2Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={13}
         title=' DOM_2 - DOM要素の取得方法 - ' />
        <p>
          それでは、今回はDOMを操作して要素を取得する方法を見ていきましょう。
        </p>
        
        <p>
          まずは、index.htmlとthe-dom.jsを用意します。
        </p>
        
        <p>
          ・index.html
        </p>
        <CodeBlock>
          {`
<!DOCTYPE html>
<html lang=”en”>

<head>
<meta charset=”UTF-8”>
<meta name=”viewport” content=”width=device-width,initial-scale=1.0”>
<title></title>
<link rel=”stylesheet” href=”../../base.css”>
</head>

<body>
  <div class=”items”>
    <p>Hi I’m an item</p>
    <div>
      <h2 id=”tel”>Sub Div</h2>
      <p>Hi I’m an item</p>
    </div>

    <div class=”item”>
      <img src=”https://picsim.photos/200” />
      <p>Hi I’m an item</p>
      <p>Hi I’m an item</p>
    </div>
  </div>

  <scriptsrc=”./the-dom.js”></script> 
</body>
</html>          
        `}
        </CodeBlock>
       
        
        <p>
          JavaScriptを読み込むためには、Documentの読み込みが完了していないとJavascriptがその要素にアクセスできないので、&lt;head&gt;内にscriptタグを書くのではなく&lt;/body&gt;タグの直前に書くようにしましょう。
        </p>
        
        <p>
          ・the-dom.js
        </p>
        <CodeBlock>
          {`
const p = document.querySelector(‘p’);
console.log(p);
`}
        </CodeBlock>
        
        <p>
          &lt;head&gt;タグ内に&lt;script&gt;タグを書いた場合は、nullが返ってきますが、&lt;/body&gt;タグ直前に&lt;script&gt;タグを書くことで、以下の実行結果が返ってきました。
        </p>
        
        <p>
          実行結果
        </p>
        <p>
          &lt;p&gt;Hi I’m an item&lt;/p&gt;
        </p>
        
        <h2>querySelector()とquerySelectorAll</h2>
        
        <p>
          querySelectorは指定されたセレクターまたはセレクターのグループに一致する、文書内の最初の Element を返します。
        </p>
        
        <p>
          querySelectorAllは、与えられた CSS セレクターに一致する文書中の要素のリストを示す静的な NodeList を返します。
        </p>
        
        <p className='font-bold'>
          ちなみに、NodeListとJavascriptの配列は別物です。NodeListを配列として扱ってしまうと、期待する動作が得られず、デバッグに悩まされることになるので注意してください。
        </p>
        
        <p>
          ただし、NodeListでもforEach() メソッドやArray.from() を使うことができます。
        </p>
        
        <p>
          NodeList.forEach()メソッドは、指定された関数を NodeList の各要素に対して実行し、その要素を関数の引数として渡します。
        </p>
        
        <p>
          Array.from() メソッドは、配列のようなオブジェクトや反復可能オブジェクトから、新しい Array インスタンスを生成します。
        </p>
        
        <p>
          では、querySelectorAll()を使ってみましょう。
        </p>
        
        <CodeBlock>
          {`
const divs = document.querySelectorAll(‘div’);
console.log(divs);
        `}
        </CodeBlock>
        
        <p>
          実行結果
        </p>
        <p>
          ⇒
        </p>
        <p>
          NodeList(3) [div.items, div.item, div.item]
        </p>
        
        <p>
          divの要素が含まれるNodeListが得られ、クリックするとブラウザ上でdiv要素が選択された状態を確認することができます。
        </p>
        
        <p>
          次に、itemクラスのノードリスト内にあるimgを取得してみましょう。
        </p>
        
        <CodeBlock>
          {`
const imgs = document.querySelectorAll(‘.item img’);
console.log(imgs);
`}
        </CodeBlock>
        
        <p>
          実行結果
        </p>
        <p>
          ⇒NodeList [img]
        </p>
        <p>
          o: img
        </p>
        <p>
          length: 1
        </p>
        <p>
          _proto_: NodeList
        </p>
        
        <p>
          itemクラス内のイメージNodeListを抽出することができました。
        </p>
        
        <p>
          また、以下のようにしても同じようにimg要素を取得することができます。
        </p>
        
        <CodeBlock>
          {`
const itemImage = item.querySelector(‘img’);
          `}
        </CodeBlock>
        
        <p>
          itemスコープ内のimgタグにアクセスすることができました。
        </p>
        
        <p>
          他の例を示しますので、DevtoolsのConsole上で以下のコードを入力してみましょう。
        </p>
        
        <h3>
          Document.getElementById()
        </h3>
        
        <p>
          Document の getElementById() メソッドは、 要素の ID が指定されていれば固有であることが求められているため、特定の要素にすばやくアクセスするには便利な方法です。
        </p>
        
        <CodeBlock>
          {`
> document.getElementById(‘tel’)
        `}
        </CodeBlock>
        
        <p>
          実行結果
        </p>
        <p>
          &lt;h2 id=”tel”&gt;Sub Div&lt;/h2&gt;
        </p>
        
        <p>
          document.getElementByIdを用いることで、Idがtelの要素を抽出することができました。
        </p>
        
        <p>
          以上、今回は要素の取得方法を確認してきました。
        </p>
        
        <p>
          最後までお読み頂きありがとうございました。
        </p>
        <div>
          
        </div>
      </LessonLayout>
    </div>
  )
}

export default withRouter(DOM_2Component)