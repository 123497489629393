import * as React from 'react'

const LessonTitle = ({ lessonNumber, title }) => {
  return (<h1 className='text-4xl sm:text-5xl pt-4 sm:pt-0'>
    <span className='text-monokai-green'>Lesson {lessonNumber}:</span>
    <span className='text-monokai-pink leading-snug'>{title}</span>
  </h1>)
}

export default LessonTitle
