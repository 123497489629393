import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const ClosuresComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={11}
          title=' JavaScriptのクロージャ（Closures）' />
        <p>
          今回は、JavaScriptのクロージャ（Closures）についてご紹介します。
        </p>
        <p>
          少し難しい概念ですが、分かりやすくご説明いたします。
        </p>
        <h2>
          クロージャ（Closures）とは？
        </h2>
        
        <p>
          クロージャとは、内側の関数から外側の関数スコープへのアクセスを可能にするもので、自分を囲むスコープにある変数を参照することができます。クロージャは、JavaScriptが静的スコープであるという特性のために利用できるのです。よって、関数が終了した後もそのローカル変数を参照できるようになります。
        </p>
        
        <p>
          ちなみに、静的スコープ（レキシカルスコープ）とは、変数がどこで実行されたかではなく、変数がどこで宣言されたかに依存するというスコープの性質のことです。
        </p>
        
        <h3>
          クロージャのメリット
        </h3>
        <p>
          ・グローバル変数を減らすことができる
        </p>
        <p>
          ・計算量を減らすことができる。
        </p>
        
        <p>
          グローバル変数を多く定義すると、その変数は書き換え可能なのでエラーの原因になりがちです。しかし、クロージャを使うことで外部から変数が書き換えられないようにできます。また、関数終了後にもローカル変数を参照できるので、記述を増やさずに処理を行うことができます。
        </p>
        
        <p>
          では、さっそく例を使って説明しますので、以下のファイルをご準備ください。
        </p>
        
        <p>
          ・closures.html
        </p>
        <CodeBlock>
          {`
<!DOCTYPE html>
<html lang=”en”>

  <head>
    <meta charset=”UTF-8”>
    <meta name=”viewport” content=”width=device-width,initial-scale=1.0”>
    <title></title>
    <link rel=”stylesheet” href=”../../base.css”>
  </head>

  <body>
    <script>
      function outer() {
        const outerVar = ‘Hey I am the outer var!’;
        
        function inner() {
          const innerVar = ‘Hey I am an inner var!’;
          console.log(innerVar);
          console.log(outerVar);
        }
        inner();
      }
      outer();
    </script> 
  </body>
</html>
        `}
        </CodeBlock>
        
        <p>
          Consoleにて
        </p>
        <p>
          &gt; outer()
        </p>
        <p>
          実行結果
        </p>
        <p>
          Hey I am an inner var!
        </p>
        <p>
          Hey I am the outer var!
        </p>
        
        <p>
          outer関数の中にinner関数を宣言し、それぞれのスコープ内で関数を呼び出しています。また、inner関数では親スコープの変数であるouterVarを呼び出すことが可能ですが、親スコープであるouter関数は子供のスコープであるinnerVar変数を呼び出すことができません。では、次の例を見てみましょう。
        </p>
        
        <CodeBlock>
          {`
function outer() {
  const outerVar = ‘Hey I am the outer var!’;
  function inner() {
    const innerVar = ‘Hey I am an inner var!’;
    console.log(innerVar);
    console.log(outerVar);
  }
  return inner;
}

const innerFn = outer();
innerFn();
          `}
        </CodeBlock>
        
        <p>
          この場合、outer関数の返り値としてinner関数の値が与えられ、変数innerFnの中身はouter関数となっています。そこで、スコープ外からinnerFn関数を実行した場合、inner関数とinnerVar変数にアクセスすることができるでしょうか？
        </p>
        
        <p>
          実行結果
        </p>
        <p>
          Hey I am an inner var!
        </p>
        <p>
          Hey I am the outer var!
        </p>
        
        
        <p>
          結果的に、outer関数をinnerFn変数に代入する事で、inner関数にアクセスすることができました。これがクロージャというJavaScriptの機能です。基本的に、JavaScriptでは関数の処理が終わると、そのローカルスコープの変数はなくなってしまいます（ガーベージコレクション）。しかし、今回のようにinnerFn関数からouterVar変数にアクセスできるのです。
        </p>
        
        <p>
          では、別の例を見てみましょう。
        </p>
        
        <CodeBlock>
          {`
function createGreeting(greeting = ‘ ‘) {
  const myGreet = greeting.toUpperCase();
  return function (name) {
    return \`\${myGreet} \${name}\`;
  }
}
  
const sayHello = createGreeting(‘hello’);
const sayHey = createGreeting(‘hey’);
console.log(sayHello(‘tel’));
console.log(sayHello(‘min’));
console.log(sayHey(‘fus’));
          `}
        </CodeBlock>
        
        <p>
          実行結果
        </p>
        <p>
          HELLO tel
        </p>
        <p>
          HELLO min
        </p>
        <p>
          HEY fus
        </p>
        
        <p>
          アウタースコープとしてcreateGreeting関数の中に変数myGreetを定義し、そのインナースコープに無名関数を定義しました。
        </p>
        
        <p>
          こうすることで、createGreeting(‘hello’);と関数外で呼出されたとしても、createGreating関数内に作ったmyGreet変数にアクセスすることができるのです。
        </p>
        
        <p>
          これがレキシカルスコープの性質であり、実際にはcreateGreetingの引数にhelloとheyが別々に代入されたスコープが生成されることになります。これがJavaScriptのクロージャの大きな特徴です。
        </p>
        
        
        <p>
          このように、関数が定義された時点での環境を保存して置き、関数の実行時に再利用することができるようになっています。また、スコープとしては閉じていて変数myGreetの値を上書きすることはできませんが、スコープ外からで変数myGreetにアクセス可能であるので、クロージャと呼ばれています。
        </p>
        <h2>
          プライベート変数（private variables）について
        </h2>
        
        <CodeBlock>
          {`
function createGame(gameName) {
  let score = 0;
  return function win() {
    score++;
    return \`Your name \${gameName} score is \${score}\`;
  }
}
  
const hockeyGame = createGame(‘Hokey’);
const soccerGame = createGame(‘Soccer’);
          `}
        </CodeBlock>
        
        <CodeBlock language='bash'>{`
> hokkeyGame()
< “Your name Hockey score is 1”
> hokkeyGame()
< “Your name Hockey score is 2”
> soccerGame()
< “Your name Soccer score is 1”
> soccerGame()
< “Your name Soccer score is 2”
> soccerGame()
< “Your name Soccer score is 3”
> score
< Uncaught ReferenceError: score is not defined
        `}
        </CodeBlock>
        
        <p>
          ここでは、createGame関数に二つの異なる引数（hockey、soccer）を生成します。この時、関数createGameの引数gameNameと変数scoreは、クロージャのプライベート変数となります。また、ゲーム名（引数）が変わる毎にそれぞれ別のクロージャが作られ、それが共有されることはありません。
        </p>
        
        <p>
          つまり、クロージャは同じコードから異なるプライベート変数をもつ関数を作り出すことができるのです。また、score変数を呼び出そうとしてもアクセスすることはできません。
        </p>
        
        <p>
          このことから、JavaScriptでプライベート変数を作る方法としてクロージャが用いられているのです。
        </p>
        
        <p>
          以上、今回は、クロージャについて学んできましたがいかがだったでしょうか？
        </p>
        <p>
          少し難しく感じるかもしれませんが、しっかりと習得しましょう。
        </p>
        <p>
          では、今回も最後までお読みいただきありがとうございました。
        </p>
        <div>
          
        </div>
      </LessonLayout>
    </div>
  )
}

export default withRouter(ClosuresComponent)