import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { ResponsiveHoneycomb, Hexagon } from 'react-honeycomb'

const HomeComponent = ({ history }) => {

  const UseWindowSize = () => {
    const [ size, setSize ] = React.useState([ 0, 0 ])
    React.useLayoutEffect(() => {
      const updateSize = () => {
        setSize([ window.innerWidth, window.innerHeight, parseFloat(getComputedStyle(window.document.documentElement).fontSize) ])
      }
      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size
  }

  const [ , , fontSize ] = UseWindowSize()

  const LESSONS = [
    { id      : 1, name    : 'Javascriptの世界にようこそ!', 
      path    : paths.introduction, 
      class   : 'bg-hexf8 active:bg-hexf8 hover:bg-gray-400',
      popTitle: 'さあ、はじめよう!',
      popDesc : '興味のあるLessonへ！'
    },
    { id      : 2, name    : 'ブラウザ', path    : paths.browserTerminalSetup, class   : 'bg-marmalade active:bg-hexf8 hover:bg-gray-400',
      popTitle: 'ブラウザ', 
      popDesc : `- テキストエディター
      - JavaScriptとNode.js
      - CUI（Character User Interface）とNode.js`  },
    { id      : 3, name    : '実行環境', path    : paths.runningLoadingJs, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'JavaScriptの実行環境', 
    popDesc : `- webサイトのconsole
    - Node.jsとコマンドプロンプト
    - script tag
    ` },
    { id      : 4, name    : '変数', path    : paths.variablesStatements, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: '変数とステートメント', 
    popDesc : `- 変数の公式
    - 命名規則
    - 変数宣言（const, let, var）
    ` },
    { id      : 5, name    : 'Prettier', path    : paths.prettierEslint, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'PrettierとESLint', 
    popDesc : `- ESLint Demoをさわってみよう！
    - ESLintとPrettierを併用する理由
    - VSCodeにESLint, Prettierをインストールする
    `  },
    { id      : 6, name    : '型', path    : paths.types, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'データ型・オブジェクト型', 
    popDesc : `データ型とは？
    - String型（文字列）
    - Number型（数値）
    - nullとUndefined
    - Booleans
    - Object型（オブジェクト型）
    `   },
    { id      : 7, name    : '関数', path    : paths.functions, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: '関数', 
    popDesc : `- 関数とは？
    - 関数の書き方
    - 関数の引数とは？
    - 無名関数
    - Javascriptの巻き上げ
    - アロー関数
    `   },
    { id      : 8, name    : 'ディベロッパーツール', path    : paths.devTools, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'デバッグツール', 
    popDesc : `- Devtoolsのメリット
    - Devtoolsの機能
    - スタックトレースの使用方法
    - 要素の取得
    - コマンドラインAPI
    - ブレークポイント
    `   },
    { id      : 9, name    : 'スコープ', path    : paths.scope, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'スコープ', 
    popDesc : `- スコープの種類
    - グローバル変数
    - Windowオブジェクト
    - 関数スコープ
    - ブロックスコープ
    - 静的スコープ（レキシカルスコープ）
    `   },
    { id      : 10, name    : '巻き上げ', path    : paths.hoisting, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: '巻き上げ（Hoisting）', 
    popDesc : `- 関数の巻き上げ
    - 変数の巻き上げ
    `   },
    { id      : 11, name    : 'クロージャ', path    : paths.closures, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'クロージャ（Closures）', 
    popDesc : `- クロージャのメリット
    - プライベート変数
    `   },
    { id      : 12, name    : 'DOM 1', path    : paths.DOM_1, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'DOMについて', 
    popDesc : `- ブラウザの仕組みについて
    - windowオブジェクトを操作する
    `   },
    { id      : 13, name    : 'DOM 2', path    : paths.DOM_2, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: '要素の取得', 
    popDesc : `- querySelector()
    - querySelectorAll()
    - Document.getElementById()
    `   },
    { id      : 14, name    : 'DOM 3', path    : paths.DOM_3, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: '要素のプロパティとメソッド', 
    popDesc : `- Node.textContent
    - HTMLElement.innerText
    - innerHTMLプロパティ
    - insertAdjacentText()メソッド
    ` },
    { id      : 15, name    : 'DOM 4', path    : paths.DOM_4, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'DOMのクラス属性操作', 
    popDesc : `- classListについて
    - classList.add
    - classList.contains
    - classList.keys
    - classList.remove
    - element.className
    ` },
    { id      : 16, name    : 'DOM 5', path    : paths.DOM_5, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'カスタムデータ属性', 
    popDesc : `- ゲッター・セッター
    - setAttribute
    - getAttribute
    - カスタムデータ属性
    - Element.dataset
    ` },
    { id      : 17, name    : 'DOM 6', path    : paths.DOM_6, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'HTMLの生成', 
    popDesc : `- Document.createElement()
    - Node.appendChild()
    - Reflow
    - insertAdjacentElement()
    - Node.cloneNode()
    ` },
    { id      : 18, name    : 'DOM 7', path    : paths.DOM_7, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: '文字列によるHTMLの生成とクロスサイトスクリプティング', 
    popDesc : `- createRange().createContextualFragment(HTML)
    - クロスサイトスクリプティング（XSS）
    ` },
    { id      : 19, name    : 'DOM 8', path    : paths.DOM_8, class   : 'bg-hexf8 hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'DOMに関する練習問題', 
    popDesc : `- div要素を作る
    -  cardクラスを持つ新しいdivタグを作る
    -  delete関数を作る
    ` },
    { id      : 20, name    : 'イベントリスナー', path    : paths.Event_1, class   : 'bg-marmalade hover:bg-gray-400 active:bg-hexf8',
    popTitle: 'イベントリスナー', 
    popDesc : `- addEventListenerの書き方
    -  第２引数の関数に（）がいらない理由
    -  関数を事前に定義しておくことのメリット
    - 複数要素にaddEventListenerを行う
    ` },
  ]

  return (
    <div>
      <article className='h-full my-10 mx-4 sm:mx-10 relative z-0 xl:my-16'>
        <div className='text-center'>
          <h2 className='mb-24 mx-12 sm:mx-0'>
            Javascript - 入門編 - 
          </h2>
        </div>
        <div className='text-center my-16 bg-gray-500 mx-auto rounded px-10 py-6 text-white lg:mb-24 xl:max-w-5xl'>
          <p>私達はJavascriptを学ぶ方のためにハンズオン形式でレッスンを準備しました。</p>
          <p>質問がありましたら、下記のTwitterかFacebookから当アカウントまでメッセージを送ってください！</p>
          <p>それでは、順番にレッスンを学習していきましょう！</p>
        </div>
        {/* mobile size */}
        <div className='sm:hidden -ml-2 mr-1'>
          {LESSONS.map((item) => {
            return (
              <button type='button' className='border break-all w-full' onClick={() => history.push(item.path)}>
                <h4 className='text-lg font-semibold'>Lesson {item.id}</h4>
                <h4 className='font-bold text-darkgray text-xl border-b-4 border-marmalade w-full'>{item.popTitle}</h4>
                <p className='text-gray-800 break-words'>{item.popDesc}</p>
              </button>
            )
          })}
        </div>
        {/* over sm size */}
        <div className='hidden sm:block'>
          <ResponsiveHoneycomb defaultWidth={fontSize * 80}
          size={fontSize*7}
          items={LESSONS}
          renderItem={(item) => {
            const _class = `h-full pt-6 text-center cursor-pointer ${item.class}`
            return (
              <div className='' id='test'>
                <div id='fukidashi' className='fukidashi absolute z-10 h-auto w-auto'>    
                  <h4 className='font-bold text-darkgray text-xl border-b-4 border-marmalade w-max py-1'>{item.popTitle}</h4>
                  <p className=' whitespace-pre-line text-gray-800'>{item.popDesc}</p>
                </div>
                <Hexagon className={item.class}>
                  <div data-content='' type='button' className={_class}
                    onClick={() => history.push(item.path)}>
                    <h4 className='text-lg text-center pt-8 font-bold'>lesson {item.id}</h4>
                    <p className='mt-1'>{item.name}</p>
                  </div>
                </Hexagon>
              </div>
            )
          }} />  
        </div>
      </article>
    </div>
  )
}

export default withRouter(HomeComponent)
