import * as React from 'react'

const LessonLayout = ({ onGoback, children }) => {
  return (
    <>
      <div className='mx-2 sm:mx-10 sm:mb-10 sm:mt-5 border-gray-400 border-2 px-3 sm:px-10 sm:py-10 whitespace-pre-wrap'>
        { children }
      </div>
      <div className='px-2 py-4 sm:px-10 sm:py-0'>
        <button type='button'
        className='px-4 text-center'
        onClick={onGoback}>
          Go Back
        </button>
      </div>
      <div className='fixed bottom-10 right2 sm:bottom-10 sm:right-10'>
        <button type='button'
        className='px-4 text-center rounded-full text-2xl'
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
          ↑
        </button>
      </div>
    </>
  )
}

export default LessonLayout
