import * as React from 'react'
import Icon from '../img/icon.png'
import LogoImg from '../img/logo.png'

const Logo = ({ styles='rect' }) => {
  return (
    <img src={styles==='rect'?LogoImg:Icon} className='h-20 pl-2' alt='1f8 logo' />
  )
}

export default Logo
