import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const Event_1Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={20}
          title=' イベントリスナー' />
        <p>
          ブラウザは、クリック、スクロール、マウスを動かしたときなど、イベントを常に発生させています。
        </p>
        <br />
        <p>
          そして、JavaScriptはEventListenerを使うことでイベント処理をコントロールすることができます。
        </p>
        <br />
        <p>
          また、addEventListener()メソッドはすべての要素やdocumentにも付加することができます。
        </p>
        <br />
        <p>
          では、最も基本的なaddEventListener()メソッドである、ボタンをクリックされた時の処理から見ていきましょう。
        </p>
        <h2 dir='ltr'>
          addEventListenerの書き方について
        </h2>
        <p>
          addEventListenerの構文です。
        </p>
        <CodeBlock>
          {`
対象要素.addEventListener( イベントの種類, 関数, false )
        `}
        </CodeBlock>
        <p>
          addEventListenerは３つの引数を取ることができますが、一般的には２つの引数を取ることになります。
        </p>
        <br />
        <p>
          第１引数には、イベントの種類を文字列で渡します。イベントの種類としては、clickの他にもdblclick(ダブルクリック)、OnMouseOver（カーソルが乗る）、OnMouseUp（カーソルが離れる）などの種類があります。これらは、一般的に「イベントハンドラ」と呼ばれます。
        </p>
        <br />
        <p>
          第２引数の関数は、イベントが発生したときの処理を書きます。
        </p>
        <br />
        <p>
          第３引数は、イベントの伝達方式をtrue/falseで指定できますが、次回以降説明します。
        </p>
        <br />
        <p>
          それでは、今回は最も典型的なclickを例に説明を行っていきます。
        </p>
        <br />
        <p>
          ・index.html
        </p>
        <CodeBlock>
          {`
<!DOCTYPE html>
<html>
 
<head>
 <meta charset="utf-8" />
 <meta http-equiv="X-UA-Compatible" content="IE=edge">
 <title>Page Title</title>
 <meta name="viewport" content="width=device-width, initial-scale=1">
 <link rel="stylesheet" type="text/css" media="screen" href="main.css" />
</head>
 
<body>
<button class=”btn”>Click Me!</button>
<script src="./JS/event.js"></script>
</body>
 
</html>
`}
        </CodeBlock>
        <p>
          ・events.js
        </p>
        <CodeBlock>
          {`
const btn = document.querySelector('.btn');
 
btn.addEventListener('click', function(){
 console.log('It got clicked!');
});
`}
        </CodeBlock>

        <p>
          実行結果（Console）
        </p>
        <p>
          ブラウザ上でボタンをクリックするとIt got clicked!と表示されます。
        </p>
        <p>
          <img src='https://lh6.googleusercontent.com/1fbIJTEqKa_VMc0kKtmiWGr9hO8q1axrzzpHiMeRXaLLLu-l3uRc5GVDYNIGGqYZJiotcdPVSgFgkHDSKBJTUgvE774VZixKTZoLqzEHajG_pLtEvqtAPjDQPznunTlkWM1jy_cN=s0'
        width='680'
        height='67'
        alt='addEventListener' />
        </p>
        <p>
          処理の流れとして、querySelectorで要素を取り出し、取り出した要素がクリックされた時に、第２引数の無名関数が実行されます。
        </p>
        <br />
        <p>
          また、第２引数のコールバック関数をhandleClickとして定義した場合も、同様の実行結果となります。
        </p>
        <CodeBlock>
          {`
const btn = document.querySelector('.btn');
function handleClick(){
 console.log('It got clicked!');
}
btn.addEventListener('click',handleClick);
`}
        </CodeBlock>
        <h2 dir='ltr'>
          第２引数の関数に（）がいらない理由とは？
        </h2>
        <p>
          ここで、addEventListenerの第２引数がなぜhandleClick()ではなく、handleClickだけでいいのか？という疑問があるのではないでしょうか。
        </p>
        <br />
        <p>
          基本的に、関数を呼び出す場合は（）をつけなければなりません。しかし、今回は第２引数に関数を示すだけで良いので、（）を付ける必要がありません。
        </p>
        <br />
        <p>
          なぜなら、addEventListenerが呼び出された時点で関数が呼び出されてしまってはいけないからです。
        </p>
        <br />
        <p>
          そして、示された関数はクリックされた時に、ブラウザがhandleClickを自動的に呼び出してくれることになります。
        </p>
        <h2 dir='ltr'>
          関数を事前に定義しておくことのメリットとは？
        </h2>
        <p>
          関数を事前に定義しておくことで得られるメリットの１つ目に、関数の内容変更があった時に第２引数として定義している部分を修正する必要がなく、関数を定義している部分だけを修正すればいいので手間が省けます。
        </p>
        <br />
        <p>
          以下の例ように、２つのボタンの第二引数にhandkeClickを渡しているので、修正する時にhandleClick()の処理内容だけを変更すればいいので楽ですね。
        </p>
        <br />
        <p>
          ・index.html
        </p>
        <CodeBlock>
          {`
<button class=”btn”>Click Me!</button>
<button class="cool">Click me also!</button>
        `}
        </CodeBlock>
        <p>
          ・event.js
        </p>
        <CodeBlock>
          {`
const btn = document.querySelector('.btn');
const coolButton = document.querySelector('.cool');
 
function handleClick(){
 console.log('It got clicked!');
}
btn.addEventListener('click',handleClick);
coolButton.addEventListener('click',handleClick);
`}
        </CodeBlock>
        <br />
        <p>
          <img src='https://lh3.googleusercontent.com/42o_4e5VMrkOCtJwveC6t27SsiC2yNnGIyxrhLA1P1m5PKmXoUGGYqHGynS0JGk_sbeMFy2YvqZHvBsXOjDDaoNo_kHnctw6rERlQ8BCeYW2rp9rhWELw7aT8VnI7YAIuSghJUwx=s0'
        width='680'
        height='63'
        alt='第二引数にhandkeClickを渡す' />
        </p>
        <p>
          そして、関数を事前に定義しておくことで得られるメリットの２つ目に、removeEventListener()が使用できることが挙げられます。
        </p>
        <br />
        <p>
          先程のように、無名関数を使った場合は紐付けがなされていないため、removeEventListener()が効きません。しかし、関数を定義しておくことによって紐付け（binding）がなされるため、removeEventListener()を使用する事ができます。
        </p>
        <br />
        <p>
          ですので、紐付けされたEventListenerを取り除きたい時は、名前付きの関数もしくはアロー関数を使用しなければなりません。
        </p>
        <CodeBlock>
          {`
  btn.addEventListener('click', function(){
  console.log('Im a tel');
  });
   
  btn.addEventListener('click',handleClick);
  coolButton.addEventListener('click',handleClick);
   
  btn.removeEventListener('click', function(){
  console.log('Im a tel');
  });
`}
        </CodeBlock>
        <p>
          →removeできません
        </p>
        <CodeBlock>
          {`
btn.addEventListener('click',handleClick);

coolButton.addEventListener('click',handleClick);
        
btn.removeEventListener('click', handleClick);
        `}
        </CodeBlock>
        <p>
          →removeできました
        </p>
        <br />
        <p>アロー関数を使用する場合：</p>
        <p>アロー関数を使用する場合は、関数を変数として保存しましょう。</p>
        <CodeBlock>
          {`
const btn = document.querySelector('.btn');
const coolButton = document.querySelector('.cool');

const handleClick = () => {
console.log('It got clicked!');
}
 
btn.addEventListener('click', handleClick);
 
coolButton.addEventListener('click',handleClick);
 
btn.removeEventListener('click', handleClick);
        `}
        </CodeBlock>
        <p>
          →removeできました
        </p>

        <h2 dir='ltr'>
          複数要素にaddEventListenerを行いたい！
        </h2>
        <p>
          では次に、複数のボタンにaddEventListenerを適用するにはどうするのか見ていきましょう。
        </p>
        <ul>
          <li dir='ltr'>
            <p>
              - index.html
            </p>
          </li>
        </ul>
        <CodeBlock>
          {`
<h2>Buy buttons</h2>

<button class="buy">Buy Item 1</button>
<button class="buy">Buy Item 2</button>
<button class="buy">Buy Item 3</button>
<button class="buy">Buy Item 4</button>
<button class="buy">Buy Item 5</button>
<button class="buy">Buy Item 6</button>
<button class="buy">Buy Item 7</button>
<button class="buy">Buy Item 8</button>
<button class="buy">Buy Item 9</button>
<button class="buy">Buy Item 10</button>
 
<script src="./JS/event.js"></script>
        `}
        </CodeBlock>
        <ul>
          <li dir='ltr'>
            <p>
              - event.js
            </p>
          </li>
        </ul>
        <CodeBlock>
          {`
const buyButtons = document.querySelectorAll('button.buy');
 
function buyItem() {
 console.log('Buying item');
}
 
buyButtons.addEventListener('click', buyItem);
`}
        </CodeBlock>
        <p>
          querySelectorAllを使用した場合、複数ボタンにaddEventListenerを適用させることはできませんでした。
        </p>
        <br />
        <p>
          では、配列（ループ）もしくはforEachメソッドを使えば良さそうですが、まだ配列は学習しておりませんので、forEachメソッドを今回は使いたいと思います。
        </p>
        <CodeBlock>
          {`
buyButtons.forEach(function(buyButton, index) {
  console.log('Binding the buy button');
  buyButton.addEventListener('click', () => {
    console.log(\`you clicked \${index}\`);
  });
 });
`}
        </CodeBlock>
        <p>
          実行結果（Console）
        </p>
        <p dir='ltr'>
          <img src='https://lh5.googleusercontent.com/r0cSu4WCHyMTMe2EpfvHF35L0V4zpalo4hxhSYqGE0FCaHnAXJ2u6KSCeUEKSfpE8gXPHnGpfuvefOcOLBW0yMKdD5KuV43vWtXomxaZuQulAU_I-SUilrFRyKjS_gaSwg6hh4qG=s0'
        width='680'
        height='168'
        alt='forEach()メソッド' />
        </p>
        <p dir='ltr'>
          1〜10のボタンをクリックした時に、押したボタンのindexごとにyou clicked
          (ボタンのindex)という表示がConsole上でなされるのが確認できます。
        </p>
        <br />
        <p>
          また、無名関数を使うのではなく、外部に名前付きの関数を定義しても同じ結果となります。
        </p>
        <CodeBlock>
          {`
function attachListener(buyButton, index){
  console.log('Binding the buy button');
  buyButton.addEventListener('click', () => {
    console.log(\`you clicked \${index}\`);
  });
}
buyButtons.forEach(attachListener);
`}
        </CodeBlock>
        <p>
          ちなみに、buyButtons.forEach(function(buyButton, )のbuyButtonは、forEachで現在処理されている配列の要素ですので、どんな名前でも結構です。また、removeする時も同様にforEachメソッドを用います。
        </p>
        <br />
        <p>
          それから、アロー関数を使った場合はこのようになります。
        </p>
        <CodeBlock>
          {`
buyButtons.forEach((buyButton, index) => {
  console.log('Binding the buy button');
  buyButton.addEventListener('click', () => {
    console.log(\`you clicked \${index}\`);
  });
});
`}
        </CodeBlock>
        <p>
          実行結果
          <img src='https://lh5.googleusercontent.com/r0cSu4WCHyMTMe2EpfvHF35L0V4zpalo4hxhSYqGE0FCaHnAXJ2u6KSCeUEKSfpE8gXPHnGpfuvefOcOLBW0yMKdD5KuV43vWtXomxaZuQulAU_I-SUilrFRyKjS_gaSwg6hh4qG=s0'
        width='680'
        height='168'
        alt='アロー関数を使った場合' />
        </p>
        <br />
        <p>
          以上、最も基本的なaddEventListener()メソッドと、ボタンをクリックされた時の処理について見てきましたがいかがだったでしょうか？
        </p>
        <br />
        <p>
          今回も最後までお読みいただきありがとうございました。
        </p>
      </LessonLayout>
    </div>
  )
}

export default withRouter(Event_1Component)