import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DOM_3Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={14}
         title=' DOM要素 - プロパティとメソッド - ' />
        <div>
          <p>
            では、前回はgetElementById()メソッドなどを使って、要素を取得する方法をみていきました。
          </p>
          <br />
          <p>
            では、今回は取得された要素（Element）からプロパティとメソッドを使ってできることを見ていきましょう。
          </p>
          <br />
          <p>
            まずは、index.htmlとthe-dom.js前回に引き続き使用していきます。
          </p>
          <br />
          <p>
            ・index.html
          </p>
          <CodeBlock>
            {`
<!DOCTYPE html>
<html lang=”en”>
 
<head>
 <meta charset=”UTF-8”>
 <meta name=”viewport” content=”width=device-width,initial-scale=1.0”>
 <title></title>
</head>
 
<body>
 <div class="items">
   <div class="item">
     <img src="https://picsum.photos/300/300?random=1" alt="">
     <p>Hi I’m a item</p>
   </div>
   <div class="item2">
     <img src="https://picsum.photos/300/300?random=2" alt="">
     <h2>I am a heading</h2>
     <p>Hi I’m a item</p>
   </div>
   <article class="item">
     <h2>I am an article</h2>
   </article>
 </div>
 
 <script src="./the-dom.js"></script>
</body>
 
</html>
        `}
          </CodeBlock>
         
          <br />
          <p>
            ・the-dom.js
          </p>
          <CodeBlock>
            {`
const p = document.querySelector('p');
const imgs = document.querySelectorAll('.item img');
const item2 = document.querySelector('.item2');
const item2Image = item2.querySelector('img');
const heading = document.querySelector('h2');
console.log(heading);
        `}
          </CodeBlock>
          <p>
            <img src='https://lh4.googleusercontent.com/AJRuuo3d3uVX4UHv5fnNV34Hk6CrDcgTCtL4mWDyUpX5iUPCBeR8gCJ5zvjYD1CtyNGljwJUoxzFliq6eoF_4Jf4K1_ZaN4jsp8-7fLnYglTSiehg8o01MGS4o1cyPF4xhdxHrCs'
                  width='380'
                  height='594'
                  alt='h2要素' />
          </p>
          <br />
          <p>
            実行結果として、h2の要素が返ってきました。
          </p>
          <br />
          <p>
            また、要素を示すconsole.logではなく、console.dirをつかって要素を操作する時に使うプロパティを確認することができます。
          </p>
          <br />
          <p>
            console.dirは、オブジェクトの要素ではなく、プロパティ（名前keyと値valueが対となったもの）を返します。
          </p>
          <br />
          <p>
            <img src='https://lh3.googleusercontent.com/elDOIkZKNKQGHKD2bxb3gEuYcHiNuy4rynq5cJpGFRLd5CSbu00sJvAQSdhQfYyaEDedFt_NIRrWbkC-ZsOcpOAeXmXc0jOgT15nSrHo9DyCnDBpVQ2In5qgqW2EAWzPLE6u3jKf'
                  width='222'
                  height='271'
                  alt='h2プロパティ' />
          </p>
          <br />
          <p>
            また、この中にあるtextContentプロパティを使うことでセッター、ゲッターとしても機能します。
          </p>
          <br />
          <p>
            textContent は Node のプロパティで、ノードおよびその子孫のテキストの内容を表します。
          </p>
          <br />
          <CodeBlock>
            {`
console.log(heading.textContent);
heading.textContent = ‘ Tel is cool’;
console.log(heading.textContent);

実行結果
I’m a heading
Tel is cool
        `}
          </CodeBlock>
          <br />
          <p>
            heading.textContentをTel is coolで上書きされているのが確認できました。
          </p>
          <br />
          <p>
            また、console.log(heading.innertext);でもtextContentと同じ値を返します。
          </p>
          <h2 dir='ltr'>
            textContentとinnerTextの違いは何？
          </h2>
          <p>
            textContent は、 &lt;script&gt; と &lt;style&gt; 要素を含む、すべての要素の内容を取得します。一方、
            innerText は「人間が読める」要素のみを示します。
          </p>
          <br />
          <p>
            ・index.html
          </p>
          <CodeBlock>
            {`
<h2>
I am a  heading
<style>
h2 {
color: red;
}
</style>
</h2>

・the-dom.js
console.log(heading.textContent);;
console.log(heading.innerText);

実行結果
I am a  heading
h2 {
color: red;
}

I am a  heading
        `}
          </CodeBlock>
          <br />
          <p>
            heading.innerTextでは、文字列しか返ってきませんでしたが、heading.textContentではスタイルを含む要素が返ってきました。
          </p>
          <h2 dir='ltr'>
            innerHTMLプロパティ
          </h2>
          <br />
          <p dir='ltr'>
            Element オブジェクトの innerHTML プロパティは、要素内の HTML または XML のマークアップを取得したり設定したりします。
          </p>
          <br />
          <p dir='ltr'>
            つまり、HTML要素の中身を変更することで、動的webページを作ることができます。
          </p>
          <br />
          <p dir='ltr'>
            また、innerHTMLプロパティを使用するにあたってセキュリティのことは気をつけなければなりません。
          </p>
          <br />
          <p dir='ltr'>
            なぜなら、innerHTMLを通した&lt;script&gt;要素は実行すべきでないと決まっていますが、&lt;img&gt;要素などをつかってクロスサイトスクリプティングを実行することが可能となるからです。
          </p>
          <br />
          <p dir='ltr'>
            そのため、テキストを挿入したい場合は、innerHTMLを使用せずにtextContentプロパティを用いるようにしましょう。
          </p>
          <br />
          <h2 dir='ltr'>
            innerHTML, innerText, textContent違い
          </h2>
          <br />
          <p dir='ltr'>
            textContentは、全てを表示するための単純なテキストとして扱い、htmlタグを文字としてそのまま出力します。
          </p>
          <br />
          <p dir='ltr'>
            innerTextはエスケープ文字がその通りに機能します。（例：改行、タブなど）
          </p>
          <br />
          <p dir='ltr'>
            innerHTMLはHTMLタグがあるとタグとして機能するので、htmlタグを解釈して出力します。
          </p>
          <br />
          <p dir='ltr'>
            次に、innerHTML, outerHTMLの違いについて見ていきましょう。
          </p>
          <div>
            <br />
          </div>
          <h2 dir='ltr'>
            innerHTMLプロパティと outerHTMLプロパティの違いとは？
          </h2>
          <p>
            早速、console.logで比較します。
          </p>
          <br />
          <CodeBlock>
            {`
console.log(heading.innerHTML);
console.log(heading.outerHTML);

実行結果
I am a  heading
h2 {
color: red;
}

<h2>
I am a  heading
<style>
h2 {
color: red;
}
</style>
</h2>
        `}
          </CodeBlock>
          <br />
          <p>
            outerHTMLは要素タグを含み、innerHTMLは要素タグを含まないということが確認できました。
          </p>
          <br />
          <p>
            では次に、取得した要素に文字列を追加する方法をご紹介します。
          </p>
          <br />
          <h2 dir='ltr'>
            取得した要素に文字列を追加する方法
          </h2>
          <p>
            例を示します。
          </p>
          <br />
          <p>
            ・index.html
          </p>
          <CodeBlock>
            {`
<article class=”item”>
<h2>Im an article</h2>
<p class=”apple”>This is how many apples I ate! apple</p>
</article>
        `}
          </CodeBlock>
          <br />
          <p>
            ・the-dom.js
          </p>
          <CodeBlock>
            {`
const appleList = document.querySelector(‘.apple’);
console.log(appleList.textContent);

実行結果
This is how many apples I ate! apple
        `}
          </CodeBlock>
          <br />
          <p>
            クラス属性にappleを持つpタグ要素が取得されました。では、appleをもう一つ増やしましょう。
          </p>
          <br />
          <CodeBlock>
            {`
appleList.textContent = \`\${appleList.textContent} + apple\`;

実行結果
This is how many apples I ate! apple apple
        `}
          </CodeBlock>
          <br />
          <p>
            このように、バッククォート（`）で囲むことで文字列を追加することができますが、処理速度に時間がかかってしまいます。ですので、別の方法をご紹介します。
          </p>
          <h2 dir='ltr'>
            Element.insertAdjacentText()メソッド
          </h2>
          <br />
          <p>
            insertAdjacentText() メソッドは、与えられたテキストノードを、メソッドを実行した要素に対する相対的な位置に挿入します。
          </p>
          <br />
          <p>
            また、これはプロパティではなくメソッドになります。
          </p>
          <br />
          <p>
            プロパティは多くの場合、ノード名が何かを参照します。
          </p>
          <br />
          <p>
            メソッドは多くの場合、何を行うのか(ノードの削除)を参照します 。
          </p>
          <h3 dir='ltr'>
            Element.insertAdjacentText()メソッドの使い方
          </h3>
          <br />
          <p>
            element.insertAdjacentText(position, element);
          </p>
          <br />
          <p>
            引数であるpositionの種類についてですが、以下のようになります。
          </p>
          <br />
          <p>
            ・’beforebegin’: elementの前
          </p>
          <p>
            ・’afterbegin’: elementの内側の、最初の子要素の前
          </p>
          <p>
            ・’beforeend’: element内側の、最後の子要素の後
          </p>
          <p>
            ・’afterend’: elementの後
          </p>
          <br />
          <p>
            例を示します。
          </p>
          <br />
          <CodeBlock>
            {`
appleList.insertAdjacentText(‘beforeend’, ‘apple’);

実行結果
This is how many apples I ate! apple apple
        `}
          </CodeBlock>
          <br />
          <p>
            では、beforebeginの場合はどうでしょうか？
          </p>
          <br />
          <CodeBlock>
            {`
appleList.insertAdjacentText(‘boforebegin’, ‘apple’);

実行結果
<article class=’item’>
<h2>Im an article</h2>
‘apple’
<p class=’apple’>This is how many apples I ate! apple</p>
</article>
        `}
          </CodeBlock>
          <br />
          <p>
            afterbeginの場合はどうでしょうか？
          </p>
          <br />
          <CodeBlock>
            {`
appleList.insertAdjacentText(‘afterbegin’, ‘apple’);

実行結果
<article class=”item”>
<h2>Im an article</h2>
<p class=”apple”>
”apple"
This is how many apples I ate! apple</p>
</article>
        `}
          </CodeBlock>
          <br />
          <p>
            取得した要素に文字列を追加することができるようになりました。
          </p>
          <br />
          <p>
            では、最後にMDNを参考にして用語の確認を行います。
          </p>
          <h2 dir='ltr'>
            Nodeについて
          </h2>
          <p>
            DOMツリーのひとつひとつのデータ（オブジェクト）がNodeと呼ばれています。なので、NODEツリー、DOMツリーと呼ばれます。
          </p>
          <br />
          <p>
            Nodeには種類があり、htmlの要素を表す部分はelementノード、テキスト・空白・改行はTextノードと呼ばれます。
          </p>
          <br />
          <p>
            また、htmlとheadの関係をhtmlを親Node、headを子Nodeと言ったりもします。また、同じ階層にあるNodeをSibling
            Nodeと言います。
          </p>
          <h2 dir='ltr'>
            Elementについて
          </h2>
          <p>
            Element は Document の中にあるすべての要素オブジェクトが継承する、もっとも一般的な基底クラスです。
          </p>
          <br />
          <p>
            このインターフェイスは、すべての種類の要素に共通するメソッドとプロパティを記述するだけのものです。
          </p>
          <br />
          <p>
            また、ElementはSGML/HTML/XMLで出てくる概念であり、Nodeの中のひとつで、Element型のNodeのことです。
          </p>
          <br />
          <p>
            つまり、elementはnodeの特定タイプの一つで、ElementはNodeを継承します。
          </p>
          <br />
          <p>
            Elementのmdnのページから、すべてのプロパティを確認することができます。
          </p>
          <br />
          <p>
            <a href='https://developer.mozilla.org/ja/docs/Web/API/Element'>
              https://developer.mozilla.org/ja/docs/Web/API/Element
            </a>
          </p>
          <br />
          <p>
            今回使用したtextContentはElementにはなく、Nodeのページで確認することができます。
          </p>
          <br />
          <p>
            textContent は Node のプロパティで、ノードおよびその子孫のテキストの内容を表します。
          </p>
          <br />
          <h2 dir='ltr'>
            メソッドについて
          </h2>
          <br />
          <p>
            今回使用したinsertAdjacentTextメソッドや、querySelectorAllメソッドはElementのメソッドの一つです。
          </p>
          <br />
          <p>
            以上が用語のまとめになります。
          </p>
          <br />
          <p>
            今回も最後までお読みいただきありがとうございました。
          </p>
          <div>
            <br />
          </div>
        </div>
      </LessonLayout>
    </div>
  )
}

export default withRouter(DOM_3Component)