import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DOM_6Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={16}
          title=' DOM_6 - HTMLの生成 - ' />
        <p>
          今回は、DOM操作における要素の作り方やDOMツリーへの要素の挿入方法について見ていきましょう。
        </p>
        <h2 dir='ltr'>
          Document.createElement()
        </h2>
        <br />
        <p>
          JavaScript で HTML 要素を動的に生成する場合、 createElement() メソッドを使用します。
        </p>
        <br />
        <p>
          document.createElement() メソッドは 、HTML 文書においてtagName で指定された HTML 要素を生成し、または
          tagName が認識できない場合は HTMLUnknownElement を生成します。
        </p>
        <br />
        <p>
          【書き方】
        </p>
        <CodeBlock>
          {`
const element = document.createElement(タグ名)
        `}
        </CodeBlock>
        <p>
          タグ名は、html、div、pのようなHTML要素を入れます。では、サンプルコードを準備して見ていきましょう。
        </p>
        <br />
        <p>
          ・creating.js
        </p>
        <CodeBlock>
          {`
const myParagraph = document.createElement('p');
myParagraph.textContent = 'I am tel';
        `}
        </CodeBlock>
        <p>
          <img src='https://lh4.googleusercontent.com/NT5A2R5DE7GiI5kFIbDLZ_CTIkgRW8gti5681AYwJATSJhKDl61XLjPXLkqPAHIOn96suHgS7L1YE4Od_EQ1pwJVw_nVZVC1Ds0u8Co2a_5gu2TFw6NXIFSjyS5qDGw3qavj9umZ'
            width='680'
            height='139'
            alt='I am tel' />
        </p>
        <br />
        <p>
          この場合、まだJavaScriptのメモリに要素が追加されただけですので、ブラウザ上では表示されていません。では、テキストとクラス属性を追加してみましょう。
        </p>
        <CodeBlock>
          {`
const myParagraph = document.createElement('p');
myParagraph.textContent = 'I am tel';
myParagraph.classList.add('special');
console.log(myParagraph);
        `}
        </CodeBlock>
        <img src='https://lh4.googleusercontent.com/wRlQhgGIAQzMDby8a-qta5whluuSMQ3UA8qGSdJlyZHIAzvm_Dk9Z6WblOnU2AE8gpTF928V1t_yDjLDVxE3TtehvGgJHiV_YEUF5rp7uNIoeJnW0gtcbvxMgcBsuo3MOYIn2RQS'
          width='560'
          height='68'
          alt='テキストとクラスの追加' />
        <br />
        <p>
          textContent()メソッドでテキストを、classList.addメソッドでクラス属性を追加することができました。
        </p>
        <br />
        <p>
          では、 DOM上にテキストを挿入する前に、イメージを追加してみましょう。
        </p>
        <CodeBlock>
          {`
const myImage = document.createElement('img');
myImage.src = 'https://picsum.photos/500';
myImage.alt = 'Nice photo';
 
const myDiv = document.createElement('div');
myDiv.classList.add('wrapper');
console.log(myDiv);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/7Z-zUqqqbXKpz-Kou2_3xIDZTg3cU1vepiP4lvoVMPoofWokzgnlFYJjRs3mhehOPgzp4fKVPd0_X0SSauSsr8HZplnfNfodZBWEvmpwLE2eqOCEjx583nMfvl2VtYRNoFfjVB9_'
            width='516'
            height='108'
            alt='イメージの追加' />
        </p>
        <br />
        <p>
          DOMには反映されませんが、イメージとwrapperのクラス属性を持つdiv要素をメモリに追加することができました。
        </p>
        <h2 dir='ltr'>
          Node.appendChild()
        </h2>
        <br />
        <p>
          次に、Node.appendChild() メソッドを使って、生成した要素を実際の HTML 要素に追加し、 HTML
          をDOMツリー内に動的に生成してみましょう。
        </p>
        <br />
        <p>
          Node.appendChild() メソッドは、特定の親ノードの子ノードリストの末尾にノードを追加します。
        </p>
        <br />
        <p>
          追加しようとしたノードが既に存在していたら、それは現在の親ノードから除かれ、新しい親ノードに追加されます。
        </p>
        <br />
        <p>
          では、Node.appendChild() メソッドを使って、DOMのbody内にmyParagraph要素を追加していきましょう。
        </p>
        <CodeBlock>
          {`
document.body.appendChild(myParagraph);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh6.googleusercontent.com/lyJKQ6y5C61IlFf7yhkniwYFd54BDoSn7-9Uhx6o1KaiqdQW8YRtL7IabXSPFyUqab-RygBY0utTYO51twn7h23lKtpv0oVcWdbrsAnmfeHNFPQ45Vno1l-9Sv7uK97QvwCS_ya3'
            width='638'
            height='260'
            alt='Node.appendChild()' />
        </p>
        <br />
        <p>
          DOMのbody内にI am telを表示させることができました。
        </p>
        <br />
        <p>
          では、次に画像をNode.appendChild()メソッドを3回使うことで、DOMのbody内に挿入します。
        </p>
        <CodeBlock>
          {`
document.body.appendChild(myDiv);
myDiv.appendChild(myParagraph);
myDiv.append(myImage);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/9U7uldoMj-LyY7ijCqXjBGs10Ay6sAelG1GhDPKiFiYh5gt0TPboX2OaMroZPU9UI8kGsiyghIa2B-fCauRbi1lxZjVRe-gdTy4zmmOwcZUvw4rFIeq2BwDSmg8gjZ2zuOsrK2_T'
            width='680'
            height='512'
            alt='bodyへの挿入' />
        </p>
        <p>
          ブラウザ上でdivの中に、myParagraphのテキストとmyImageの画像を追加することができました。
        </p>
        <br />
        <p>
          ちなみに、bodyの場合は以下のようにquerySelector()メソッドで取得する必要はなく、document.bodyで取得することも可能です。
        </p>
        <CodeBlock>
          {`
const body = document.querySelector(‘body’);
↓
const body = document.body;
        `}
        </CodeBlock>
        <h2 dir='ltr'>
          Javascriptが遅くなる原因：Reflow
        </h2>
        <br />
        <p>
          ただし、appendChildを使うとテキストや画像などの要素が変わるということなので、その度にDOMを修正することになります。これを「Reflow」と言います。
        </p>
        <br />
        <p>
          ちなみに、ブラウザがHTMLを表示するまでには大きく、2つの工程があります。
        </p>
        <br />
        <ol>
          <li dir='ltr'>
            <p>
              Reflow(Layout)：HTMLを解釈しスペースを算出
            </p>
          </li>
          <li dir='ltr'>
            <p>
              Repaint：スペースの中に内容を描画
            </p>
          </li>
        </ol>
        <br />
        <p>
          上記の例では、appendChild()メソッドを3回使うことで、その度にこの工程が行われ、ＤＯＭ操作や表示が遅くなる原因となります。
        </p>
        <br />
        <p>
          ですので、できるだけ不要な処理を減らすことを心がけなければなりません。その例を以下に示します。
        </p>
        <CodeBlock>
          {`
myDiv.appendChild(myParagraph);
myDiv.appendChild(myImage);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh3.googleusercontent.com/a0mgPbenhz5EksWa0Tw8fT6kdS0zydGF6od-l-NwvSwCmoM3eHuQv9CioyZQ9f_F13pvDWfP5MbWGdaInBoJSGYaWaJXjWAMBzhSSMSHr2F7zd3d7IZhYSE82Mn_JkFb-rXaR2Wf'
            width='481'
            height='111'
            alt='Reflow' />
        </p>
        <br />
        <p>
          この場合、myDivにmyParagraphとmyImageを追加する事で、まだDOMのbody内には要素が追加されておらずブラウザには反映されていませんが、メモリ状に反映させていることになります。
        </p>
        <CodeBlock>
          {`
myDiv.appendChild(myParagraph);
myDiv.appendChild(myImage);

document.body.appendChild(myDiv);
        `}
        </CodeBlock>
        <p>
          そして、以上のように最後に一度だけbodyにappendChild()で要素を追加しているので、実際にDOMに追加しているのは1回のみとなり、Reflow,Repaintが起きるのを減らし、高速化をおこなうのとができるのです。
        </p>
        <br />
        <p>
          実行結果は、appendChild()メソッドを3回使ったときと同様となります。
        </p>
        <h2 dir='ltr'>
          DOM要素を追加する方法：insertAdjacentElement()
        </h2>
        <br />
        <p>
          次に、要素を入れ忘れて追加したい場合などに使える便利なメソッド「insertAdjacentElement()」をご紹介します。
        </p>
        <br />
        <p>
          insertAdjacentHTML() は、第二引数で指定するテキストを HTML または XML としてパースし、その結果であるノードを DOM
          ツリー内の指定された位置に挿入します。
        </p>
        <br />
        <p>
          つまり、insertAdjacentElement()を使うことによって、DOMツリー内の指定位置への要素を挿入することができるのです。
        </p>
        <br />
        <p>
          【書き方】
        </p>
        <CodeBlock>
          {`
targetElement.insertAdjacentElement(position, element);
        `}
        </CodeBlock>
        <p>
          targetElementのpositionの位置にelementを挿入することになります。
        </p>
        <br />
        <p>
          【position】
        </p>
        <p>
          ・<strong>beforebegin</strong>: targetElementの前
        </p>
        <p>
          ・<strong>afterbegin</strong>: targetElementの内側の、最初の子要素の前
        </p>
        <p>
          ・<strong>beforeend</strong>: targetElement内側の、最後の子要素の後
        </p>
        <p>
          ・<strong>afterend</strong>: targetElementの後
        </p>
        <br />
        <p>
          また、要素を挿入するのに似たようなメソッドであるinnerHTMLは、要素の中身をすべて新しいものに書き換えるので、既存の要素を破壊することになるので、あまりオススメできません。
        </p>
        <br />
        <p>
          では、トップ（h2）に要素を追加したい場合の例を示します。
        </p>
        <br />
        <CodeBlock>
          {`
const heading = document.createElement('h2');
heading.textContent = 'Cool Things';

myDiv.insertAdjacentElement('beforebegin', heading);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh5.googleusercontent.com/ZFRwgiJyxjrPpcZki1Zj9ZQ7jmgnYJy6X8kaM5gOiQvPBvzYL7hlY-pvzerFa-DoyIPntveBmK_CPeFiC8sQZVoha8l3PrN_tuIp7Dy8fFDHHRLFOh9NoxWnHcVYSH72QvFeqzUF'
            width='680'
            height='261'
            alt='h2要素の追加' />
        </p>
        <br />
        <p>
          myDiv要素の一番先頭にheading要素のテキストCool Thingsを表示することができました。
        </p>
        <h2 dir='ltr'>
          リストを作る：Node.cloneNode()
        </h2>
        <br />
        <p>
          では、要素を複製してリストを追加する例も見てみましょう。
        </p>
        <br />
        <p>
          Node.cloneNode()は、現在のノードの複製を返します。
        </p>
        <br />
        <p>
          【書き方】
        </p>
        <CodeBlock>
          {`
var dupNode = node.cloneNode(deep);
        `}
        </CodeBlock>
        <p>
          また、引数deepは、node の子孫ノードを複製する場合は true 、 node のみを複製する場合は falseを使います。
        </p>
        <br />
        <p>
          では、以下のようなリストを生成する例を示します。
        </p>
        <br />
        <p>
          ・one
        </p>
        <p>
          ・two
        </p>
        <p>
          ・three
        </p>
        <p>
          ・four
        </p>
        <p>
          ・Five
        </p>
        <br />
        <CodeBlock>
          {`
const list = document.createElement('ul');
const li = document.createElement('li');
li.textContent = 'three';
list.appendChild(li);

document.body.insertAdjacentElement('afterbegin', list);

const li5 = document.createElement('li');
li5.textContent = 'Five';
list.append(li5);

const li1 = li5.cloneNode(true);
li1.textContent = 'one';
list.insertAdjacentElement('afterbegin', li1);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh6.googleusercontent.com/EmQjXCaUmrjwzMC4tuufIdufHQ7cks3vHWTp9HF8PSuzu5t-WzF33zvvvbFXy1NMTAOdve4RrR7BNPvJbAZ5IQNgdnBJRzzFLAJbx4p21c2oNWAzuHRG3XGKu86MRa1-xU3I50bG'
            width='426'
            height='240'
            alt='要素の生成、追加' />
        </p>
        <br />
        <p>
          document.createElementでliの空の要素を生成し、textContentで中身の設定を行い、appendChildでDOMツリーに要素を追加することができました。
        </p>
        <br />
        <p>
          また、cloneNode()メソッドを用いて、変数li5をコピーします。ちなみに、ここでfalseを渡すと、要素の中身を複製せず空のli要素が作られます。
        </p>
        <br />
        <p>
          今回は、中のテキストもコピーしたいのでtrueにしました。これで、li5要素をDOMに複製することができ、textContentで要素の値をoneに上書きしたのです。
        </p>
        <br />
        <p>
          では、最後に２と４を追加していきましょう。
        </p>
        <br />
        <CodeBlock>
          {`
const li4 = document.createElement('li');
li4.textContent = 'four';
li5.insertAdjacentElement('beforebegin', li4);

const li2 = document.createElement('li');
li2.textContent = 'two';
li1.insertAdjacentElement('afterend', li2);
        `}
        </CodeBlock>
        <p>
          <img src='https://lh5.googleusercontent.com/9FBCyM85T3r-ezMnlUbxbXNnAcd7xWSmfCWXcvrmekhwWtmDR3jLV0qlt1PQYqjmZAcN0JGNyu8VZsWHrxq5ofWmKSWUgVt4y4OW_LlMQQa89y869-DtGI60x3FpLpL1Jy1yKXVU'
            width='516'
            height='322'
            alt='リストの生成' />
        </p>
        <br />
        <p>
          今回は繰り返し文を使わなかったので、助長なコードになってしまいましたが、要素の追加方法の基礎について学ぶことができました。
        </p>
        <br />
        <p>
          以上、最後までお読み頂きありがとうございました。
        </p>
        <div>
          <br />
        </div>
      </LessonLayout>
    </div>
  )
}


export default withRouter(DOM_6Component)