import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'


const BrowserTerminalSetupComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={2}
          title=' JavaScriptを動かすための環境設定~ブラウザ、エディタ、ターミナルの各種セットアップ~' />
        <h2>
          ブラウザとは
        </h2>
        <p>
          ブラウザには、Chrome（Google）やFireFox（Mozilla）、Edge（Microsoft）、Safari（Apple）などがあり、ブラウザを起動しているPC自体またはネットワーク上のコンピュータ（サーバー）と、データを送受信して、PCのスクリーンにwebページを表示させたりするソフトのことを言います。</p>
        <p>
          Webページは、文字や画像などと基本構造をhtmlで、そのデザインをcssで、ユーザーの行動に対する反応などの複雑な機能をJavaScriptで決めることができます。
        </p>
        <p>
          htmlとcssは<a href='https://www.w3.org/'>The World Wide Web Consortium</a>
          、JavaScriptは    <a href='http://www.ecma-international.org/'>ECMA International</a>
          という規格団体によって、時代に合わせた開発がし易いように、アップデートが繰り返されていて、このような言語の規格（ルール）の変更などを適切に反映したり、インターネットを通した攻撃に対応できるように、ブラウザもアップデートが繰り返されています。
        </p>
        <p>
          普通のユーザーは、ネットワーク上のサーバーにあるデータにアクセスするだけですが、Webページの製作を行う場合、コンピュータ自体に保存されているデータを読み込んで、webページとして表示することもできます。
        </p>
        <h2>
          開発者ツール（Developer Tools）
        </h2>
        <p>
          開発者ツールとは、ブラウザに含まれる、webページの製作作業を効率化するための機能のことです。
        </p>
        <p>
          ブラウザが読み込んでいるhtmlの構造や、webページの見た目では確認できないJavaScriptなどのプログラムの実行結果の確認をはじめ、様々な作業が行えます。
        </p>
        <p>
          開発者ツールの起動は、ChromeやFireFoxなら、ブラウザ上でWindowsなら「control + shift + I」で、Macなら「option + command + i」のショートカットキーで行えます。
        </p>
        <p>
          webページ製作においては、開発者ツールが優れているので、ChromeやFireFoxの使用が推奨されます。
        </p>
        <h2>
          Visual Studio Code
        </h2>
        <p>
          テキストエディターとは、文字列を編集などするプログラムのことで、Visual Studio Codeはその中の一です。
        </p>
        <p>
          wordやpagesのように文字に効果を加えて書類を作成するのではなく、コードなどを書いてプログラムを作成するのに主に使われ、プログラミングを効率化する便利な機能が標準で備わっています。
        </p>
        <p>
          <a href='https://code.visualstudio.com/'>Microsoft社製のVisual Studio Code</a>
          は、無料で、Mac、Windows、Linuxで使用でき、有用な拡張機能を多数使用することができます。
        </p>
        <br />
        <p>
          テキストエディターは数多く存在しています、中でも今回Visual Studio Code(以後VS Code）をおすすめする理由は、
        </p>
        <ol>
          <li>1. オープンソースであること。- 誰でも無料で使用できます。</li>
          <li>2. 多くの言語に対応している。 - メニューなどの表記をさまざまな言語に切り替えることができます。もちろん日本語にも対応しています。</li>
          <li>3. 拡張機能がコードを書くのをサポートしてくれる。- 初心者から上級者に至るまで拡張機能はコードを書くのに重宝します。プログラミングの目的に合った拡張機能を使用することで、効率よくコーディングをすることが可能です。</li>
        </ol>
        <br />
        <p>
          JavaScriptに適した拡張機能ももちろんあり、コードを書くのをサポートしてくれます。
        </p>
        <p>JavaScriptを書く際のおすすめの拡張機能を2つ、紹介します。</p>
        <ol>
          <li>・INTELLICODE - Microsoftの公式プラグイン。AI支援のインテリセンスが利用できるようになります。</li>
          <li>・JAVASCRIPT CODE SNIPPETS - JavaScriptのスニペットです。</li>
        </ol>
        <br />
        <p>※ インテリセンスとは？</p>
        <p>入力支援機能のことで、キー入力を監視し、何を入力しようとしているのかを推測して自動保管（オートコンプリート）したり、後続要素の候補を表示して選択するだけで入力できるようになります。</p>
        <p>※ スニペットとは？</p>
        <p>プログラミングの分野では、様々なプログラムに挿入して利用することができる、特定の機能を実現した短いコードのまとまりのことを指します。プログラミングでは様々なプログラムで似たようなコードを記述する場面は数多くあるため、よく使うコードスニペットとして使いまわすことで開発効率を向上することができます。</p>
        <br />
        <p>他にも様々な拡張機能があるので、自分でいろんな拡張機能を入れてみてください。</p>
        <h2>
          JavaScript
        </h2>
        <p>
          JavaScriptは、ChromeやFirefoxといった『ブラウザ上』で、動作するプログラミング言語です。
        </p>
        <p>対して、Pythonや、Rubyのような一般的なプログラミング言語は通常『パソコン上』で動作します。</p>
        <p>つまり、その言語で書いたプログラムがOSの機能へアクセスしてパソコン上のファイルを読み書きされたり、削除できるかが違います。</p>
        <p>ブラウザ上で動作するJavaScriptがOSの機能にアクセスできると、あるサイトにアクセスしただけでパソコン上のファイルを改変されてしまう可能性があるので、ユーザーにとっては嬉しくありません。ということで、JavaScriptはOSの機能にアクセス出来ないようになっています。</p>
        <br />
        <p>
          ブラウザがJavaScriptのプログラムをスクリーンに反映することで、webページ上で様々な体験ができ、
          JavaScriptは、先に書いたように、    <a href='http://www.ecma-international.org/'>ECMA International</a>
          が文法を決めていて、それを受けて、各社が、JavaScriptのコードを正しく読み込み、実行し、webページに反映できるブラウザを開発しています。
        </p>
        <p>
          ブラウザに設けられた開発者ツールには、「コンソール」と呼ばれる、プログラムの実行内容を確認できる機能があります。
        </p>
        <p>
          またファイルに書かれたコードがJavaScriptだと認識させるためには、そのファイルに必要な文字列を記入したり、ファイル名の拡張子を「.js」にするだけでよいです。
        </p>
        <br />
        <p>
          よって、JavaScript自体の基本的な学習は、Chromeなどのブラウザがあればできます。
        </p>
        <h2>
          Node.js
        </h2>
        <p>
          『ブラウザ上』でしか動作できなかったJavaScriptを、PythonやRubyのように『パソコン上』で動作できるようにしてくれるのが、Node.jsです。
        </p>
        <p>
          Node.jsはWebサーバでもなければRailsやDjangoのようなWebフレームワークでもありません。『JavaScript実行環境』です。
        </p>
        <p>Node.jsは元々、大量の同時接続を捌けるネットワークアプリケーションの構築を目的に設計されたJavaScript環境です。</p>
        <p>JavaScriptを用いたOSの機能にアクセスできるプログラムを組むことも出来ます。ですが現在では、Node.jsはサーバサイドのJavaScript実行環境や、クライアントサイドJavaScriptの開発環境としても利用されており、JavaScriptを用いたOSの機能にアクセスできるプログラムを組むことも出来ます。</p>
        <p>
          Node.jsのインストールは、<a href='https://nodejs.org/en/'>公式ページ</a>
          からデータをダウンロードして行うこともできますが、後々の開発でエラーを起こす原因となり得るので推奨しません。
        </p>
        <p>
          Node.jsは頻繁にアップデートされ、バージョンの違いなどがエラーの原因になることも多いです。
        </p>
        <p>
          nvm（Node Version Manager）を使用すると、Node.jsの各バージョンのインストールや切り替えをすることができるので、「nvmのインストール」→「Node.jsのインストール」という手順が適切です。
        </p>
        <p>
          Node.jsをインストールする際、自動的に、npmもインストールされます。
        </p>
        <p>
          npmは、Node.jsのパッケージ管理ツールで、Node.jsを使用して動く様々なソフトを管理してくれます。
        </p>
        <p>pythonにおけるpip、rubyのgem、macのhomebrewのようなものです。</p>
        <p>※ パッケージというのは、ライブラリやフレームワーク(VueやReact、webpack、jQuery）のことを指します。</p>
        <p>※ yarnはnpmと同じことができるものだと思って大丈夫です。
        </p>
        <br />
        <b>Node.jsの使用用途</b>
        <p>1. 新しい仕様のJavaScriptやTypeScriptでクライアントサイドのプログラムを書きたい</p>
        <p>2. Webアプリケーションを作りたい</p>
        <p>3. モバイル・デスクトップ用のアプリケーションを作りたい。</p>
        <h2>nvmのインストール方法</h2>
        <p>nvmのインストール方法にはgit,curl,homebrewのどれかを使用します。</p>
        <p>今回は、curlを使用して、インストールしてみましょう。</p>
        <p>まず、nvmをインストールする準備をしていきます</p>
        <p>ターミナルを開いてください。</p>
        <CodeBlock>
          {`
$ echo $SHELL
//「/bin/zsh」、 もしくは「/bin/bash」のどちらかが表示されると思います。

//「/bin/zsh」と表示された方は、
touch ~/.zshrc
//「/bin/bash」と表示された方は、
touch ~/.bashrc
//とコマンドを入力しましょう。
          `}
        </CodeBlock>
        <p>※ touchコマンドとは</p>
        <p>- ファイルの更新日時などのタイムスタンプを変更するコマンドですが、存在しないファイル名を指定することで、内容の入っていないファイルを新規作成する機能も持っています。</p>
        <br />
        <p>次に、nvmのgithubのページにアクセスしましょう。<a href='https://github.com/nvm-sh/nvm'>nvmのgithubページ</a></p>
        <p>ページを下にスクロールすると、以下のようなコマンドを見つけることができるでしょう。こちらをご自身のターミナルにコピペ、Enterを押下しましょう。</p>
        <CodeBlock>
          {`
curl -o- https://raw.githubusercontent.com/nvm-sh/nvm/v0.39.1/install.sh | bash
          `}
        </CodeBlock>
        <p>インストールが完了したら一度ターミナルを再起動する必要があります。一度ターミナルを閉じ、再度開きましょう。</p>
        <p>nvmがインストールされているか確認しましょう。以下のコマンドをターミナルに打ち込んでみましょう。</p>
        <CodeBlock>
          {`
$ command -v nvm
//「nvm」と表示されたら、nvmが正しくインストールされています
        `}
        </CodeBlock>
        <h2>nvmを使ってNode.jsをインストールしてみましょう。</h2>
        <p>nvmのインストールはできましたか？</p>
        <p>今度はnvmを使って、Node.jsをインストールしていきましょう。（またターミナルを使用します。起動してください。）</p>
        <p>Node.jsには様々なバージョンがあります。最新バージョンをインストールしても良いですが、バグが発生しやすいと言われています。今回は推奨バージョンをインストールしていきましょう。</p>
        <p>ターミナルで以下のコマンドを実行しましょう。Node.jsの推奨バージョンがインストールされます。</p>
        <CodeBlock>
          {`
$ nvm install --lts
          `}
        </CodeBlock>
        <p>※ --lts (long term support)の意味で推奨バージョンを指します。</p>
        <p>インストールできているか確認しましょう。以下のコマンドをターミナルで実行してください。</p>
        <CodeBlock>
          {`
$ node -v
          `}
        </CodeBlock>
        <p>Node.jsのバージョンを表す数字が表示されていたら、Node.jsのインストールが完了です。nvmを使用したnode.jsのインストールは以上になります。お疲れ様でした</p>
        <h2>
          CUI（Character User Interface）
        </h2>
        <p>
          キーボードのからの文字入力でコンピュータを操作する画面を「CUI」、マウスやタッチパットなどで操作できる画面のことを「GUI」と言います。</p>
        <p>
          CUIには、Macのターミナルや、Windowsのコマンドプロンプトなどがあり、
        </p>
        <p>
          一般のユーザーのPC操作では不要だが、高度な設定や、プログラミングを行う際に使用します。
        </p>
        <p>
          特定のコマンドを入力することで、そのコマンドに割り振られた行動をコンピュータに実行させることが可能で、
        </p>
        <p>
          コマンドによっては、コンピュータが、エラーも含めた実行結果などのメッセージを返します。
        </p>
        <p>
          CUIごとにコマンドが異なり、また、CUI内で使用するソフトによっても、コマンドが異なります。
        </p>
        <p>
          よって、コマンドの学習が不可欠になります。
        </p>
        <p>
          また、CUIでのコマンドは、作業ディレクトリで実行されます。
        </p>
        <p>
          ディレクトリとは、ファイルをまとめた一つのデータ、いわゆるフォルダのことで。
        </p>
        <p>
          全てのディレクトリとファイルにはコンピュータ上の住所（パス）があります。
        </p>
        <p>
          作業ディレクトリの変更などでは、このパスを使用します。
        </p>
        <h3>
          CUIのコマンドの例
        </h3>
        <ul>
          <li>
            node -v・・・インストールされているNode.jsのバージョンを表示
          </li>
          <li>
            npm -v・・・インストールされているnpmのバージョンを表示
          </li>
          <li>
            dir（windows）またはls -l（Mac）・・・作業ディレクトリ内にあるディレクトリを表示する。
          </li>
          <li>
            cd ディレクトリのパス・・・パスで指定したディレクトリに、作業ディレクトリを切り替える
          </li>
          <li>
            pwd・・・作業ディレクトリのパスを表示する。
          </li>
          <li>
            cd ..・・・一つ上の階層のディレクトリに移動する。
          </li>
        </ul>
        <h2>
          Node.jsのREPL(read-eval-print-loop)
        </h2>
        <p>
          CUIでnodeと入力すると、CUIでの入力方式がREPLという開発者向けのモードに切り替わります。
        </p>
        <p>
          このモードでは、通常の状態で入力できるコマンドが効きません。
        </p>
        <p>
          このモードから抜け出すには「control + C」を2回続けて押す必要があります。
        </p>
        <p>
          Nodeの使用方法については別の機会に扱います。
        </p>
        <br />
        <p>今回はここまでです。お疲れ様でした。</p>
      </LessonLayout>
    </div>
  )
}

export default withRouter(BrowserTerminalSetupComponent)
