import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DevToolsComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={12}
         title=' デバッグツール（ディベロッパーツール） ' />
        <p className='mt-5'>
          今回は、デバッグツール’(DevTools)についてご紹介したいと思います。
        </p>
        
        <p>
          DevToolsは、フロントエンド開発にはなくてはならないツールです。また、主流なブラウザに標準で搭載されており、ブラウザの処理を記録したり情報を取得したり、データを変更したりすることができます。
        </p>
        
        <h3>
          Devtoolsを使うメリット
        </h3>
        <p>
          ・ホームページの画面を見ながら編集できる。
        </p>
        <p>
          ・Webサイト、アプリケーションを効率的に開発できる。
        </p>
        <p>
          ・パフォーマンスチューニングを行う時、素早く問題を特定できる。
        </p>
        <h3>
          DevToolsの表示方法
        </h3>
        <p>
          Mac：option + command + i
        </p>
        <p>
          Windows：Ctrl + shift + i　もしくはF12
        </p>
        
        <p>
          または、Webサイト上で右クリック＞要素の検証からアクセス可能です。
        </p>
        <h3>
          DevToolsの各種機能
        </h3>
        <p>
          ・Element：WebサイトのDOMツリーの確認・編集。
        </p>
        <p>
          ・Console：HTML、CSS、JavaScriptのエラーや警告確認、ログ表示。
        </p>
        <p>
          ・Resources：画像、CSS、JavaScriptのリソースを確認。
        </p>
        <p>
          ・Network：JavaScriptや画像など、通信内容の確認。
        </p>
        <p>
          ・Performance：スクリプト(JavaScript)処理の種類と実行時間の測定。
        </p>
        <p>
          ・Memory：Webページのメモリ使用量の確認
        </p>
        
        <p>
          以上のようなパネル機能がありますが、ReactパネルやReduxパネルなどの拡張機能を追加することも可能です。
        </p>
        
        <p>
          まずは、テストを行うために2つのファイルを準備します。
        </p>
        
        <p>
          ・debugging.html
        </p>
        <CodeBlock>
          {`
<!DOCTYPE html>
  <html lang=”en”>

  <head>
    <meta charset=”UTF-8”>
    <meta name=”viewport” content=”width=device-width,initial-scale=1.0”>
    <title></title>
    <link rel=”stylesheet” href=”../../base.css”>
  </head>

  <body>
    <button class=”bigger”>Make Me Bigger</button>
    <script src=”./debugging.js></script>
  </body>
</html>
`}
        </CodeBlock>
        
        <p>
          ・debugging.js
        </p>
        
        <CodeBlock>
          {`
const people = [
  { name: ‘Tel’, cool:true, country: ‘Japan’},
  { name: ‘Mina’, cool:true, country: ‘China’},
  { name: ‘Fusa’’, cool:false, country: ‘Korea’},
];

people.forEach((person, index) => {
  console.log(person.name);
});
`}
        </CodeBlock>
        
        <p>
          準備ができましたら、Consoleメソッドという基本的なデバッグの方法からご紹介します。
        </p>
        <p>
          Consoleは、対話型のJavaScript実行環境です。ここでは、Console関数を用いて出力されるログやエラーを確認していきましょう。
        </p>
        
        <p>
          ・Console.error()
        </p>
        
        <CodeBlock>
          {`
people.forEach((person, index) => {
  console.error(person.name);
});
`}
        </CodeBlock>
        
        <p>
          console.errorを使うと、ChromeのConsole上での表記がエラー時のように赤で表示されます。
        </p>
        
        <p>
          ・Console.warn()
        </p>
        
        <CodeBlock>
          {`
people.forEach((person, index) => {
  if (person.name === ‘Tel’) {
  console.warn(‘Dumb name’);
  }
});
`}
        </CodeBlock>
        <p>
          実行結果
        </p>
        <p>
          Dumb name
        </p>
        
        <p>
          console.warnを使うと、ChromeのConsole上での表記が警告時のように黄色で表示されます。
        </p>
        
        <p>
          ・Console.table()
        </p>
        
        <CodeBlock>
          {`
console.table(people);
`}
        </CodeBlock>
        
        <p>
          実行結果
        </p>
        <div>
          <table className='border-2'>
            <tbody>
              <tr>
                <th className='border-2 px-1 py-1'>
                  (index)
                </th>
                <th className='border-2 px-1 py-1'>
                  name
                </th>
                <th className='border-2 px-1 py-1'>
                  cool
                </th>
                <th className='border-2 px-1 py-1'>
                  country
                </th>
              </tr>
              <tr>
                <td className='border-2 px-1 py-1'>
                  0
                </td>
                <td className='border-2 px-1 py-1'>
                  “Tel”
                </td>
                <td className='border-2 px-1 py-1'>
                  true
                </td>
                <td className='border-2 px-1 py-1'>
                  “Japan”
                </td>
              </tr>
              <tr>
                <td className='border-2 px-1 py-1'>
                  1
                </td>
                <td className='border-2 px-1 py-1'>
                  “Mina”
                </td>
                <td className='border-2 px-1 py-1'>
                  true
                </td>
                <td className='border-2 px-1 py-1'>
                  “China”
                </td>
              </tr>
              <tr>
                <td className='border-2 px-1 py-1'>
                  2
                </td>
                <td className='border-2 px-1 py-1'>
                  “Fusa”
                </td>
                <td className='border-2 px-1 py-1'>
                  false
                </td>
                <td className='border-2 px-1 py-1'>
                  “Korea”
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <p>
          console.tableを使うとConsole上でテーブルを表記してくれるので非常に便利です。
        </p>
        
        <p>
          ・Console.count()
        </p>
        
        <p>
          次に、console.countの例を示します。
        </p>
        <CodeBlock>
          {`
function doctorize(name) {
  console.count(‘running Doctorize’);
  return \`Dr. \${name}\`;
  }  
`}
        </CodeBlock>
        
        <p>
          実行結果
        </p>
        <p>
          Consoleにて
        </p>
        <p>
          &gt; doctorize(‘Tel’);
        </p>
        <p>
          running Doctorize: 1
        </p>
        <p>
          &gt; doctorize(‘Tel’);
        </p>
        <p>
          running Doctorize: 2
        </p>
        <p>
          &gt; doctorize(‘Tel’);
        </p>
        <p>
          running Doctorize: 3
        </p>
        
        <p>
          以上のようにconsole.countを使用する事で、何回実行されたかがカウントされるので、非常に便利です。
        </p>
        
        <p>
          ・Console.group()
        </p>
        
        <p>
          それでは、Consoleのグループ化についてです。
        </p>
        <CodeBlock>
          {`
function doAlotOfStuff() {
  console.group(‘Doing some stuff’);
  console.log(‘Hey Im one’);
  console.warn(‘watch out”);
  console.error(‘hey’);
  console.groupEnd(‘Doing some stuff);
}
`}
        </CodeBlock>
        
        <p>
          &gt;doAlotOfStuff()
        </p>
        <p>
          実行結果
        </p>
        <p>
          ▼Doing some stuff
        </p>
        <p>
          | Hey Im one
        </p>
        <p>
          | watch out!
        </p>
        <p>
          | hey
        </p>
        
        <p>
          このように、console.groupを最初に、console.groupEndを最後に書くことでグループ化でき、console.logがかなり見やすくなりました。
        </p>
        
        <p>
          また、以下のようにconsole.groupCollapsedを使えば、要素が収納された状態で表示され、クリックすると開いて要素が見えるようになるので、スッキリ表示させたいときは便利です。
        </p>
        
        <CodeBlock>
          {`
console.groupCollapsed(‘Doing some stuff’);
`}
        </CodeBlock>
        
        <p>
          &gt;doAlotOfStuff()
        </p>
        <p>
          実行結果
        </p>
        <p>
          ▼Doing some stuff
        </p>
        <div>
          
        </div>
        <h2 className='text-2xl mt-6 mb-4'>
          スタックトレース（callstack, Stack Trace）を使用したデバッグ方法
        </h2>
        <p>
          スタックトレースとは、プログラムの実行過程の事です。スタックトレースを確認する事で、どの関数がどのような順番に呼び出されているのか、どこにエラーが起きているのかをチェックすることができます。以下に例を示します。
        </p>
        
        <CodeBlock>
          {`
function doctorize(name) {
  return \`Dr. \${name}\`;
}

function greet(name) {
  doesntExist(); // エラーの原因
  return \`Hello \${name}\`;
}

function go() {
  const name = doctorize(greet(‘Tel’));
  console.log(name);
}

function bootstrap() {
  console.log(‘Starting the app!’);
  go();
}
`}
        </CodeBlock>
        
        <p>
          Consoleにて
        </p>
        <p>
          &gt;bootstrap()
        </p>
        <p>
          実行結果
        </p>
        <CodeBlock>
          {`
Starting the app!
× Uncaught ReferenceError: doesntExist is not defined
at greet (debugging.js.;47)
at go (debugging.js.;52)
at bootstrap (debugging.js.;58)
at <anonymous>:1:1
`}
        </CodeBlock>
        
        <p>
          以上のように、エラーの原因となっているdoesntExist関数と、それが格納されているgreet関数、greet関数が呼び出されているgo関数、go関数を呼び出したbootstrap関数、anonymousはConsoleから実行されたという意味になります。つまり、関数が実行される順番によって表示されているのが確認できました。
        </p>
        
        <h2 className='text-2xl mt-6 mb-4'>
          要素の取得（Grabbing Elements）
        </h2>
        <p>
          では、次に要素（Elements）の取得方法について学びましょう。
        </p>
        <p>
          Elementsは、WebサイトのDOMツリーの確認・編集を行うことができます。
        </p>
        <p>
          ここでは、実際にYahooのサイトで説明いたします。
        </p>
        
        <p>
          <img src='https://lh5.googleusercontent.com/6OoiFDaXomvV8gtvsjX62ASuf2v66UnU9lpCgW3vo1agcJqLWthrYT3k4nehT8mPRcnH2pGFdtlMHAUbuIakW9prPapQCES8nGvMlNbjaU6ccQNJNXHjus_UIXGnkwWIYK3b7K7E'
        width='680'
        height='329' 
        alt='console1' />
        </p>
        <p className='mt-4'>
          Yahooの検索ページで、検索欄にカーソルをクリックした状態です。
        </p>
        <p>
          また、右側にはDev ToolのElementsを開いており、クリックした箇所の要素がElements上で示されているのが確認できます。
        </p>
        
        <p>
          <img src='https://lh3.googleusercontent.com/Aw1jfNg1MeMn8OqzRFSj7bSDfnD-zFAyNTS6zIkrgRYjVHSiJxSbEY1f5lgDwgSUkB_nmf7LZGSp-FAK3V-kY2DhF2c6HXtfMCngVw0RwtFjyyvuzXZm8Cp_sLEMgoCSX7L5fl9w'
        width='680'
        height='240'
        alt='console2' />
        </p>
        
        <p>
          次に、そのままクリックした状態でElementからConsoleに移動してみましょう。
        </p>
        
        <h3>
          コマンドラインAPI
        </h3>
        <p>
          
          ここで、コマンドラインAPIという、ディベロッパーツールのConsoleで使用可能な関数の使用方法をご紹介します。コマンドラインAPIを使うことで、自分が知りたい関数などのソースコードをダイレクトに表示することができます。
        </p>
        <p>
          <a className='' href='https://developers.google.com/web/tools/chrome-devtools/console/utilities'>
            https://developers.google.com/web/tools/chrome-devtools/console/utilities
          </a>
        </p>
        
        
        <p>
          ・$0
        </p>
        <p>
          では、そのままの状態で、$0を入力してみましょう。
        </p>
        <p>
          そうすると、左の画面でクリックされた要素だけが取得できたかと思います。
        </p>
        
        
        <p>
          ・$0.value
        </p>
        <p>
          では、&amp;0.valueを入力してみると、現在検索欄で入力した”tel”という文字列の値が返ってきました。
        </p>
        
        <p>
          ・$1, $2~
        </p>
        <p>
          また、$1を入力してみましょう。
        </p>
        <p>
          この場合、$0がクリックされる前に選択された要素が実行結果として返ってきます。また、$2,$3～も使用可能です。
        </p>
        
        <p>
          以上のように、コマンドラインAPIを使用する事で、簡単に要素が取得できました。今回は、選択した要素を取得する方法を示しましたが、以下では、自分が欲しい要素を取得する方法を説明をします。
        </p>
        
        <p>
          ・$(selector)
        </p>
        <p>
          この関数は、document.querySelector（）
          関数のエイリアスです。使い方としては、$(selector)で指定されたselectorを持つ、最初のDOM要素への参照を返します。
        </p>
        
        <p>
          例を示すと、selectorとしてpタグを代入しており、ウェブページの最初のpタグの要素をひとつ返します。
        </p>
        
        <p>
          $(‘p’)
        </p>
        
        <p>
          実行結果
        </p>
        <p>
          &lt;p&gt;～&lt;/p&gt;
        </p>
        
        <p>
          ・$$(selector)
        </p>
        <p>
          このコマンドは、document.querySelectorAll（）を呼び出すのと同じです。この場合、NodeListではなく、配列を返します。例えば、上記の$(‘p’)を代入したときは、要素が一つしか返ってきませんでしたが、$$(‘p’)を使用する事でページ内の全てのpタグの要素が配列として返ってきます。
        </p>
        
        <h2 className='text-2xl mt-6 mb-4'>
          ブレークポイント（Breakpoints）
        </h2>
        <p>
          ブレークポイントを利用することで、プログラムの実行を任意の場所で一時停止し、停止した場所の変数の状態やコールスタック（関数の呼び出し経路）を確認したり、停止した場所からコードの実行を一つ一つ自分で進めることができます。
        </p>
        
        <p>
          まずは、コード内からdebuggerが呼び出されて、ブレークポイントが配置された行で一時停止する場合を見てみましょう。
        </p>
        
        <p>
          ・debugging.js
        </p>
        <CodeBlock>
          {`
const people = [
  { name: ‘Tel’, cool:true, country: ‘Japan’},
  { name: ‘Mina’, cool:true, country: ‘China’},
  { name: ‘Fusa’’, cool:false, country: ‘Korea’},
];

people.forEach((person, index) => {
  debugger;
  console.log(person.name);
});
`}
        </CodeBlock>
        

        <p>
          以上のように、コード間にdebuger;を挿入してみると、ConsoleのSources欄からdebuggerの場所に移動します。
        </p>
        
        <p>
          <img src='https://lh6.googleusercontent.com/hfh_2LnXlaGqd9LvLMkkRnsN0zKIsW8hvOmqkoV9NuF6ycbT2QtKaTgmlLtFQcL91uJFV_0QS18L0JMo8D0ddCL-2wQ0SJ8OIBJJCehY-xU968A-KHFQ2iHOBhSZpqe2-C-5uEYE'
        width='601'
        height='322'
        alt='console3' />
        </p>
        
        <p>
          では、Sourcesのサイドバーの主な機能の説明をします。
        </p>
        
        
        <p className='font-bold'>
          Call Stack（コールスタック）：
        </p>
        <p>
          ブレーク時の処理の呼び出し階層を確認できます。
        </p>
        
        <p className='font-bold'>
          Scope（スコープ）：
        </p>
        <p>
          ブレーク時に実行中のスコープにある変数とその値を確認できます。
        </p>
        
        <p className='font-bold'>
          Resume（リジューム実行）：
        </p>
        <p>
          青い矢印のResumeボタンを使用する事で、実行を再開して次のブレークポイントまで進みます。ブレークポイントが見つからない場合は、通常の実行が再開されます。
        </p>
        
        <p className='font-bold'>
          Long Resume（ステップオーバー）：
        </p>
        <p>
          黒い矢印のLong Resumeは、debuger以下のコードを一行ずつ実行し、一時停止することができます。
        </p>
        <p>
          展開してアクションが表示されるまで [Resume] をクリックし続けます。
        </p>
        
        <p>
          使い方としては、以上の例のようにコード内にdebugger;を設置し、一時停止した場所からステップオーバーを一つずつ実行していくことによって、右側のScope内に実行されたコードに応じて値が示され、実行の流れを確認し、エラーが出る箇所を特定します。
        </p>
        
        <p>
          また、今回はコード内にdebugger;を設置しましたが、DevTools内のSourcesのPageからJSファイルを選択し、JSファイルの任意のラインナンバーをクリックすると、そこにブレークポイントを設置することができます。
        </p>
        
        <p>
          <img src='https://lh4.googleusercontent.com/oJmkt2OuvYhwsdhf2uewtg5GGagEVguh8LRiKhcrBBnsZsA8noSRYpQmUkql8CkosmKNsXHZB9y29N2D5P5N0lHQ8oEjDURB6FTDLj0mCqLZp_OdXZu4ZvODrMU5RSS1k5GjH9Wv'
        width='680'
        height='232'
        alt='console4' />
        </p>
        
        <p>
          以上、二通りのブレークポイントについてご紹介しましたが、コード内にdebudder;を挿入する場合、デバッグ後に消し忘れないようにしましょうね。
        </p>
        <div>
          
        </div>
        <h2 className='text-2xl mt-6 mb-4'>
          Network
        </h2>
        <p>
          <img src='https://lh5.googleusercontent.com/KNTYZdFQVKM8GlYibhXlogIKRDUbOKJeu__B9NbUDvRHjHfHOSz1eutk4iK3Ytl6Jfz6FY7nWcChYig-FW6bqg7jhqze4d-4ICuOD1QeUKMylaf5bOuI0gvDR5ZWMgaqc2frbjDd'
        width='680'
        height='328'
        alt='console5' />
        </p>
        <p>
          次に、DevToolsのNetworkについて見ていきましょう。
        </p>
        
        <p>
          NetworkはJavaScriptや画像など、通信内容の確認するができます。また、ページのリクエストをしてからの通信内容を一覧で表示できるため、ページ表示が遅くなっている原因を特定するのに非常に便利です。
        </p>
        
        <p>
          例として、appleのホームページを参照していきましょう。
        </p>
        
        <p>
          では、DevToolsのNetworkを開き、JSファイルを選択すると、以下の項目が表示されます。
        </p>
        
        <p>
          ・Headers
        </p>
        <p>
          ・Preview
        </p>
        <p>
          ・Response
        </p>
        <p>
          ・Initiator
        </p>
        <p>
          ・Timing
        </p>
        <p>
          ・Cookies
        </p>
        
        <p>
          主な機能をご紹介します。
        </p>
        
        <p>
          ・Hearder：リクエストヘッデャやレスポンスヘッダ、クエリ文字列の確認。
        </p>
        <p>
          ・Response：レスポンス本文の確認。
        </p>
        <p>
          ・Cookies：リクエスト／レスポンスで使用されたクッキーを確認。
        </p>
        <p>
          ・Timing：各項目でどれだけ時間がかかっているのかを確認。
        </p>
        <p>
          ・Preview：テキストデータならレスポンスのテキスト、画像ファイルなら画像の情報が表示され、JSONなどのAPIレスポンスが整理された状態での確認。
        </p>
        <h3>
          DOM Breakpoints
        </h3>
        <p>
          例えば、ウェブページ上からボタンなどのソースがどこにあるのかを調べられたら便利だと思いませんか？もし、この機能がなければ、自分の何千とういうソースコードを一つ一つ確認していく羽目になります。そんな時に使えるのが、attribute
          modificationです。
        </p>
        
        <p>
          例えば、あるボタンのJavaScriptのソースコードがどこにあるのか調べたいときは、以下の手順で確認可能です。
        </p>
        
        <ol>
          <li>
            <p>
              １．DevToolsのSelect an element in the page to inspect it(Ctrl + shift +
              c)をクリック
            </p>
          </li>
          <li>
            <p>
              ２．Webページの任意のボタンの要素をクリック
            </p>
          </li>
          <li>
            <p>
              ３．Elementsに移動し、選択したコード上で右クリックからBreak onを選択
            </p>
          </li>
          <li>
            <p>
              ４．attribute modificationsを選択
            </p>
          </li>
          <li>
            <p>
              ５．Webページのボタンの要素をクリック
            </p>
          </li>
        </ol>
        
        <p>
          <img src='https://lh4.googleusercontent.com/IBvbk9wTlbdLuqlht2DFbGcSFROCAex8NaheL5Zjz5Vw3HsPEhsqryl8d-D26Z_hUc1ow4l6wKgEawVi32jJH1zPvSx9wBPMM9OOAPeKUp2uADipwkPcYqHDjVk6B9aYrXG-M5Lm'
        width='680'
        height='289' 
        alt='console6' />
        </p>
        <p>
          (引用ページ：
          <a a className='text-blue-900' href='https://qiita.com/vent/items/497cbd9f9f5faa965100'>
            https://qiita.com/vent/items/497cbd9f9f5faa965100
          </a>)
        </p>
        
        <p>
          そうすると、DevToolsのElementsまたはSourcesに移動し、ボタンが記載されたソースコードに飛ぶことができます。
        </p>
        <p>
          また、残りの二つのトリガーについても下記に示しますので、参考にしてください。
        </p>
        
        <p>
          ・subtree modifications（小要素の追加、削除、変更時にブレーク）
        </p>
        <p>
          ・attribute modifications（属性の変更（）class, id, name時にブレーク）
        </p>
        <p>
          ・node removal（要素の削除時にブレーク）
        </p>
        
        <h3>
          XHR/fetch Breakpoints
        </h3>
        
        <p>
          では、ScopeやCall Stack以外にもう一つ、XHR/fetch ブレークポイントについて紹介します。
        </p>
        
        <p>
          XHR/fetch ブレークポイントは、指定された文字列が XHR/fetch のリクエストURLに含まれており、一時停止する場合に使用します。
        </p>
        <h3>
          <img src='https://lh4.googleusercontent.com/PV8sCuvSd7TffBP8Zf_yh0wNnPxwmIiYq4o2j9MfpB52vuacmzcN3oTNmIaR16jDsrMOJPhcHTrCSQcbmnr5yrKea-YSoH49wFJYF6hFwaJb4eMMaYFB-sh56g7hVHtdHT-vOozJ'
        width='680'
        height='327' 
        alt='console7' />
        </h3>
        
        <h3>
          XHR(XMLHttpRequest)：
        </h3>
        <p>
          Ajax（非同期通信）で使われる組み込みオブジェクトであり、JavaScriptを使ってブラウザ上でサーバとHTTP通信を行うためのAPIです。
        </p>
        <p>
          サーバから一度すべてのデータを受信したwebページから、さらにサーバへ通信リクエストが送ることができるので、ページをリロードせずにページ内容の書き換えが可能ですとなります。
        </p>
        
        <h3>
          fetch API：
        </h3>
        <p>
          ・fetchはPromiseをベースとしたAPIで、リクエストやレスポンスといったHTTPのパイプラインを構成する要素を操作できるようになり、fetch()メソッドを使用する事で、非同期通信を分かりやすく記述できます。また、XHRに比べてシンプルでモダンなAPIです。
        </p>
        
        
        <p>
          使用方法ですが、DevToolsのXHR/fetch BreakpointsでAny XHR or fetchにチェックします。
        </p>
        
        <p>
          そして、以下のfetch()メソッドの関数をConsoleで実行してみます。
        </p>
        
        <CodeBlock>
          {`
async function fetchDadJoke() {
  const res = await fetch(‘https://icanhazdadjoke.com/’, {
    headers: {
    Accept: ‘text/plain’,
    },
});
};
`}
        </CodeBlock>
        
        <p>
          すると、Sources上のfetch関数である、{'const res = await fetch("https://icanhazdadjoke.com/", {}'}
          で一時停止することができました。
        </p>
        
        <p>
          このように、基本的にはどんなタイプのブレークポイントを設定することができるので、必要に応じて使えるようになりましょう。
        </p>
        
        <p>
          以上、今回はDevToolsの使用方法について解説してきましたが、いかがだったでしょうか。
        </p>
        <p>
          最後までお読みいただきありがとうございました。
        </p>
        
      </LessonLayout>
    </div>
  )
}

export default withRouter(DevToolsComponent)