import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const FunctionsComponent = ({ history }) => {
  return (
    <div> <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={12}
       title=' JavaScriptの関数 ' />
        <p>今回は、関数(function)について学んでいきましょう。</p>
        <h3>関数とは何か？</h3>
        <p>タスクや値計算を実行する文の集まりで、複雑で長いプログラムを書く時に役立ちます。</p>
        <h3>関数を使うメリット</h3>
        <ul>
          <li>・何回も使いまわせるので、コードの量が減る。</li>
          <li>・名前を付けることでコードが何をしているのか可視化できるので、見通しが良くなる。</li>
        </ul>
        <br />
        <p>それでは早速、イメージを掴むためにChromeのConsoleで、関数を試してみよう。</p>
        <h2>関数にさわってみよう！</h2>
        <article>
          <CodeBlock>
            {`
Math.max(10, 12)
< 12        
`}
          </CodeBlock>
          <p>maxは一番大きな値をとる関数なので、12が返ってきました。</p>
          <p>ここで、関数内にある10, 12のことを引数（argument）と呼びます。</p>
          <CodeBlock>
            {`
Math.floor(2.444)
< 2        
`}
          </CodeBlock>
          <p>Max.floorは切り捨てを行う関数です。</p>
          <CodeBlock>
            {`
parseFloat('20.234324')
< 20.234324        
`}
          </CodeBlock>
          <p>parseFloat() 関数は、引数を解釈して浮動小数点値を返します。</p>
          <CodeBlock>
            {`
parseInt('20.341234')
< 20        
`}
          </CodeBlock>
          <p>parseInt()関数は、引数を解釈して整数を返します。</p>
          <CodeBlock>
            {`
Date.now()
< 15626823        
`}
          </CodeBlock>
          <p>Date.now() 関数は、UTC (協定世界時) での 1970 年 1 月 1 日 0 時 0 分 0 秒 から現在までの経過時間をミリ秒単位で返します。</p>
          <p>変換するにはEpoch converter<a className='text-blue-900' href='https://epoch.now.sh/'>(https://epoch.now.sh/)</a>が便利です。</p>
          <p>次に、htmlファイルで関数を使ってみましょう。</p>
          <iframe src='https://stackblitz.com/edit/js-mschun?embed=1&file=index.html' title='巻き上げExample' style={{ width: '100%', height: '400px' }}></iframe>
          <p>実行結果</p>
          <p>{'<p>Hey How ya doin?</p>'}</p>
          <p>定数pのDocument の querySelector() メソッドは、指定されたセレクターまたはセレクターのグループに一致する、文書内の最初のp要素を返します。</p>
          <br />
          <p>それでは、次にWindow.scrollTo()関数です。</p>
          <CodeBlock>
            {`
window.scrollTo(0, 200)         
`}
          </CodeBlock>
          <p>Window.scrollTo(x軸, y軸) は文書内の特定の組み合わせの座標までスクロールします。</p>
          <p>つまり、この場合y軸が200の場所に移動し、戻り値はありません。</p>
          <p>また、</p>
          <CodeBlock>
            {`
scrollTo({ top: 500, left:0, behavior: 'smooth'});        
`}
          </CodeBlock>
          <p>というように、Object型の値を入れることもでき、behaviorをsmoothにすることでスムーススクロールを手軽に実装することができます。</p>
          <p>このように数多くの関数がありますので、詳細も調べるには<a className='text-blue-900 whitespace-pre-wrap' href='https://developer.mozilla.org/ja/docs/Web/API/Window/scrollTo'>MDN</a>で調べると良いでしょう。</p>
        </article>
        <h2>関数を書いてみよう！</h2>
        <article>
          <p>ここからは、実際に関数の書き方について見ていきましょう。</p>
          <CodeBlock>
            {`
function 関数名（仮引数1, 仮引数2）{
  // 処理
  return 関数の返り値;
}          
`}
          </CodeBlock>
          <p>次に、関数の呼び出し方（使用方法）です。</p>
          <CodeBlock>
            {`
関数名();        
`}
          </CodeBlock>
          <h3>関数呼び出しの（）のありなしの違いとは？</h3>
          <p>ちなみに、関数を呼び出す際、後ろのカッコがあると関数の中の処理の呼び出しになり、カッコがあれば処理として、カッコがなければ値として、使い分けることができます。</p>
          <p>また、関数名として一般的に動詞が良く使われます。</p>
          <br />
          <p>例）calculate, add, is </p>
          <p>また、is...ableで...ができるか？を意味する関数名で、これもよく使われます。</p>
          <p>例）isCalculatable, isChangeable</p>
          <p>注意点として、returnの後に書かれたコードは実行されません。</p>
          <p>では、実際に以下のIDEのConsoleを開き、実行結果を確認してみましょう。</p>
          <iframe src='https://stackblitz.com/edit/js-ngc7gg?embed=1&file=index.js' title='巻き上げExample' style={{ width: '100%', height: '400px' }}></iframe>
          <br />
          <p>実際にcalculateBill()を呼び出す際に、()のありなしで実行結果が変わったことが確認できたのではないでしょうか？</p>
          <h3>ローカル変数とブロックスコープ</h3>
          <p>また、この例では関数の中に変数が作られています。関数内で作られた変数をローカル変数と呼び、関数のブロック内でのみ有効です。そのため、関数外でローカル変数を呼び出しても使うことができません。</p>
          <p>また、Console上ではreturn を追加する事でundefinedではなく、関数の返り値が返ってくるようになります。</p>
          <p>ですので、Consoleで定数totalを実行しても値は返ってきません。これは、ブロックスコープと呼ばれるものです。</p>
          <p>ブロックスコープとは、ブロック {'{}'} ごとに作られるスコープで、ブロックスコープ内で作られたlet, constなどの定数はブロックの内側でのみ使用可能であり、アクセス可能となります。</p>
          <CodeBlock>
            {`
console.log(\`Your total is \${calculateBill()}\`);        
`}
          </CodeBlock>
          <p>このように、関数をそのまま入れても同じ実行結果が返ってきます。</p>
        </article>
        <h2>関数の引数について</h2>
        <article>
          <p>では、もう一つ、税率を含めて商品単価を計算する関数を作ってみます。</p>
          
          <CodeBlock>
            {`
let bill = 100;
const taxRate = 0.13;

function calculateBill2() {
  const total = bill * 1 + taxRate;
  return total;
}

const myTotal2 = calculateBill2();
bill = 200;
const myTotal3 = calculateBill2();
console.log('myTotal2, myTotal3:', myTotal2, myTotal3);

実行結果
100.13 200.13 
`}
          </CodeBlock>
          
          <p>
            ここでは、グローバルスコープから変数（bill, taxRate）の値が直接入っています。
          </p>
          
          <p>
            では、商品の値段や税率の定数を毎回宣言せずに、関数に値を直接渡したい場合はどのようにすればよいでしょうか？
          </p>
          <p>この時に、引数(parameter)を使うと非常に便利です。</p>
          
          <CodeBlock>
            {`
function calculateBill3(billAmount, taxRate) {
  const total = billAmount * (1 + taxRate);
  return total;
}

const myTotal4 = calculateBill3(100, 0.13);
const myTotal5 = calculateBill3(200, 0.13);

実行結果
100.13 200.13 
`}
          </CodeBlock>
          
          <p>
            関数calculateBill()を呼び出すときに、引数100と0.13を渡しています。こうする事で、一つずつ定数を宣言する手間が省けます。
          </p>
          
          <p>
            ここまで、引数には直接値が入力されていました。しかし、引数に定数が入る場合もあります。複雑だと感じるかもしれませんが、ゆっくりと確認していきましょう。またここでは関数式も用いられています。
          </p>
          <p>
            複雑だと感じるかもしれませんが、ゆっくりと確認していきましょう。またここでは関数式も用いられています。
          </p>
          <h3>関数式について</h3>
          <p>
            関数式：関数を変数の値として利用するもの。
          </p>
          
          <CodeBlock>
            {`
const telTotal = 500;
const telTaxRate = 0.3;
const myTotal= calculateBill(telTotal, telTaxRate);        

実行結果
112.999999999999
`}
          </CodeBlock>
          
          <p>
            これは、const myTotal= calculateBill(500,
            0.3);と同じになります。また、定数myTotalに関数calculateBillが代入されているような状態で、これが関数式と呼ばれるものです。
          </p>
          
          <p>
            別の例を見てみましょう。
          </p>
          
          <CodeBlock>
            {`
function sayHiTo(firstName) {
  return \`Hello \${firstName}\`;
}

const greeting = sayHiTo('Tel');
console.log(greeting); 

実行結果
Hello tel
`}
          </CodeBlock>
          
          <p>
            ここでは、引数TelがsayHiTo関数のfirstNameに代入され、戻り値（Hello tel）が与えられています。
          </p>
          
          <p>
            また、関数の引数に以下のような計算も代入可能です。
          </p>
          
          <CodeBlock>
            {`
const myTotal = calculateBill(20 + 20 + 30 + 20, 0.15);

実行結果
103.499999
`}
          </CodeBlock>
          
          <p>
            問題なく実行できます。
          </p>
          
          <p>
            それでは、関数を呼び出す際に、引数の中に関数を入れた場合はどうでしょうか？
          </p>
          
          <iframe src='https://stackblitz.com/edit/js-v5k7w4?embed=1&file=index.js' title='巻き上げExample' style={{ width: '100%', height: '400px' }}></iframe>
          <CodeBlock>
            {`
実行結果
HEY DR. TEL
`}
          </CodeBlock>
          
          <p>
            ここでは、まずdoctorizeに引数telが渡され、戻り値（Dr.
            tel）が返ってきます。その後、yell関数のtoUpperCase関数（大文字に変換して）から得られた値が実行結果となります。
          </p>
          
          <p>
            また、引数に値を入れ忘れた時はエラーが起きてしまうので、デフォルト値を設定しましょう。
          </p>
          <p>
            デフォルトを設定すると、その分の引数を省略して渡すことができます。デフォルトは以下のように（引数
            = 'デフォルト値')と設定します。
          </p>
          
          <CodeBlock>
            {`
function yell(name = 'Silly Goose') {
  return \`HEY \${name.toUpperCase()}\`;
}
console.log(yell());

実行結果
HEY SILLY GOOSE
`}
          </CodeBlock>
          
          <p>
            また、以下の場合、引数が3つあり、taxRateとtipRateにデフォルト値を設定しています。
          </p>
          
          <CodeBlock>
            {`
function calculateBill(billAmount, taxRate = 0.13, tipRate = 0.15) {
  const total = billAmount + (billAmount * taxRate) + (billAmount * tipRate);
  return total;
}

const myBill4 = calculateBill(100, undefined, 0.2);
console.log(myBill4);

実行結果
133
`}
          </CodeBlock>
          <p>
            デフォルトを設定していると言っても、空白（100, , 0.2）にすると、Argument expression expected.というエラーが出るので、引数にundefinedを入れるようにしましょう。
          </p>
          <div>
          </div>
        </article>
        <h2>関数宣言の種類について</h2>
        <article>
          <p>
            JavaScriptの関数はある種の型のように、値として扱うことができます。
          </p>
          <p>
            このように、数値や文字列のような、変数に割り当てられたり、なんらかの計算処理ができたりといった、プログラミングの基本機能が使える対象のことを「第一級オブジェクト」と言います。
          </p>
          
          <p>
            関数を宣言するためには、
          </p>
          
          <p>
            ・匿名関数（無名関数）
          </p>
          <p>
            ・アロー関数
          </p>
          <p>
            ・即時実行関数式
          </p>
          <p>
            ・コールバック関数
          </p>
          
          <p>
            以上、4つの方法がありますので、それぞれ学んでいきましょう。
          </p>
          
          <p>
            まずは、例を示すために、ways-to-make-a-function.jsファイルを作ります。
          </p>
          <h3>匿名関数（無名関数）：</h3>
          <p>
            匿名関数とは、関数名がついてない関数です。名前がなくても関数として機能します。一度しか使わない「その場限りの関数」なので、あえて名前を付ける必要もありません。
          </p>
          
          <p>
            例を示します。
          </p>
          
          <CodeBlock>
            {`
const doctorize2 = function (firstName) {
  return \`Dr. \${firstName}\`;
};

console.log('doctorize2', doctorize2('tel'));

Consoleにてdoctorize('tel')の実行結果
Dr. tel
`}
          </CodeBlock>
          <p>
            この場合、定数doctorizeに無名関数が代入されています。
          </p>
          <p>
            ただし、無名関数はあまりお勧めできないこともあります。なぜなら、エラーが起きた時にどこで問題が発生しているか分からないことがあるからです。
          </p>
        </article>
        <h3>
          JavaScriptの巻き上げについて
        </h3>
        <p>
          前回ご紹介したように、JavaScriptはwhistle（巻き上げ）という機能を持ちます。
        </p>
        <CodeBlock>
          {`
console.log(doctorize3('Tel'));
console.log(doctorize4('Tel'));

const doctorize3 = function (firstName) {
  return \`Dr. \${firstName}\`;
};

function doctorize4 (firstName) {
  return \`Dr. \${firstName}\`;
};
`}
        </CodeBlock>
        <p>
          一つ目の関数式を用いたdoctorize3の実行結果はError: Cannot access 'doctorize3' before initializationというエラーになります。</p>
        <p>
          二つ目の関数doctorize4ではDr. Tel
          という値が返ってきます。
        </p>
        <br />
        <p>
          その理由がJavaScriptの巻き上げ（whitsle）という機能になります。</p>
        <p>実際にはdoctorize4関数がconsole.logの上に移動する動きになるのです。また、doctorizeも実際には巻き上げられるのですが、正しくは、変数宣言だけが巻き上げられて、値は巻き上げられないからです。つまり、以下のようになるのです。
        </p>
        
        <CodeBlock>
          {`
const doctorize3 ;

function doctorize4 (firstName) {
  return \`Dr. \${firstName}\`;
};

console.log(doctorize3('Tel'));
console.log(doctorize4('Tel'));

doctorize3 = function(firstName) {
  return \`Dr. \${firstName}\`;
};
`}
        </CodeBlock>
        <p>
          それでは、次にアロー関数について確認していきましょう。
        </p>
        <h3>
          アロー関数
        </h3>
        <p>
          アロー関数はES6から使えるようになったJavaScriptの構文の一つです。</p>
        <p>ES5で関数を作る場合はfunction文を使いますが、今後は「アロー関数」が主流になると言われており、無名関数の省略記法です。アロー関数（=&gt;）を用いることでコードを省略することができます。
        </p>
        <p>
          では、省略の手順を順番に見ていきましょう。
        </p>
        
        <CodeBlock>
          {`
function inchToCM(inches) {
  const cm = inches * 2.54;
  return cm;
}       
`}
        </CodeBlock>
        <p>
          では、関数式を用いて、cmの定数宣言を省略し、4行から3行に短くします。
        </p>
        <CodeBlock>
          {`
const inchToCM2 = function (inches) {
  return inches * 2.54;
};         
`}
        </CodeBlock>
        <p>
          functionとreturnを省略して2行から1行に短くしました。
        </p>
        <CodeBlock>
          {`
const inchToCM3 = inches => inches * 2.54;
`}
        </CodeBlock>
        <p>
          以上、アロー関数を使うことで、4行から1行にコードを省略することができました。
        </p>
        <p>
          ちなみに、引数（Parameter）のカッコ()は引数が一つだと書かなくても問題ありません。
        </p>
        <p>
          別の例を見てみましょう。
        </p>
        <CodeBlock>
          {`
function add(a, b = 3) {
  const total = a + b;
  return total;
}

const add2 = (a, b = 3) => a + b;    
`}
        </CodeBlock>
        <p>
          この場合、引数（parameter）が二つあるのでカッコ（）は必要となります。
        </p>
        <h3>
          オブジェクトを使った関数
        </h3>
        <p>
          次に、オブジェクト型を返す例を示します。関数にオブジェクトを使うことで、複数のデータを記録する便利な入れ物として使うことができます。
        </p>
        <p>
          オブジェクトの取り出し方は、以下の2種類です。
        </p>
        <p>
          ・変数名.プロパティ
        </p>
        <p>
          ・変数名['プロパティ']
        </p>
        <iframe src='https://stackblitz.com/edit/js-q5w5r7?embed=1&file=index.js' title='巻き上げExample' style={{ width: '100%', height: '400px' }}></iframe>
        <CodeBlock>
          {`
  実行結果
  age: 0
  name: “min tel”
  _proto_: object          
  `}
        </CodeBlock>
        <p>
          オブジェクト型の値を得ることことを確認することができました。では、これをアロー関数を用いて一行で表現してみましょう。
        </p>
        <CodeBlock>
          {`
const makeABaby = (first, last) => ({ name: \`\${first} \${last}\`, age: 0});        
`}
        </CodeBlock>
        <p>
          以上のようにスッキリと表現することができましたが、時間が経った時に見直すと若干分かりにくいので、オブジェクト型の関数では最初の方が分かりやすいかもしれません。
        </p>
        <h3>
          即時実行関数（IIFE：Immediately Invoked Function Expression）
        </h3>
        <p>
          即時実行関数は、定義されるとすぐに実行されるJavaScriptの関数のことです。</p>
        <p>即時実行関数を使うメリットとして、変数宣言を省略することができるので、変数が書き換えられたりしてエラーとなる要因を減らすことができます。また、プログラムのスコープを適切に制御する事ができます。
        </p>
        <br />
        <p>
          書き方：
        </p>
        <CodeBlock>
          {`
(function () {
  //処理
}());          
`}
        </CodeBlock>
        <p>
          例を示します。
        </p>
        <CodeBlock>
          {`
(function(age) {
  return \`You are cool and age \${age}\`;
})(10);
  
  実行結果
  You are cool and age 10
`}
        </CodeBlock>
        <p>
          この場合、引数に10を代入しています。
        </p>
        
        <p>
          では、次にメソッドについて見ていきましょう。
        </p>
        <h2>メソッド</h2>
        <p>
          メソッドと関数はほぼ同義語ですが、詳しい違いについて見ていきましょう。
        </p>
        <h3>
          関数とメソッドの違いは何か？
        </h3>
        
        <p>
          関数とメソッドという全く別物の機能が存在するのではなく、オブジェクトのプロパティに代入された関数の事を便宜上メソッドと呼んでいます。 </p>
        <p>簡単に言うと、オブジェクトの中にある関数がメソッドです。
        </p>
        <p>
          では、メソッドを記述する3つのタイプを確認しましょう。
        </p>
        <CodeBlock>
          {`
const tel = {
  name: 'Tel Min',

  // メソッド
  sayHi: function () {
    console.log(\`Hey \${this.name}\`);
    return ' Hey Tel';
  },

  // 速記タイプメソッド
  yellHi() {
    console.log('Hey Tellll');
  },

  // アロー関数
  wisperHi: () => {
    console.log('hi tel im a mouse');
  },
};

console.log(tel.sayHi());
console.log(tel.yellHi());
console.log(tel.wisperHi());
`}
        </CodeBlock>
        <p>
          それぞれ、プロパティの値として関数を代入しています。
        </p>
        <p>
          速記メソッドでは、コロン（：）を取り除いていますが、動作します。
        </p>
        <h3>
          コールバック関数：
        </h3>
        <p>
          ある関数を呼び出すときに引数を指定する別の関数のことです。 </p>
        <p>JavaScriptでは、関数はデータなので、関数を引数として渡すことができるのです。また、呼び出された関数が、逆に関数を呼び出す側になるので、コールバックといいます。 </p>
        <p>また、関数を受け取る関数を「高階関数」と呼びます。つまり、高階関数に渡すための関数の事をコールバック関数と言います。
        </p>
        <br />
        <p>
          コールバック関数は非同期処理などに良く用いられるのですが、非同期処理をした後に実行したい処理を指定する際に、コールバック関数が使われます。
        </p>
        <p>
          書き方としては、以下のようになります。
        </p>
        <CodeBlock>
          {`
function 高階関数(コールバック関数){
  コールバック関数();
}          
`}
        </CodeBlock>
        <p>
          また、引数に関数を呼び出す時は、関数名 (); の () をつけないようにしましょう。 </p>
        <p>
          では、例を示します。
        </p>
        <p>
          ・htmlファイル
        </p>
        <CodeBlock>
          {`
<body>
  <button class="clickMe">Click Me!</button>
</body>        
`}
        </CodeBlock>
        <p>
          ・JSファイル
        </p>
        <CodeBlock>
          {`
function yellHi() {
  console.log('Hey Tellll');
}

const button = document.querySelector('.clickMe');

button.addEventListener('click', yellHi);    

実行結果
ブラウザでボタンをクリックしたときに、Hey Tellllが表示される。
`}
        </CodeBlock>
        <p>
          addEventListener関数のclickの処理が行われた後に、yellHi関数が呼び出されたことになります。これがコールバック関数です。 </p>
        <p>また、関数を呼び出すときは（）を付けていけません。
        </p>
        <p>実際に、上記のStackBlitzで()のありなしの動作確認をしていただきたいのですが、()がない場合はボタンが押されるごとに関数が実行されるのに対し、()をつけた場合はブラウザ読み込み時に一度関数が実行されるだけで、ボタンをクリックしても関数は実行されません。
        </p>
        <br />
        <p>別の例を挙げます。</p>
        <CodeBlock>
          {`
function handleClick() {
  console.log('Great Clicking!!');
}
  
button.addEventListener('click', handleClick);          

実行結果
ボタンをクリックした後にに、handleClick関数が呼び出され、Great Clicking!!が表示されました。
`}
        </CodeBlock>
        <p>
          また、以下の例では、コールバック関数に匿名関数を使用したものになります。関数を一回限り使用する場合は、匿名関数を使用するようにしましょう。
        </p>
        <CodeBlock>
          {`
const button2 = document.querySelector('.clickMe2');

button2.addEventListener('click', function () {
  console.log('Nice Job!');
}); 

実行結果
ボタンをクリックした後に、Nice Job!が表示されます。
`}
        </CodeBlock>
        <br />
        <p>
          最後に、タイマーコールバック関数という、setTimeout()メソッドをつかったコールバック関数をご紹介します。これは、任意の時間経過後に一回だけ実行される関数です。
        </p>
        
        <CodeBlock>
          {`
const button3 = document.querySelector('.clickMe3');

button3.addEventListener('click', function () {
  setTimeout(() => {
    console.log('Done! Time to eat!');
  }, 1000);
});      

実行結果
クリックして1秒後に、'Done! Time to eat!が表示されます。
`}
        </CodeBlock>
        <br />
        <p>
          以上、今回はJavaScriptの関数について学んできましたが、いかがだったでしょうか。
        </p>
        <p>
          特に、コールバック関数は複雑に感じるかもしれませんが、しっかりと理解して使えるようにしましょう。
        </p>
        <div>
          
        </div>
      </LessonLayout>
    </div>
  )
}

export default withRouter(FunctionsComponent)