import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const ScopeComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={9}
         title=' Javascrirptのスコープ ' />
        <p>
          今回はスコープについて学んでいきましょう。
        </p>
        <h2>
          スコープとは？
        </h2>
        
        <p>
          プログラム中で変数名などのシンボルが参照可能な有効範囲のことを、スコープといいます。つまり、関数内で定義した変数、定数、引数は基本的にその中でしか使えない、という事になります。また、スコープの仕組みを理解する事で、関数をより深く理解することにつながるので、JavaScriptを学ぶ上で非常に重要な概念です。
        </p>
        <h2>
          スコープを使うメリット
        </h2>
        
        <p>
          ・変数名の競合を防ぐ
        </p>
        <p>
          ・メモリの消費を防ぐ
        </p>
        
        <h3>
          変数名の競合について</h3>
        
        <p>
          スコープが異なれば、同じ名前の変数を定義できます。逆に、スコープの仕組みがないと、全て違う名前の変数をつけなければならないので、大変ですね。
        </p>
        
        <h3>
          メモリの消費を避けるガベージコレクションについて
        </h3>
        
        <p>
          スコープがなければ、すべての変数がグローバルに属することになります。そして、グローバルに属する変数はプログラムから参照され続けるため、ガベージコレクションされません。つまり、ページを閉じるまでの間ずっと、不要なメモリ領域を確保し続けてしまいます。
        </p>
        
        <h2>
          スコープの種類について
        </h2>
        
        <p>
          グローバル変数とは、グローバルスコープで定義した変数の事で、プログラミング全体からアクセスすることができます。
        </p>
        
        <p>
          一方、ローカルスコープはさらに、関数スコープとブロックスコープという2種類に分けることができます。
        </p>
        
        <p>
          関数スコープとは、関数function(){'{}'}ごとに作られ、参照されるスコープの事を指します。
        </p>
        
        <p>
          また、ブロックスコープとは、ブロック {} ごとに作られるスコープで、ブロックスコープ内で作られたlet,
          constはブロック内でのみ使用可能であり、アクセス可能となります。
        </p>
        
        <h2>
          スコープの基本
        </h2>

        <CodeBlock>
          {`
const one = ‘グローバルスコープ’;

function two(){
  const three = ‘関数スコープ’;
  if(true){
    let four =’ブロックスコープ’;
  }
}
`}
        </CodeBlock>
        
        <p>
          グローバル変数oneはどこからでもアクセスが可能であり、変数threeは関数two内でのみアクセス可能です。また、変数fourはif文のブロック内でのみアクセス可能となります。
        </p>
        
        <p>
          では、詳しく見ていくために、以下のテスト用ファイルを準備します。
        </p>
        
        <p>
          ・scope.html
        </p>
        <CodeBlock>
          {`
<!DOCTYPE html>
<html lang=”en”>

<head>
<meta charset=”UTF-8”>
<meta name=”viewport” content=”width=device-width,initial-scale=1.0”>
<title></title>
<link rel=”stylesheet” href=”../../base.css”>
</head>

<body>
<script src=”./scoop.js></script>
<script>
console.log(first);
</script>
</body>
</html>
`}
        </CodeBlock>
        
        <h2>
          グローバル変数について
        </h2>
        
        <p>
          グローバルスコープで定義した変数のことをグローバル変数といい、関数やモジュール、if文の外で宣言された変数です。JavaScriptが記述されたScriptタグやConsoleから呼び出し可能となります。
        </p>
        
        <p>
          ・scope.js
        </p>
        <CodeBlock>
          {`
const first = ‘tel’;
`}
        </CodeBlock>
        
        <p>
          Consoleにて
        </p>
        <p>
          実行結果
        </p>
        <p>
          &gt; tel
        </p>
        
        <h2>
          Windowオブジェクトについて
        </h2>
        
        <p>
          Windowオブジェクトは、DOM文章を収めるウインドウを表し、ブラウザ上でのグローバルスコープをます。ですので、すべてのグローバル変数は、windowオブジェクトのプロパティとしてアクセスできるのです。例えば、ConsoleでsetTimeout();を実行することとwindow.setTimeout();は同義語ですし、以下も同様です。また、このようにwindowのプロパティやメソッドにアクセスする時は、「window.」は省略可能です。
        </p>
        
        <p>
          var a = 0;
        </p>
        <p>
          window.a = 0;
        </p>
        
        <p>
          では、以下の例も見ていきましょう。
        </p>
        
        <CodeBlock>
          {`
const first = ‘tel’;
let second = ‘mina’;
var age = 10;
`}
        </CodeBlock>
        
        <p>
          Consoleにて
        </p>
        <p>
          実行結果
        </p>
        <CodeBlock>
          {`
> first 
< “tel”
> second
< ”mina”
> age
< 10

> window.first
< undefined
> window.second
< undefined
> window.age
< 100
`}
        </CodeBlock>
        
        
        <p>
          windowオブジェクトを使った場合、window.ageだけ値が返ってきます。これは、varがwindowオブジェクトにアタッチされているからです。
        </p>
        
        <p>
          しかし、letやconstをトップレベルで宣言した場合もグローバル変数ですが、windowオブジェクトにはアタッチされていません。
        </p>
        
        <p>
          次に関数の場合について見てみると、以下のようになります。
        </p>
        
        <CodeBlock>
          {`
function sayHi() {
console.log(‘hi!’);
}  
`}
        </CodeBlock>
        
        <p>
          実行結果（Console）
        </p>
        <p>
          &gt; window.sayHi()
        </p>
        <p>
          &lt; hi!
        </p>
        
        <p>
          これは、関数がwindowオブジェクトにアタッチされていることを示しています。
        </p>
        <p>
          これらをまとめると、以下のように言い換えることが可能となります。
        </p>
        
        <p>
          ・グローバル変数はwindowオブジェクトのプロパティ。
        </p>
        <p>
          ・グローバル関数はwindowオブジェクトのメソッド。
        </p>
        <p>
          ・let, const, classは、グローバルスコープで定義しても、windowオブジェクトのプロパティとはならない。
        </p>
        
        <p>
          以上がグローバル変数とwindowオブジェクトについてですが、グローバル変数だけを使えばいいわけではありません。なぜなら、グローバル変数はどこでも変更されてしまう可能性があるからです。なので、できるだけ使わないようにしましょう。では、スコープについてもう少し掘り下げていきます。
        </p>
        
        <h2>
          関数スコープ
        </h2>
        
        <p>
          関数スコープとは、関数function(){'{}'}ごとに作られ、参照されるスコープのことです。例を示します。
        </p>
        
        <CodeBlock>
          {`
const age = 10;

function go() {
  const hair = ‘blonde’;
}

go();

console.log(age);
console.log(hair);
        `}
        </CodeBlock>
        
        <p>
          実行結果（Console）
        </p>
        <p>
          &lt; 10
        </p>
        <p>
          &lt; Uncaught ReferenceError:
        </p>
        
        <p>
          このように、関数内で宣言された変数hairは、関数外で呼び出したり実行したりすることはできません。これが、関数スコープです。しかし、以下のように、関数内では呼び出す場合は実行可能です。
        </p>
        
        <CodeBlock>
          {`
function go() {
  const hair = ‘blonde’;
  console.log(hair);
}  
        `}
        </CodeBlock>
        
        <p>
          実行結果（Console）
        </p>
        <p>
          &lt; blonde
        </p>
        
        <p>
          では、次のように関数内で関数外の定数を呼び出すことは可能でしょうか？
        </p>
        
        <CodeBlock>
          {`
const age = 10;

function go() {
console.log(age);
}
        `}
        </CodeBlock>
        
        <p>
          実行結果は10になります。JavaScriptの動きとしては、まず関数内にageが定義されているかチェックしますが、この場合は関数内にageが定義されていないので、一つ上の階層であるグローバル変数からageを参照するのです。
        </p>
        
        <CodeBlock>
          {`
const age = 10;

function go() {
const age = 20;
console.log(age);
}
        `}
        </CodeBlock>
        
        <p>
          それでは、この場合の実行結果はどうなるでしょうか？
        </p>
        <p>
          答えは20になります。この場合、同じ階層に定数ageが定義されているので、こちらの方が優先順位が高くなるからです。
        </p>
        
        <p>
          では、関数の中に関数を定義した場合について見ていきます。
        </p>
        
        <CodeBlock>
          {`
function sayHi(name){
  function yell() {
    console.log(name.toUpperCase())
  }
  yell();
}  
        `}
        </CodeBlock>
        
        <p>
          &gt; sayHi(‘tel’)
        </p>
        <p>
          実行結果
        </p>
        <p>
          TEL
        </p>
        
        <p>
          この場合、sayHi関数の中にyell関数を定義し、その関数内でyell関数を呼び出すことが可能となっています。しかし、以下の場合では、yell関数が親スコープ（sayHi関数）外で呼び出されているので、Uncaught
          ReferenceErrorが発生します。
        </p>
        
        <CodeBlock>
          {`
function sayHi(name){
  function yell() {
    console.log(name.toUpperCase())
  }
  yell();
}
  
  yell();  
        `}
        </CodeBlock>
        
        <p>
          &gt; sayHi(‘tel’)
        </p>
        
        <p>
          以上が関数スコープについてです。
        </p>
        
        <h2>
          ブロックスコープ
        </h2>
        <p>
          ブロックスコープとは、ブロック {} ごとに作られるスコープで、ブロックスコープ内で作られたlet,
          constはブロック内でのみ使用可能であり、アクセス可能となります。まずは、例を見てみましょう。
        </p>
        
        <CodeBlock>
          {`
if (1 === 1) {
  let cool = true;
  }
  
  console.log(cool);
  
`}
        </CodeBlock>
        
        <p>
          では、if文{}内で定義されたcoolは呼び出すことができるでしょうか？
        </p>
        <p>
          実行結果は、Uncaught ReferenceErrorになります。なぜなら、if文のブロック内 {}
          で定義された変数はブロック外で呼び出すことができないからです。これをブロックスコープといいます。
          また、上記のコードを実行できるように修正すると、このようになります。
        </p>
        
        <CodeBlock>
          {`
let cool;
if (1 === 1) {
cool = true;
}

console.log(cool);
`}
        </CodeBlock>
        
        <p>
          では、繰り返し文を用いたブロックスコープの例も見てみましょう。
        </p>
        
        <CodeBlock>
          {`
for(let i = 0; i < 10; i++) {
  console.log(i);
  }
  
`}
        </CodeBlock>
        
        <p>
          実行結果（Console）
        </p>
        <p>
          1
        </p>
        <p>
          2
        </p>
        <p>
          3
        </p>
        <p>
          4
        </p>
        <p>
          5
        </p>
        <p>
          6
        </p>
        <p>
          7
        </p>
        <p>
          8
        </p>
        <p>
          9
        </p>
        
        <p>
          となります。そして、i を実行した結果は、Uncaught ReferenceErrorとなります。これは、if文内で宣言された i
          は、if文外で呼び出すことができないからです。ちなみに、let i でなく、var i　として宣言した場合、Consoleでi
          を呼び出すと10が返ってきます。これは、ブロックスコープがvarでは適用されないからです。ですので、エラーが発生する可能性が高くなり、保守性の面から見ても、varは使わないようにした方が良いのです。
        </p>
        
        <h2>
          静的スコープ（レキシカルスコープ）
        </h2>
        
        <h3>
          静的スコープとは？
        </h3>
        
        <p>
          JavaScriptやJava、pythonは、静的スコープが用いられ、動的スコープ（ダイナミックスコープ）にはPerlなどが使用されています。静的スコープは、関数を定義した時点でスコープが決まり、動的スコープは関数を実行した時点でスコープが決まります。
        </p>
        
        <p>
          JavaScriptは静的スコープなので、ここでは静的スコープの例について説明します。
        </p>
        
        <CodeBlock>
          {`
const dog = ‘snickers’;

function logDog() {
  console.log(dog);
}

function go() {
  const dog = ‘sunny’;
  logDog();
}

go();
`}
        </CodeBlock>
        
        <p>
          答えは、実行結果はsnickersとsunnyどちらになるでしょうか？
        </p>
        
        <p>
          go関数のローカルスコープで、dogをsunnyと定義してlogDog関数を呼び出しているので、sunnyとなると考えたかもしれませんが、答えはsnickersとなります。なぜなら、JavaScriptは静的スコープなので、変数がどこで実行されたかではなく、変数がどこで宣言されたかに依存するからです。  
          つまり、logDog関数の引数dogはグローバル変数のsnickersを参照する事になります。
        </p>
        
        <CodeBlock>
          {`
const dog = ‘snickers’;

function logDog() {
  console.log(dog);
}

function go() {
  const dog = ‘sunny’;
  logDog(dog);
}

go();
`}
        </CodeBlock>
        
        <p>
          上記の関数では、logDogの引数に定数dogを代入していますが、実行結果はどうなるでしょうか？  
          答えは、sunnyになります。なぜなら、go関数を宣言した時にlogDogの引数としてsunnyが代入されるからです。  
          少し混乱するかもしれませんが、スコープについてしっかりと理解しておかなければ、バグの原因にもなりますので、気をつけましょう。
        </p>
        
        <p>
          以上、今回はスコープについて学んできましたが、いかがだったでしょうか？
        </p>
        <p>
          では、最後までお読みいただきありがとうございました。
        </p>
        
      </LessonLayout>
    </div>
  )
}

export default withRouter(ScopeComponent)