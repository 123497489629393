import * as React from 'react'
import Router from './router'
import Logo from './components/Logo'
import Footer from './components/Footer'
// import Contact from './components/Contact'
import { Helmet } from 'react-helmet'

function App() {

  const pageTitle = 'Learn JS'
  const pageDesc = 'Learn JSは、JavaScriptを実際にコードを動かしながら学べる無料プログラミングサービスです。'
  const pageKws = 'JavaScript,ジャバスクリプト,JS,プログラミング'
  const ogUrl = 'https://javascript.1f8.co.jp/'
  
  return (
    <div>
      <Helmet>
        <meta charset='UTF-8' />
        <meta name='keywords' content={pageKws} />
        <meta name='description' content={pageDesc} />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta property='og:title' content={pageTitle} />
        <meta property='og:description' content={pageDesc} />
        <meta property='og:type' content='article' />
        <meta property='og:url' content={ogUrl} />
        <meta property='og:image' content='../img/logo.png' />
        <title>{pageTitle}</title>
      </Helmet>
      <nav className='w-full py-4 bg-onef8 shadow'>
        <div className='w-full container mx-auto flex flex-wrap items-center justify-between'>
          <div>
            <Logo />
            <div className='px-4 text-white font-semibold text-xl box-shadow-bottom'>
              LEARN JAVASCRIPT
            </div>
          </div>
          <div className='text-lg'>
            <a href='https://1f8.co.jp' target='_blank' rel='noopener noreferrer'>1F8について</a>
          </div>
        </div>
      </nav>
      <div className='bg-white max-w-screen-xl mx-auto mb-28'>
        <Router />
      </div>
      {/* <Contact /> */}
      <Footer />
    </div>
  )
}

export default App
