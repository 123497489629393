import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const VariablesStatementsComponent = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={4}
          title=' 変数とステートメント ' />
        <p>
          今回から、JavaScriptの書き方について学んでいきます。今回はコメント、変数とステートメントについて学んでいきましょう。
        </p>
        <h2>コメント</h2>
        <p>コメントはプログラムとして評価されません。そのため、ソースコードの説明を書くために利用されています。</p>
        <p>コメントの書き方には２通りの方法があります。</p>
        <br />
        <b>一行コメント</b>
        <p>一行コメントはその名の通り、一行のコメントを書く際に利用されます。</p>
        <CodeBlock>
          {`
// 一行コメント
          `}
        </CodeBlock>
        <br />
        <b>複数行コメント</b>
        <p>複数行コメントは、複数の行をまとめてコメントを書く際に利用します。まとめて書けるので長い説明を書く際などに利用されます。</p>
        <CodeBlock>
          {`
/*
複数行コメント
囲まれている範囲がコードとして評価されません
*/
        `}
        </CodeBlock>
        <p>ちなみに、複数行のコメントの中に複数行のコメントを書くことはできません。</p>
        <br />
        <b>HTML-likeコメント</b>
        <p>HTMLのコメントと同じ表記で、ES6からの後方互換性のためだけに仕様として追加されています。このように、ECMAScriptは後方互換性が慎重に取り扱われます。</p>
        <h2>ステートメントとは何か？</h2>
        <p>
          ステートメントとはプログラムで使用する宣言や命令文、構文の事を指します。
        </p>
        <h2>変数とは何か？</h2>
        <p>
          まず、変数についてですが、「変数＝データを保存しておくための箱」と説明されることが多いです。文字列や数値などのデータに名前をつけることで、繰り返し利用できる様にする機能です。
        </p>
        <p>
          変数を使うことで、長い文章や長い数式を一つの変数に格納して何度も使い回すことができるので、コードを書く手間が減りますね。
        </p>

        <h3>変数の使い方－変数宣言とは？－</h3>
        <p>
          変数を定義するには、変数を「これは変数です!」と宣言する必要があり、宣言するキーワードとして<code>const</code>、<code>let</code>、<code>var</code>の3つの種類があります。
        </p>
        <p><code>var</code>は元々古くからある変数宣言のキーワードですが、意図しない動作を作りやすい問題が知られていました。そのためECMAScript2015で、その問題を改善するために<code>const</code>と<code>let</code>という新しいキーワードが導入されました。</p>
        <p>
          これは、データの種類を分けることで、データを使うときにごちゃごちゃにならないので使いやすくなりますし、エラーを防ぐことができます。
        </p>
        <h3>変数の公式</h3>
        <p>
          <ul>
            <li>const 変数名＝初期値;</li>
            <li>let  変数名＝初期値;</li>
            <li>var  変数名＝初期値;</li>
          </ul>
        </p>
        <br />
        <p>
          変数名とは、変数を呼び出すときに使われ、識別子とも呼ばれます。初期値には、数字や文字、関数などを代入することができます。
        </p>
        <p>
          また、JavaScriptの本文（statement）を書き終えたら、セミコロン( ; ) で終了の目印をつける必要があります。
        </p>
        <br />
        <p>
          使用例）
        </p>
        <CodeBlock>
          {`
const cool = true;
let age = 300;
var first = 'tel';
        `}
        </CodeBlock>
        <p>
          また、同じ形式の変数宣言であれば、カンマ(,)で区切ることにより、一度に複数宣言することができます。
        </p>
        <CodeBlock>
          {`
var first = 1, second = 2, third = 3;
        `}
        </CodeBlock>
        <h3>変数名の付け方に関するルール</h3>
        <p>
          変数名をつけるにはいくつかのルールがあるので、気をつけましょう。
        </p>
        <br />
        <p>
          ・大文字で始めない。大文字で書き始めるのはクラス名など。
        </p>
        <p>
          ⇒× Dog
        </p>
        <br />
        <p>
          ・ドル文字( $ )やアンダーバー( _ )で書き始めることもできる。
        </p>
        <p>
          ⇒$cat, _apple
        </p>
        <br />
        <p>
          ・数字で始めるとエラーとなる。2文字目以降であれば使用可。
        </p>
        <p>
          ⇒× 1dog
        </p>
        <br />
        <p>
          ・複数の単語を組み合わせて各場合、camelCaseを用いる。
        </p>
        <p>
          ⇒ twoDogs
        </p>
        <br />
        <p>
          ・if, catch, elseなどの予約語と呼ばれるものは使用不可。
        </p>
        <h2>
          命名規則について
        </h2>
        <p>
          上記のcamelCase以外にも、命名規則と呼ばれるものがいくつかあります。
          ただし、JavaScriptでは使えないものが多いので、基本的にはcamelCaseを用いるようにしてください。
        </p>
        <br />
        <p>
          ・camelCase
        </p>
        <p>
          const iLovePizza = true;
        </p>
        <br />
        <p>
          ・UpperCamelCase：変数名としては使用不可。クラス名などに使う。
        </p>
        <p>
          const ILoveToEatHotDogs = false;
        </p>
        <br />
        <p>
          ・snakeCase：変数名として使用可能。
        </p>
        <p>
          const this_is_snake_case = ‘cool’;
        </p>
        <br />
        <p>
          ・kebabCase：JavaScriptでは使用不可。
        </p>
        <p>
          const this-is-kebab-case = false;
        </p>
        <h2>変数宣言の特徴について</h2>
        <p>それでは、const, var, letの詳しい特徴を見ていきましょう。</p>
        <h3>constの特徴</h3>
        <p>・constキーワードは、再代入できない変数の宣言とその変数が参照する値（初期値）を定義できます。</p>
        <p>・constは再代入できない変数の宣言をするキーワードなので、宣言した変数に対して後から値を代入することはできません。再代入した場合、エラーが発生し、それ以降の処理は実行されなくなります。</p>
        <p>・一般的に、変数への再代入は「変数の値は最初に定義した値と常に同じである」という参照透過性と呼ばれるルールを壊すため、バグを発生させやすい要因として知られています。よって、 変数に対して値を再代入する必要がない場合はconstキーワードを使って変数宣言することを推奨しています。</p>
        <p>変数の定義方法は以下の通りです。</p>
        <CodeBlock>
          {`
const constFirst = 1;

const constFirst = 2;
        `}
        </CodeBlock>
        <p>・constは「再代入できない変数」を定義する変数宣言であり、必ずしも定数を定義するわけではありません。</p>
        <p>・定数とは、一度定義した名前（変数名）が常に同じ値を示すものです。</p>
        <p>・const宣言の特性として「再代入できない変数」を定義すると理解しておきましょう。</p>
        <CodeBlock>
          {`
const object = {first: 1;}

console.log(object.first);
        `}
        </CodeBlock>
        <p>実行結果：</p>
        <p>⇒1</p>
        <CodeBlock>
          {`
object.first = 2;

console.log(object.first);
`}
        </CodeBlock>
        <p>実行結果：</p>
        <p>⇒2</p>
        <h3>letの特徴</h3>
        <p>・再代入可能な変数を宣言できます。</p>
        <p>・変数に値を再代入したいケースとして、ループなどの反復処理の特徴で特定の変数が参照する値を変化させたい場合などがあり、そういったケースでよく用いられます。</p>
        <p>・使い方はほとんどconstと同じです。</p>
        <p>・宣言した変数に対しては何度でも値の代入が可能です。</p>
        <CodeBlock>
          {`
let letFirst = 1;

letFirst = 2;

letFirst = 3;

letFirst = 4;

console.log(letFirst);
`}
        </CodeBlock>
        <p>実行結果：</p>
        <p>⇒4</p>
        <br />
        <p>・再定義はできません。</p>
        <CodeBlock>
          {`
let letFirst = 1;

let letFirst = 2;
`}
        </CodeBlock>
        <p>実行結果：</p>
        <p>⇒×</p>
        <p>・letはconstとは異なり、初期値を指定しない変数も定義することができます。</p>
        <p>・初期値が指定されなかった変数はデフォルト値としてundefinedという値で初期化されます。</p>
        <CodeBlock>
          {`
let letFirst;

// 'letFirst'は自動的に'undefined'という値になる
          `}
        </CodeBlock>
        <p>・このletFirstという変数には、代入演算子（=）を使うことで、値を代入できます。</p>
        <h3>varの特徴</h3>
        <p>・varキーワードでは、値の再代入が可能な変数を宣言できます。</p>
        <p>・varの使い方はletとほとんど同じです。</p>
        <CodeBlock>
          {`
var varFirst = 1;

varFirst = 2;

console.log(varFirst);
`}
        </CodeBlock>
        <p>実行結果：</p>
        <p>⇒2</p>
        <br />
        <p>・再定義できる</p>
        <CodeBlock>
          {`
var varFirst = 1;

var varFirst = 2;

console.log(varFirst);
`}
        </CodeBlock>
        <p>実行結果：</p>
        <p>⇒2</p>
        <br />
        <p>実際に、変数を使う際は基本的にはconstを用います。
          constは再代入も再定義もできないので堅牢性が高く、バグが少なくなります。
        </p>
        <br />
        <p>また、後々に値を変える必要がある時は、その都度変数宣言を letに変えると良いでしょう。</p>
        <h2>varを使ってはいけない理由とは？</h2>
        <p>ちなみに、varはJavaScriptが始まった当初からある変数ですが、今ではほとんど使いません。</p>
        <p>理由として、varキーワードには同じ名前の変数を再定義できてしまう問題があり、意図しないところで自分やチームメンバーが書き換えてしまう可能性があるからです。</p>
        <br />
        <p> 一方、letやconstは書き換えようとするとエラーが起きるためバグに気づきやすいのです。</p>
        <p>そのため、これから各コードに対してvarを利用することは避けた方が良いでしょう。</p>
        <br />
        <p>また、varには他のプログラミング言語と違い、「変数の巻き上げ」という挙動を示します。</p>
        <CodeBlock>
          {`
var myname = "first";

function func() {
  //出力内容は？
  console.log(myname);
  var myname = "second";
  //出力内容は？
  console.log(myname);
}

func();
        `}
        </CodeBlock>
        <p>例えば、上記のコードを見た時に、他のプログラミング言語であれば、一つ目のの出力内容はfirst, 二つ目の出力内容はsecondと、出力されると予想したのではないでしょうか？
        </p>
        <br />
        <p>
          しかし、実際には、一つ目がundefined, 二つ目がsecondになります。なぜそうなるのかというと、上記のコードは以下のように動作することになるからです。
        </p>
        <CodeBlock>
          {`
var myname = "first";

function func() {

  var myname;
  console.log(myname);
  myname = "second";
  console.log(myname);
}

func();
        `}
        </CodeBlock>
        <p>つまり、関数内の戦闘に変数宣言が巻き上げられ、変数の値は巻き上げられていません。実際に下のコードを実行してみましょう。
        </p>
        <p>(Consoleを展開してください)</p>
        <br />
        <iframe src='https://stackblitz.com/edit/js-omm18k?file=index.js' title='巻き上げExample' style={{ width: '100%', height: '400px' }}></iframe>
        <br />
        <p>
          このように、varを用いることで予想外のバグが発生してしまうことがあるので、変数宣言を関数の先頭で行うようにする。または、varをそもそも使わないようにした方が良いでしょう。</p>
        <br />
        <p>結果的に、このようなvarの問題を解決するためにconstとletが2019年にECMAScriptの新機能としてが追加されたのです。
        </p>
        <br />
        <p>ちなみに、letやconstでも変数の巻き上げは起こりますが、undefinedではなく、ReferenceErrorを投げかけられる事になります。どちらにせよ、変数宣言は関数の先頭でおこなうようにしましょう。</p>
        <br />
        <p>[コラム]</p>
        <p>ではなぜvarそのものを改善するのではなく、新しくconstとletというキーワードを追加することによって問題の回避をしたのかというと、それは後方互換性のためです。</p>
        <p>varの挙動自体を変更してしまうと、すでにvarで書かれたコードの動作が変わってしまい、動かなくなるアプリケーションが出てくるためです。</p>
        <h3>コードブロック（Code block）について</h3>
        <p>コードブロックとは、 {'{'} {'}'}のカッコで囲まれたものを指します。コードブロックの後にはセミコロン( ; )を書く必要はありません。
          if文の例を下記に挙げます。</p>
        <CodeBlock>
          {`
if (age > 10) {console.log("You are old");}
        `}
        </CodeBlock>
        <h3>Strictモードについて</h3>
        <p>Strictモードを使えば、変数宣言などを忘れた場合、エラーを表示してくれるようになるので便利です。</p>
        <p>使用例）</p>
        <p>'use strict';</p>
        <p>dog = 'snickers';</p>
        <p>⇒エラー</p>
        <h2>スコープについて</h2>
        <p>スコープ：</p>
        <p>スコープとは、変数がどの場所から参照できるのかを定義する概念であり、変数の有効範囲ということになります。
          そして、スコープはグローバルスコープとローカルスコープという2つの種類に分けることができます。</p>
        <br />
        <p>グローバルスコープ：</p>
        <p>グローバルスコープの変数は、プログラミング全体からアクセスすることができます。
          一方、ローカルスコープはさらに、関数スコープとブロックスコープという2種類に分けることができます。</p>
        <br />
        <p>関数スコープ：</p>
        <p>関数スコープとは、関数function(){'{ }'}ごとに作られ、参照されるスコープの事を指します。
          また、ブロックスコープとは、ブロック {'{ }'}ごとに作られるスコープで、ブロックスコープ内で作られたlet, constはブロックの内側でのみ使用可能であり、アクセス可能となります。</p>
        <p></p>
        <h2>エクササイズ</h2>
        <p>Q1：console.logの出力結果は？</p>
        <CodeBlock>
          {`
if (true) {
  var x = 5;
}
console.log(x);
`}
        </CodeBlock>
        <p>Q2：console.logの出力結果は？</p>
        <CodeBlock>
          {`
if (true) {
  let y = 5;
}
console.log(y);
        `}
        </CodeBlock>
        <details>
          <summary>答え</summary>
          <p>A1. x は 5</p>
          <p>A2. ReferenceError: y が定義されていない</p>
        </details>
        <p>Q3：console.logの出力結果は？</p>
        <CodeBlock>
          {`
console.log(x === undefined);
var x = 3;
`}
        </CodeBlock>
        <p>Q4：console.logの出力結果は？</p>
        <CodeBlock>
          {`
var myvar = 'my value';

(function() {
  console.log(myvar);
  var myvar = 'local value';
})();
`}
        </CodeBlock>
        <details>
          <summary>答え</summary>
          <p>A3. true</p>
          <p>A4. undefined</p>
        </details>
        <p>Q5：console.logの出力結果は？</p>
        <CodeBlock>
          {`
foo();

function foo() {
  console.log('bar');
}
`}
        </CodeBlock>
        <p>Q6：console.logの出力結果は？</p>
        <CodeBlock>
          {`
baz();

var baz = function() {
  console.log('bar2');
};

`}
        </CodeBlock>
        <details>
          <summary>答え</summary>
          <p>A5. "bar"</p>
          <p>A6. Uncaught TypeError: baz is not a function</p>
        </details>
        <br />
        <p>今回は以上になります。次回もJavaScriptについて一緒に学んでいきましょう！</p>
      </LessonLayout>
    </div>
  )
}

export default withRouter(VariablesStatementsComponent)
