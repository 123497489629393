import React from 'react'
import CodeBlock from './CodeBlock'
import Logo from './Logo'
import blog from '../img/Vector.svg'
import LP from '../img/icon-white.svg'
import { TwitterTimelineEmbed, TwitterDMButton } from 'react-twitter-embed'
import { FacebookProvider, MessageUs } from 'react-facebook'

 const Footer = () => {
  return (
    <div>
      <div className='w-full py-4 bg-darkgray shadow px-2 sm:px-6'>
        <div className='w-full container mx-auto flex flex-wrap items-center justify-between md:max-w-2xl xl:max-w-4xl'>
          {/* twitter */}
          <div className='flex flex-col sm:flex-row mx-auto gap-4 md:gap-10 justify-center xl:gap-16 xl:mb-10'>
            <div className='mx-auto'>
              <div className='text-center py-6'>
                <TwitterDMButton id={1260521135566802944} options={{ size: 'large' }} />
              </div>
              <TwitterTimelineEmbed sourceType='profile'
              screenName='1f816'
              options={{ height: 300 }} />
            </div>
            {/* Facebook */}
            <div className='mx-auto'>
              <div className='text-center py-6'>
                <FacebookProvider appId='354964132966482'>
                  <MessageUs messengerAppId='590166295551298' pageId='107237067726772' />
                </FacebookProvider>    
              </div>
              <div className='bg-white'>
                <iframe src='https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F1F8dev%2Fposts%2F147481920368953&show_text=true&width=500' width='300' height='300' style={{ border: 'none',  overflow: 'hidden' }} scrolling='no' frameborder='0' allowfullscreen='true' allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share' title='facebook post'></iframe>
              </div>
            </div>
          </div>
          <div className='mt-6 px-3 sm:px-0 w-full'>
            <div>
              <a href='https://blog.1f8.dev/'>
                <div className='flex pl-3 sm:pl-6 items-center'>
                  <img src={blog} alt='blog logo' />
                  <p className='pl-1 sm:pl-3 text-white'>1F8 Blog</p>
                </div>
              </a>
            </div>
            <div>
              <a href='https://1f8.co.jp/'>
                <div className='flex pl-3 sm:pl-6 items-center'>
                  <img src={LP} alt='homepage logo' className='w-6 h-6' />
                  <p className='pl-1 sm:pl-3 text-white'>Homepage</p>
                </div>
              </a>
            </div>
          </div>
          <CodeBlock language='text'>
            {`
会社情報:
会社名：１F８
設立日: 2020年6月
日本住所: 〒160-0023 東京都新宿区西新宿4-29-4-315
米国住所: 20687 Amar Road Ste 2-900, Walnut, CA 91789
事業計画
Copyright © 1F8 (2020)
            `}
          </CodeBlock>
          <div className='mr-7'>
            <Logo styles='square' />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
