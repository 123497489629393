import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DOM_4Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={15}
         title=' DOM_4 - DOMのクラスについて - ' />
        <p>
          それでは、今回はDOM操作の１つであるクラスの追加、除去の方法を見ていきましょう。
        </p>
        <br />
        <p>
          JavaScript で HTML の class 属性を操作するためには、classListを使います。
        </p>
        <br />
        <h2 dir='ltr'>
          classListについて
        </h2>
        <p>
          element.classListを使用する事で、特定の要素のクラス名を追加したり、削除したり、参照したりすることが出来る便利なプロパティです。
        </p>
        <br />
        <p>
          classListの後に以下のようなメソッドを定義することにより、追加削除などの機能を指定することができます。
        </p>
        <br />
        <p>
          ・add
        </p>
        <p>
          ・remove
        </p>
        <p>
          ・toggle
        </p>
        <p>
          ・contains
        </p>
        <br />
        <p>
          ただし、厳密にはclassListで返ってくるオブジェクトはclassListのメソッドではなく、DOMTokenListのメソッドとなります。
        </p>
        <br />
        <p>
          DOMTokenListは、オブジェクトのインスタンスであり、トークン（文字列）のリストを表します。
        </p>
        <br />
        <p>
          では、index.htmlとthe-dom.jsを準備します。
        </p>
        <br />
        <p>
          ・index.html
        </p>
        <CodeBlock>
          {`
<body>

<img src="https://picsum.photos/600/600?random=1" class='nice' alt="">
        `}
        </CodeBlock>
        <p>
          では、niceのクラス属性を持つ要素を取得してみます。
        </p>
        <br />
        <p>
          ・the-dom.js
        </p>
        <CodeBlock>
          {`
const pic = document.querySelector('.nice');

console.log(pic.classList);
        `}
        </CodeBlock>
        <p>
          実行結果
        </p>
        <CodeBlock>
          {`
DOMTokenList [“nice”, value: “nice”]
0: “nice”
length: 1
value: “nice”
_proto_: DOMTokenList
        `}
        </CodeBlock>
        <p>
          niceのクラス属性をもつDOMTokenListを取得することができました。
        </p>
        <br />
        <p>
          また、DOMTokenListは、配列のようなものであることがわかります。
        </p>
        <br />
        <p>
          では、index.htmlのclassにcoolを追加してみるとどうなるでしょうか？
        </p>
        <CodeBlock>
          {`
<body>

<img src="https://picsum.photos/600/600?random=1" class='nice cool' alt="">
        `}
        </CodeBlock>
        <p>
          <img src='https://lh5.googleusercontent.com/nX9hBHkjsEgyRjMBXCWjG2ssrxvJfFUsJXM8-pq287vFs512gwItO6_4dVPC1dwQkPhnsWPIo1J1PdWQGqMpX6dXXkNUd1zqPRtBZVYbit8Xpsyw4u4MsGjmG8-GQ1hpS3FaUV8M'
        width='600'
        height='600'
        alt='nice cool' />
        </p>
        <br />
        <p>
          クラス属性が、valueに文字列として返ってきました。
        </p>
        <br />
        <p>
          また、プロトタイプ(proto)をクリックする事で、以下のようなクラスで呼び出し可能なメソッドを確認する事ができます。
        </p>
        <br />
        <p>
          では、実際に以下の主要なメソッドを使ってみましょう。
        </p>
        <br />
        <p>
          ・add
        </p>
        <p>
          ・contains
        </p>
        <p>
          ・keys
        </p>
        <p>
          ・remove
        </p>
        <br />
        <p>
          classList.addメソッドはクラス属性を追加し、classList.removeメソッドはクラス属性を除去します。
        </p>
        <CodeBlock>
          {`
const pic = document.querySelector(‘.nice’);

pic.classList.add(‘open’);

console.log(pic.classList);
        `}
        </CodeBlock>
        <p>
          実行結果(Elements)
        </p>
        <CodeBlock>
          {`
<body>

<img src="https://picsum.photos/600/600?random=1" class='nice cool open' alt="">
        `}
        </CodeBlock>
        <p>
          クラス属性にopenを追加することができました。
        </p>
        <CodeBlock>
          {`
const pic = document.querySelector(‘.nice’);

pic.classList.add(‘open’);
pic.classList.remove(‘cool’);

console.log(pic.classList);
        `}
        </CodeBlock>
        <p>
          実行結果(Elements)
        </p>
        <CodeBlock>
          {`
<body>

<img src="https://picsum.photos/600/600?random=1" class='nice open' alt="">
        `}
        </CodeBlock>
        <p>
          クラス属性からcoolを除去することができました。
        </p>
        <br />
        <p>
          このように、addメソッドでクラスの要素を追加、removeメソッドでクラスの要素を除去することできます。
        </p>
        <br />
        <p>
          では、toggleメソッドを確認するために、CSSを追加します。
        </p>
        <br />
        <p>
          toggle() メソッドは、 DOMTokenList インターフェイスのメソッドで、渡された token をリストから削除し、 false
          を返します。 token が存在しなかった場合は、追加して true を返します。
        </p>
        <br />
        <p>
          ・index,html
        </p>
        <CodeBlock>
          {`
<style>
.round {
border-radius: 50%;
}
</style>
        `}
        </CodeBlock>
        <p>
          ・the-dom.js
        </p>
        <CodeBlock>
          {`
const pic = document.querySelector(‘.nice’);

pic.classList.add(‘open’);
pic.classList.remove(‘cool’);
pic.classList.toggle(‘round’);

console.log(pic.classList);
        `}
        </CodeBlock>
        <br />
        <p>
          <img src='https://lh3.googleusercontent.com/GRTJTrOiZOKqW1B90lbKnfkww4mPi3bYnDgKllCgAJocFpdG9FZXm2SV3e7mr4Ne4RjOCWnnEcqKE-vcimw2HU7YeOInW7HmunvR3HhDRdBUrpjUTv9bpMk4w7zVnFpkilhtpdwq'
        width='600'
        height='600'
        alt='added round' />
        </p>
        <br />
        <p>
          実行結果から、もともとクラス属性にroundがなかったため、trueとなり画像を丸くするstyleが適用されました。
        </p>
        <br />
        <p>
          では、ChromeのConsole上でもう一度roundをtoggleしてみましょう。
        </p>
        <br />
        <p>
          <img src='https://lh4.googleusercontent.com/GZIPhitkEaoxGVyzh2jP30e3uyFNL4gHv1oKNshS1UqF4MoPnxpIu4BRxmpcBwnizfhyFRKkN1nb0Dv3Hx2ez3CoVGeGkdHo1EHq6RQ86cTnoAbOoZTbaM5Nk4aEFLxsZqqxEUeA'
        width='600'
        height='600'
        alt='removed round' />
        </p>
        <p>
          toggleを再度実行することで、falseとなり元に戻ります。つまり、toggleメソッドはクラスが設定されていれば削除(false)、設定されていなければ追加する(true)という機能を持つのです。
        </p>
        <br />
        <p>
          では、具体的にウェブページでイメージ画像をクリックしたときにアニメーションが切り替わるようにするにする例を示します。
        </p>
        <CodeBlock>
          {`
function toggleRound() {
  pic.classList.toggle(‘round’);
  }
  pic.addEventListener(‘click’, toggleRound);
  
        `}
        </CodeBlock>
        <p>
          クリックしたときにaddEventListenerメソッドを呼び出し、toggleRound関数が呼び出されるという仕様になっています。
        </p>
        <br />
        <p>
          このように、クラスを定義してそれが実行されたときに、イベントを実行するというのはよく用いられるのでしっかりと身に付けていきましょう。
        </p>
        <h2 dir='ltr'>
          className
        </h2>
        <br />
        <p>
          今回は、class属性値を取得するためにclassListを用いましたが、classNameでも取得可能です。
        </p>
        <br />
        <p>
          ただし、クラスが複数存在した場合はスペース区切りで文字列で操作しなければいけないので、classListの方が使い勝手は良いです。また、クラスの追加ができないため、変更したいときは上書きする形になります。
        </p>
        <br />
        <h3>
          element.className
        </h3>
        <p>
          要素の class 属性の値の取得 / 設定に用います。クラス属性というのは、HTMLの要素に対して任意の文字列などで分類名を名付けた値のことです。
        </p>
        <br />
        <p>
          使用例）
        </p>
        <CodeBlock>
          {`
> pic.className
< “nice open”
        `}
        </CodeBlock>
        <p>
          classNameメソッドを使うことで、picのクラス属性の値を取得することができました。
        </p>
        <br />
        <p>
          以上、今回はDOM操作のひとつであるクラスの追加や削除についてみてきましたがいかがだったでしょうか？
        </p>
        <br />
        <p>
          最後までお読み頂きましてありがとうございました。
        </p>
        <br />
        <p>
          次回もJavascriptのDOM操作について理解を深めていきましょう。
        </p>
        <div>
          <br />
        </div>
      </LessonLayout>
    </div>
  )
}

export default withRouter(DOM_4Component)