import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { paths } from '../router'
import { CodeBlock, LessonHeader, LessonLayout, LessonTitle } from '../components'

const DOM_8Component = ({ history }) => {
  return (
    <div>
      <LessonHeader onGoback={() => history.push(paths.home)} />
      <LessonLayout onGoback={() => history.push(paths.home)}>
        <LessonTitle lessonNumber={19}
          title=' DOM_8 DOMに関する練習問題' />
        <p>
          今回は、今までのDOMに関する復習も兼ねて、練習問題を解いていきましょう。
        </p>
        <br />
        <p>
          まずは、index.htmlとDOM-Cardio.jsを用意します。
        </p>
        <br />
        <p>
          ・index.html
        </p>
        <CodeBlock>
          {`
<!DOCTYPE html>
<html>
 
<head>
 <meta charset="utf-8" />
 <meta http-equiv="X-UA-Compatible" content="IE=edge">
 <title>Page Title</title>
 <meta name="viewport" content="width=device-width, initial-scale=1">
 <link rel="stylesheet" type="text/css" media="screen" href="main.css" />
</head>
 
<body>
 <script src="DOM-Cardio.js"></script>
</body>
 
</html>
`}
        </CodeBlock>
        
        <br />
        <p>
          では、こちらが練習問題になります。一つずつ解いていき、答え合わせをしていきましょう。
        </p>
        <br />
        <p>
          ・DOM-Cardio.js
        </p>
        <p>
          1. div要素を作る
        </p>
        <p>
          2. wrapperクラスを追加する
        </p>
        <p>
          3. bodyに挿入する
        </p>
        <p>
          4. リストを作る
        </p>
        <p>
          5. wrapperクラスに追加する
        </p>
        <p>
          6. イメージを作る
        </p>
        <p>
          7. イメージにsrcをセットする
        </p>
        <p>
          8. イメージのwidthを250に。
        </p>
        <p>
          9. クラス属性にcuteを加える
        </p>
        <p>
          10. alt属性にpuppyを追加する
        </p>
        <p>
          11. wrapperクラスにappendする
        </p>
        <p>
          12. divタグ要素を文字列型のHTMLに作り、paragraphを２つ挿入する。
        </p>
        <p>
          13. このdivを上記のwrapper内の先頭に設置する
        </p>
        <p>
          14. Paragraph twoにwarningのクラスを追加する
        </p>
        <p>
          15. Paragraph oneを消す
        </p>
        <p>
          16. ３つの引数name, age, heightを持つgeneratePlayerCard関数を作り、以下のようなhtmlを返す。
        </p>
        <CodeBlock>
          {`
<div class="playerCard">

<h2>name - age</h2>

<p>Their Height is height and age years old.

In Dog years this person would be ageindogyears.

That would be a tall dog!</p>

</div>
`}
        </CodeBlock>
        <p>
          17. cardクラスを持つ新しいdivタグをつくる。
        </p>
        <p>
          18. 上記の関数を4つ持つcardsをつくる
        </p>
        <p>
          19. cardsをdivに追加し、wrapperクラスの前にDOMを挿入する。
        </p>
        <p>
          20. deleteボタンをcard毎に追加し、クリックすると消えるようにする。
        </p>
        <p>
          21. すべてのボタンを選択する
        </p>
        <p>
          22. delete関数を作る
        </p>
        <p>
          23. loopさせ、listenerにattachさせる
        </p>
        <br />
        <p>
          では、回答例を示します。
        </p>
        <br />
        <p>
          ・DOM-Cardio.js
        </p>
        <CodeBlock>
          {`
// div要素を作る
const div = document.createElement('div');
 
// wrapperクラスを追加する
div.classList.add('wrapper');
 
// bodyに挿入する
document.body.appendChild(div);
 
// リストを作る
const ul = \`<ul>
<li>one</li>
<li>two</li>
<li>three</li>
</ul>\`;
 
// wrapperクラスに追加する
div.innerHTML = ul;
console.log(div);
`}
        </CodeBlock>
        
        <p>
          （ブラウザ表示）
        </p>
        <p>
          <img src='https://lh5.googleusercontent.com/-KtO97e67XCjsNGNWvoVOrNLLL67aoqQSkZngl5s99DDiczKwAwEN3EXtNImh7cqDtK-gSku8HkaP5Djcl1AqcrPvBfLuOjW0G323CWUmntOaO5Zb399LPDg7GcL4JVVGalupfvG=s0'
        width='393'
        height='299'
        alt='list 1,2,3' />
        </p>
        <br />
        <CodeBlock>
          {`
// イメージを作る
const img = document.createElement('img');
 
//イメージにsrcをセットする
img.src='http://placehold.jp/150x150.png';
 
// イメージのwidthを250に。
img.width = 250;
 
// クラスにcuteを加える
img.classList.add('cute');
 
// altにpuppyを追加する
img.alt = 'puppy';
 
// wrapperクラスにappendする
div.appendChild(img);
`}
        </CodeBlock>
        <p>
          （ブラウザ表示）
        </p>
        <p>
          <img src='https://lh6.googleusercontent.com/BL0ET310a_-ErJJr2_MeU8Cl1kXrqfwV6_IRZGF3i8TT-nxIbTWXyvnqCAWoZy6Vjbzt8MitOMg1jz5T_QaEYPsJxT9jD1ui70MzVTL12FbhqMqtVKU1bIrELCxY4Lx3O0zHVUTS=s0'
        width='491'
        height='484'
        alt='wrapperクラスにappend' />
        </p>
        <br />
        <CodeBlock>
          {`
// divタグ要素を文字列型のHTMLに作り、paragraphを２つ挿入する。
const myHTML =\`
<div class="myDiv">
<p>Paragraph one</P>
<p>Paragraph two</P>
</div>
\`;
 
// このdivを上記のwrapper内の先頭に設置する
const ulElement = div.querySelector('ul');
ulElement.insertAdjacentHTML('beforebegin', myHTML);
 
// Paragraph twoにwarningのクラスを追加する
const myDiv = div.querySelector('.myDiv');
myDiv.children[1].classList.add('warning');
`}
        </CodeBlock>
        
        <p>
          （ブラウザ表示）
        </p>
        <p>
          <img src='https://lh3.googleusercontent.com/prxGDF8_1Lf4Dx0IdtwhLgVBcQjNAqzxima4IOl3AC1PtHhKqMapaJBa1711Am7yZAdiEqbYkgBbyoSyZrlTCpehF3RMPVeGkdKCKFc-u6hyGPkHRU-5nJwds5AxJBVpjW-ftBTO=s0'
        width='407'
        height='382'
        alt='paragraph one and two' />
        </p>
        <br />
        <CodeBlock>
          {`
// Paragraph oneを消す
myDiv.firstElementChild.remove();
`}
        </CodeBlock>
        <br />
        <p>
          （ブラウザ表示）
        </p>
        <p>
          <img src='https://lh5.googleusercontent.com/hvC5fqaMNwd44jc_eZhGztSRoogf7Vqk5kYNpSxEUlqghqIE3INdKfFH77nnKPKYE5_FOw4-bXb2JWJ2HNJ_IPcG8ZCntv5PRqlxsGaHNxL6mpaip-e_uK0Y1i9PcwzeAEEHzYoq=s0'
        width='272'
        height='365'
        alt='paragraph oneを消す' />
        </p>
        <br />
        <CodeBlock>
          {`
//３つの引数name, age, heightを持つgeneratePlayerCard関数を作り、以下のようなhtmlを返す。
// <div class="playerCard">
// <h2>name - age</h2>
// <p>Their Height is height and age years old.
//  In Dog years this person would be ageingdogyears.
//  That would be a tall dog!</p>
//  </div>
 
function generatePlayerCard(name, age, height) {
 const html =\`
 <div class="playerCard">
 <h2>\${name} - \${age}</h2>
 <p>Their Height is \${height} and \${age} years old.
 In Dog years this person would be \${age * 7}.
 That would be a tall dog!</p>
 <button class ="delete" type="button">&times; Delete</button>
 </div>
 \`;
 return html;
}

        `}
        </CodeBlock>
        <br />
        <p>
          （ブラウザ表示）
        </p>
        <br />
        <p>
          <img src='https://lh3.googleusercontent.com/w4_iV4-eR-32fFxzwP73x_TF6Ca_X1j3GMedWc9r1njlQYA6d_bcUuaM0mU3mrfBXYk5w4_OJv0SJvJoXmkM53xmy0hQJOy1mHGRtCR8oWhB58yBr9eF6E7Jgslv7DLE8YnCuYpB=s0'
        width='359'
        height='184'
        alt='generatePlayerCard()' />
        </p>
        <br />
        <CodeBlock>
          {`
// cardクラスを持つ新しいdivタグをつくる。
const cards = document.createElement('div');
cards.classList.add('cards');
 
// 上記の関数を4つ持つcardsをつくる
cards.insertAdjacentHTML('afterbegin',generatePlayerCard('tel', 12, 30));
cards.insertAdjacentHTML('afterbegin',generatePlayerCard('min', 12, 30));
cards.insertAdjacentHTML('afterbegin',generatePlayerCard('ata', 12, 30));
cards.insertAdjacentHTML('afterbegin',generatePlayerCard('apa', 12, 30));

`}
        </CodeBlock>
        <p>
          （ブラウザ表示）
        </p>
        <br />
        <p>
          <img src='https://lh5.googleusercontent.com/BwqM5EtER9XqBebCow9e0zxF2IuJGoeBCr6oe9qDdmNz_3ZoA4y1AlFi2j4vMrdx0DjT0L3Pzar7vsp6Tsk4N-6qMJSVYUXjO_kGtA4fVSGGXFEemoKkma6iKDekTWBB2JX578zF=s0'
        width='280'
        height='458'
        alt='insertAdjacentHTML()' />
        </p>
        <br />
        <CodeBlock>
          {`
// cardsをdivに追加し、wrapperクラスの前にDOMを挿入する。
div.insertAdjacentElement('beforebegin', cards);
`}
        </CodeBlock>
        <p>
          （ブラウザ表示）
        </p>
        <br />
        <p>
          <img src='https://lh5.googleusercontent.com/E2HWXOC-R7X3BheIyJQrnf0be1QlFQQyG7Bup9S_c1y5I6B97kAwZUi-qhka6nWuyR1-GyK1j4dNAF5eRy-oXPwgWu8prVEwLKu3xtCjQk-wXZAcC8i7zGDBtMsh8Rk3m8gTwjYE=s0'
        width='500'
        height='333'
        alt='wrapperクラスの前にDOMを挿入' />
        </p>
        <br />
        <p>
          問題ではありませんが、スタイルを整えてcardを見やすくしてみます。
        </p>
        <CodeBlock>
          {`
<body>
<script src="DOM-Cardio.js"></script>

<!--cardのスタイルをグリッドで整える-->
<style>
  .cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding: 20px;
  }

  .playerCard {
    background: gold;
    padding: 20px;
  }

  .playerCard h2 {
    color: black;
  }
</style>
</body>
        `}
        </CodeBlock>
        <br />

        <br />
        <p>
          （ブラウザ表示）
        </p>
        <br />
        <p>
          <img src='https://lh5.googleusercontent.com/eVemwxYG80NEmenTsLB8XGVl3yqwmB-l2E_Jj95bQxKXOT-pcqY-WXrmMfCLmus2aAFxDNk2zSjRkMvHS_qQbQTkdZBice5s7i5dzW7XVrXAogVHp0eHWtV9Iybq0_6PAC2UoKXE=s0'
        width='680'
        height='241' 
        alt='card' />
        </p>
        <br />
        <CodeBlock>
          {`
// deleteボタンをcard毎に追加し、クリックすると消えるようにする。
//htmlにdeleteボタンを追加
 
// すべてのボタンを選択する
const buttons = document.querySelectorAll('.delete');
 
// delete関数を作る
function deleteCard(event) {
 const buttonThatGotClicked = event.currentTarget;
 //buttonThatGotClicked.parentElement.remove();
 buttonThatGotClicked.closest('.playerCard').remove();
}
 
// loopさせ、listnerにattachさせる
buttons.forEach(button => button.addEventListener('click', deleteCard));
        `}
        </CodeBlock>
        <br />
        <p>
          （ブラウザ表示）
        </p>
        <br />
        <p>
          <img src='https://lh6.googleusercontent.com/tfCtyRkZLJQkqdW3NZs5WU4oLf6h69Je2uo3yzmueNxj9krcGs0E-2ureTZKC_B9pG128Rx0AXA0eqZoUfFVfQWySvmNTBylfRvNNij6fl1hDuAWHfaReMwbQeza7BUN_g55IAds=s0'
        width='580'
        height='256' 
        alt='delete関数' />
        </p>
        <br />
        <p>
          delete関数内で、buttonThatGotClicked.closest('.playerCard').remove();
          を使うことも可能です。こちらでも、parentElement.removeと同様にボタンを削除することができます。
        </p>
        <br />
        <p>
          文書ルートに向かって指定したクラスを探索して、追加・削除などをおこなうことができるのがclosest()メソッドのメリットです。
        </p>
        <br />
        <p>
          以上、DOMに関するトレーニングを行いましたがいかがだったでしょうか？
        </p>
        <p>
          実際にJavaScriptでDOM操作をする際に見返していただけたらと思います。
        </p>
        <br />
        <p>
          では、今回も最後までお読みいただきありがとうございました。
        </p>
        <div>
          <br />
        </div>
      </LessonLayout>
    </div>
  )
}

export default withRouter(DOM_8Component)