import * as React from 'react'

const LessonHeader = ({ onGoback, lang, setLang }) => {
  return (
    <div className='mt-4 px-1 sm:mx-4 sm:px-4 mb-2 flex flex-row justify-between items-center'>
      <button type='button'
        className='px-4 text-center 1f8Button'
        onClick={onGoback}>
        Go Back
      </button>
      {lang && (
        <button type='button' className='px-4 text-center 1f8Button'
          onClick={setLang}>
          { lang==='EN' ? 'English' : '日本語' }
        </button>
      )}
    </div>
  )
}

export default LessonHeader
